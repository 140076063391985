import { useAppDispatch, useAppSelector } from "@app/hooks"
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material"
import { useState } from "react"
import { programDomains } from "../../features/program/programHelpers"
import { selectPrograms } from "../../features/program/programSlice"
import { Syllabus } from "../../features/syllabus/syllabusSelectors"
import {
  domainChanged,
  syllabusNameUpdated,
} from "../../features/syllabusEditor/syllabusEditorSlice"

export function SyllabusEditorGeneralInfo(props: { syllabus: Syllabus }) {
  const { syllabus } = props
  const dispatch = useAppDispatch()

  const programs = useAppSelector(selectPrograms)
  const [domain, setDomain] = useState(syllabus.domain)

  const handleDomainChange = (event: SelectChangeEvent) => {
    setDomain(event.target.value as string)
    dispatch(domainChanged(event.target.value))
  }

  const domains = programDomains(programs)

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        maxWidth: "600px",
      }}
    >
      <Box>
        <TextField
          label="Syllabus name"
          value={syllabus.name}
          placeholder="Syllabus name"
          multiline
          fullWidth
          InputLabelProps={{ style: { fontSize: 16 } }}
          onChange={(event) =>
            dispatch(
              syllabusNameUpdated({
                name: event.target.value,
              }),
            )
          }
        />
      </Box>
      <Box>
        <FormControl fullWidth>
          <InputLabel sx={{ fontSize: 16 }}>Program</InputLabel>
          <Select
            value={domains.includes(domain) ? domain : ""}
            label="Program"
            onChange={handleDomainChange}
            disabled={syllabus.isActive}
          >
            {domains.map((programDomain) => (
              <MenuItem key={programDomain} value={programDomain}>
                {programDomain}
              </MenuItem>
            ))}
          </Select>
          {syllabus.isActive && (
            <FormHelperText>
              Syllabus is active. Program cannot be changed.
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </Box>
  )
}
