import HighlightableScrollRef from "@cmp/highlightableScrollRef"
import { Topic, UdacityLesson } from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import SyllabusElementFactory from "@utils/syllabusElementFactory"
import { useEffect, useRef, useState } from "react"
import appTheme from "../../../theme/appTheme"
import TimeEstimationTextField from "../../components/timeEstimationTextField"
import TitleTextField from "../../components/titleTextField"
import UrlTextField from "../../components/urlTextField"
import { useEditUdacityLesson } from "../custom-hooks/useEditElementItem"
import { useSelectedUdacityLesson } from "../custom-hooks/useSelectedElementItem"
import EditLessonType from "./EditLessonType"
import { useAppSelector } from "@app/hooks"
import { selectPublishValidations } from "../../../features/courseEditor/courseValidationsSelectors"
import UdacityTasksContainer from "./task-editors/udacityTasksContainer"

function UdacityLessonEditor(props: { topic: Topic }) {
  const { topic } = props
  const lesson = useSelectedUdacityLesson()
  const editLesson = useEditUdacityLesson()

  if (!lesson) {
    return undefined
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        gap: "24px",
        padding: "24px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
        }}
      >
        <TitleTextField
          elementId={lesson.id}
          value={lesson.title}
          debounce
          onChange={(e) => {
            editLesson(lesson, "title", e.target.value, topic)
          }}
        />
        <EditLessonType topic={topic} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "16px",
        }}
      >
        <UrlTextField
          elementId={lesson.id}
          value={lesson.url}
          debounce
          onChange={(e) => {
            editLesson(lesson, "url", e.target.value, topic)
          }}
        />
        <TimeEstimationTextField
          elementId={lesson.id}
          value={lesson.estimatedDuration}
          debounce
          onChange={(e) => {
            editLesson(
              lesson,
              "estimatedDuration",
              parseInt(e.target.value),
              topic,
            )
          }}
          sx={{ minWidth: "180px" }}
        />
      </Box>
      <TasksEditorContainer lesson={lesson} topic={topic} />
    </Box>
  )
}

const TasksEditorContainer = (props: {
  lesson: UdacityLesson
  topic: Topic
}) => {
  const { lesson, topic } = props
  const editLesson = useEditUdacityLesson()
  const tasksEditorRef = useRef<HighlightableScrollRef>(null)
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false)
  const [isBulkEditing, setIsBulkEditing] = useState(false)
  const validations = useAppSelector(selectPublishValidations)
  const isInvalidBecauseMissingTasks =
    validations?.[lesson.id]?.includes("MISSING_TASKS") &&
    lesson.tasks.length === 0

  useEffect(() => {
    if (shouldScrollToBottom) {
      tasksEditorRef.current?.scrollToBottomAndHighlightLastTask()
    }
    setShouldScrollToBottom(false)
  }, [shouldScrollToBottom])

  const addTaskText =
    lesson.tasks.length === 0 ? "Add task (optional)" : "Add task"

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "start",
          gap: "16px",
          overflow: "hidden",
        }}
      >
        <UdacityTasksContainer ref={tasksEditorRef} topic={topic} />
        <Box>
          <FormControl
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
            }}
          >
            <Button
              variant="text"
              size="small"
              onClick={() => {
                editLesson(
                  lesson,
                  "tasks",
                  lesson.tasks.concat(SyllabusElementFactory.makeUdacityTask()),
                  topic,
                )
                setShouldScrollToBottom(true)
              }}
              startIcon={
                <SvgIcon
                  component={appIcons.plusCircle}
                  inheritViewBox
                  sx={{
                    width: "20px",
                    height: "20px",
                    fill: "none",
                    stroke: isInvalidBecauseMissingTasks
                      ? appTheme.palette.error.main
                      : appTheme.palette.icon.black,
                  }}
                />
              }
              sx={{
                color: isInvalidBecauseMissingTasks
                  ? appTheme.palette.error.main
                  : appTheme.palette.icon.black,
              }}
            >
              {addTaskText}
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setIsBulkEditing(true)
              }}
              startIcon={
                <SvgIcon
                  component={appIcons.edit05}
                  inheritViewBox
                  sx={{
                    width: "20px",
                    height: "20px",
                    stroke: appTheme.palette.icon.black,
                    fill: "none",
                  }}
                />
              }
            >
              Bulk edit
            </Button>
            <FormHelperText
              error={isInvalidBecauseMissingTasks}
              variant="standard"
              sx={{
                textTransform: "none",
                paddingTop: "4px",
                paddingLeft: "14px",
              }}
            >
              {isInvalidBecauseMissingTasks
                ? "Lesson must have at least one task"
                : ""}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
      {isBulkEditing && (
        <BulkEditingDialog
          onClose={() => {
            setIsBulkEditing(false)
          }}
          topic={topic}
        />
      )}
    </>
  )
}

function BulkEditingDialog(props: { onClose: () => void; topic: Topic }) {
  const { onClose, topic } = props

  const lesson = useSelectedUdacityLesson()
  const editLesson = useEditUdacityLesson()

  const [tasks, setTasks] = useState<string>("")

  if (!lesson) {
    return undefined
  }

  return (
    <Dialog open={true} fullWidth onClose={() => onClose()}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "56px 32px 24px 32px",
          position: "relative",
        }}
      >
        <SvgIcon
          component={appIcons.xClose}
          inheritViewBox
          sx={{
            width: "24px",
            height: "24px",
            stroke: appTheme.palette.icon.black,
            fill: "none",
            alignSelf: "flex-end",
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
          onClick={() => onClose()}
        />
        <Box>
          <Typography variant="body1_sb" paddingBottom="8px">
            Bulk edit
          </Typography>
          <Typography variant="body1" paddingBottom="24px">
            Separate URLs using line breaks to add tasks in bulk.
          </Typography>
        </Box>
        <TextField
          label="Tasks URL"
          multiline
          rows={6}
          fullWidth
          variant="outlined"
          value={tasks}
          onChange={(event) => {
            setTasks(event.target.value)
          }}
          sx={{
            width: "100%",
            paddingBottom: "24px",
          }}
        />
        <Box
          sx={{
            alignSelf: "flex-end",
            gap: "16px",
            display: "flex",
          }}
        >
          <Button variant="outlined" size="small" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              if (!tasks) {
                return
              }
              const tasksUrls = tasks.split("\n")
              const newTasks = tasksUrls.map((url) => {
                return SyllabusElementFactory.makeUdacityTask(url)
              })
              editLesson(lesson, "tasks", lesson.tasks.concat(newTasks), topic)
              onClose()
            }}
            disabled={!tasks}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default UdacityLessonEditor
