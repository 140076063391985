import { Autocomplete, TextField } from "@mui/material"

export function MultipleTagsSelectionInput(props: {
  options: string[]
  selectedTags: string[]
  onChange: (selectedTags: string[]) => void
  title: string
  placeholder?: string
  error?: boolean
}) {
  const optionList = props.options.map((tag) => ({
    value: tag,
    label: tag,
  }))

  const selectedTagsList = props.selectedTags.map((tag) => ({
    value: tag,
    label: tag,
  }))

  function handleSelect(e: any, selection: any) {
    props.onChange(selection.map((skill: any) => skill.value))
  }

  return (
    <Autocomplete
      size="small"
      options={optionList}
      placeholder={props.placeholder ?? props.title}
      fullWidth
      multiple
      includeInputInList
      autoComplete
      value={selectedTagsList}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={handleSelect}
      renderInput={(params) => (
        <TextField
          error={props.error}
          {...params}
          variant="outlined"
          label={props.title}
          size="small"
          InputLabelProps={{ sx: { fontSize: "14px" } }}
        />
      )}
    />
  )
}
