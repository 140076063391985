import { useAppDispatch, useAppSelector } from "@app/hooks"
import { UnitCourseDescriptor } from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { createSelector } from "@reduxjs/toolkit"
import appIcons from "@utils/appIcons"
import { useRef } from "react"
import { TabIdentifierEnumeration } from "../../../../features/coursesMenu/coursesMenuSlice"
import { selectLatestVersionsPublishedCourses } from "../../../../features/coursesMenu/coursesSelectors"
import { selectCourseFilters } from "../../../../features/search/searchSlice"
import { selectEditableSyllabus } from "../../../../features/syllabus/syllabusSelectors"
import {
  courseAdded,
  editUnitCourses,
  editUnitCoursesPopupClosed,
} from "../../../../features/syllabusEditor/syllabusEditorSlice"
import { CourseSearchComponent } from "../../../../main/courseSearchComponent"
import { filterCourses } from "../../../../main/main-page-helpers"
import appTheme from "../../../../theme/appTheme"
import useSelectedUnit from "../../useSelectedUnit"
import CourseSelectionButton from "./courseSelectionButton"
import UnitCoursesList, { UnitCoursesListRef } from "./unitCoursesList"

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    padding: theme.spacing(0),
    width: "1200px",
    overflowY: "hidden",
    height: "100%",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
    width: "100%",
    height: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

const UnitCourseSelectionPopup = () => {
  const dispatch = useAppDispatch()
  const availableCoursesDescriptors: UnitCourseDescriptor[] = useAppSelector(
    selectAvailableCourses,
  ).map((course) => ({
    courseId: course.id,
    version: course.version,
  }))
  const unit = useSelectedUnit()
  const selectedCourses =
    useAppSelector(
      (state) => state.syllabusEditor.coursesSelectionPopup?.selectedCourses,
    ) ?? []
  const listRef = useRef<UnitCoursesListRef>(null)
  const isEmptyUnit = unit?.courseDescriptors?.length === 0

  return (
    <CustomDialog
      onClose={() => dispatch(editUnitCoursesPopupClosed())}
      open={true}
      maxWidth={false}
      onClick={(e) => {
        e.stopPropagation()
      }}
      sx={{
        overflowY: "hidden",
      }}
    >
      <DialogTitle>
        {isEmptyUnit ? "Choose courses" : "Manage Courses"}
      </DialogTitle>
      <IconButton
        onClick={() => dispatch(editUnitCoursesPopupClosed())}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <SvgIcon
          component={appIcons.xClose}
          inheritViewBox
          sx={{
            stroke: appTheme.palette.icon.black,
          }}
        />
      </IconButton>
      <DialogContent dividers>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            overflowY: "hidden",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "hidden",
            }}
          >
            <Box
              sx={{
                padding: "24px",
              }}
            >
              <CourseSearchComponent />
            </Box>
            <Box
              sx={{
                overflowY: "auto",
                height: "100%",
              }}
            >
              {availableCoursesDescriptors.map((courseDescriptor, index) => (
                <CourseSelectionButton
                  key={courseDescriptor.courseId}
                  courseDescriptor={courseDescriptor}
                  onAddClick={() => {
                    dispatch(courseAdded(courseDescriptor))
                    listRef.current?.scrollToBottom()
                  }}
                  disabled={selectedCourses
                    .map((cd: UnitCourseDescriptor) => cd.courseId)
                    .includes(courseDescriptor.courseId)}
                  sx={{
                    borderBottom:
                      index === availableCoursesDescriptors.length - 1
                        ? `1px solid ${appTheme.palette.other.divider}`
                        : undefined,
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "280px",
              maxWidth: "280px",
              backgroundColor: appTheme.palette.background.default,
              borderLeft: `1px solid ${appTheme.palette.other.outlineBorder}`,
              gap: "16px",
              padding: "24px 0px 24px 0px",
              overflowY: "hidden",
              overflowX: "visible",
            }}
          >
            <Typography
              variant="body1_sb"
              sx={{
                padding: "0px 24px 0px 24px",
              }}
            >
              {`Selected Courses (${unit?.courseDescriptors?.length ?? 0})`}
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
              }}
            >
              <UnitCoursesList ref={listRef} />
            </Box>
            <Button
              variant="contained"
              size="small"
              sx={{
                margin: "0px 24px",
              }}
              onClick={() => {
                dispatch(editUnitCourses())
              }}
              startIcon={isEmptyUnit ? <PlusIcon /> : undefined}
            >
              {isEmptyUnit ? "Add to unit" : "Save changes"}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </CustomDialog>
  )
}

const PlusIcon = () => (
  <SvgIcon
    component={appIcons.plus}
    inheritViewBox
    sx={{
      stroke: "white",
    }}
  />
)

const selectAvailableCourses = createSelector(
  [
    selectLatestVersionsPublishedCourses,
    selectEditableSyllabus,
    selectCourseFilters,
    (state) => state.syllabusEditor.coursesSelectionPopup,
  ],
  (publishedCourses, syllabus, filters, popup) => {
    if (!publishedCourses || !syllabus) {
      return []
    }

    const filteredCourse = filterCourses(
      publishedCourses,
      filters.text,
      filters.advanceFilters,
      TabIdentifierEnumeration.All,
      [],
    )

    const coursesInSyllabus = syllabus.units
      .filter((unit) => unit.id !== popup?.unitId)
      .map((unit) =>
        unit.courseDescriptors.map(
          (courseDescriptor) => courseDescriptor.courseId,
        ),
      )
      .flat()

    return filteredCourse.filter(
      (course) => !coursesInSyllabus.includes(course.id),
    )
  },
)

export default UnitCourseSelectionPopup
