import { Box, Button, Dialog, IconButton, SvgIcon } from "@mui/material"
import { popupClosed } from "../../features/ui/uiSlice"
import { useAppDispatch } from "@app/hooks"
import { JSONTree } from "react-json-tree"
import { useEffect, useState } from "react"
import { LegacySyllabusClient } from "@clients/legacySyllabusClient"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"

export const CampusSyllabusJSONPreview = ({
  syllabusId,
}: {
  syllabusId: string
}) => {
  const dispatch = useAppDispatch()
  const [syllabus, setSyllabus] = useState<any>(undefined)
  useEffect(() => {
    LegacySyllabusClient.getLegacySyllabus(syllabusId).then((syllabus) => {
      setSyllabus(syllabus)
    })
  }, [syllabusId])

  return (
    <Dialog
      open={true}
      onClose={() => {
        dispatch(popupClosed())
      }}
      fullWidth
      maxWidth={"lg"}
      sx={{
        ".MuiDialog-paper": {
          width: "100%",
          height: "100%",
          margin: "0px",
          padding: "0px",
        },
        boxShadow: "1px 1px 1px 1px rgba(0,0,0,1)",
      }}
    >
      <Box>
        <Box
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            cursor: "pointer",
            zIndex: 10,
          }}
        >
          <IconButton
            sx={{ padding: "2px", cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation()
              dispatch(popupClosed())
            }}
          >
            <SvgIcon
              component={appIcons.xClose}
              inheritViewBox
              sx={{
                stroke: appTheme.palette.icon.black,
              }}
            />
          </IconButton>
        </Box>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(
              syllabus ? JSON.stringify(syllabus, null, 4) : "",
            )
          }}
          variant="outlined"
          size="small"
          sx={{
            margin: "16px 0px 8px 16px",
          }}
        >
          Copy JSON to clipboard
        </Button>
        <Box
          sx={{
            margin: "8px 0px 0px 16px",
          }}
        >
          <JSONTree
            data={syllabus ?? {}}
            theme={{ base00: "#000000" }}
            invertTheme
          />
        </Box>
      </Box>
    </Dialog>
  )
}
