import { Snackbar, SvgIcon } from "@mui/material"
import { useSelector } from "react-redux"
import { useAppDispatch } from "@app/hooks"
import { selectSnackbarPresentationState } from "../../features/ui/uiSelector"
import { hideSnackbar } from "../../features/ui/uiSlice"
import appIcons from "@utils/appIcons"

function SnackbarPresenter() {
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(hideSnackbar())
  }

  const presentationState = useSelector(selectSnackbarPresentationState)

  if (!presentationState) {
    return null
  }

  return (
    <Snackbar
      anchorOrigin={presentationState.anchorOrigin}
      open={true}
      onClose={onClose}
      autoHideDuration={presentationState.duration}
      ContentProps={{
        sx: {
          bgcolor: presentationState.bgcolor,
        },
      }}
      message={
        <>
          <SvgIcon
            component={appIcons.infoCircle}
            sx={{
              width: 20,
              height: 20,
              mr: 1,
              color: "primary.contrast",
              stroke: "currentcolor",
              fill: "none",
            }}
          />
          {presentationState.message}
        </>
      }
    />
  )
}

export default SnackbarPresenter
