import {
  Configuration,
  LegacySyllabusApi,
} from "@masterschool/course-builder-api"
import { selectAccessToken } from "../features/user/userSlice"
import { store } from "@app/store"

export class LegacySyllabusClient {
  private static legacySyllabusClient = new LegacySyllabusApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static getLegacySyllabus(id: string): Promise<any> {
    return LegacySyllabusClient.legacySyllabusClient
      .legacySyllabusControllerGet(id, LegacySyllabusClient.makeHeaders())
      .then((response) => response.data)
  }
}
