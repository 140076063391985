import { Box } from "@mui/material"
import EditorTextField from "../../components/editorTextField"
import useActiveSyllabusUnit from "../panel/useActiveSyllabusUnit"
import { useAppDispatch } from "@app/hooks"
import { unitEdited } from "../../../features/syllabusEditor/syllabusEditorSlice"

function UnitGeneralInfoEditor() {
  const unit = useActiveSyllabusUnit()
  const dispatch = useAppDispatch()
  if (!unit) {
    return null
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        mt: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "24px",
        }}
      >
        <EditorTextField
          label="Short name"
          value={unit.shortTitle ?? ""}
          placeholder="e.g. TF"
          debounce
          onChange={(e) => {
            dispatch(
              unitEdited({
                unitId: unit.id,
                key: "shortTitle",
                value: e.target.value ?? "",
              }),
            )
          }}
        />
        <EditorTextField
          type="number"
          label="Unit number"
          required
          error={!unit.unitNumber || isNaN(unit.unitNumber)}
          value={
            !unit.unitNumber || isNaN(unit.unitNumber) ? "" : unit.unitNumber
          }
          placeholder="e.g. 101"
          debounce
          onChange={(e) => {
            dispatch(
              unitEdited({
                unitId: unit.id,
                key: "unitNumber",
                value: e.target.value,
              }),
            )
          }}
        />
      </Box>
      <EditorTextField
        label="Unit name"
        value={unit.name}
        debounce
        onChange={(e) => {
          dispatch(
            unitEdited({
              unitId: unit.id,
              key: "name",
              value: e.target.value,
            }),
          )
        }}
      />
      <EditorTextField
        label="Unit description"
        value={unit.description ?? ""}
        debounce
        multiline
        rows={4}
        onChange={(e) => {
          dispatch(
            unitEdited({
              unitId: unit.id,
              key: "description",
              value: e.target.value,
            }),
          )
        }}
      />
    </Box>
  )
}

export default UnitGeneralInfoEditor
