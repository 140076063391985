import { OptionsButtonProps } from "@cmp/buttons/optionsButton"
import ItemDescriptor from "@cmp/itemDescriptor"
import { Theme } from "@emotion/react"
import {
  CourseSyllabusElement,
  CourseSyllabusElementTypeEnum,
  Survey,
  Test,
  Topic,
} from "@masterschool/course-builder-api"
import { SvgIconProps, SxProps } from "@mui/material"
import appIcons from "@utils/appIcons"
import { IconType } from "@utils/iconType"
import { elementDuration } from "@utils/syllabus+duration"
import { elementTypeDisplayName } from "@utils/syllabus+title"
import useEditElement from "./elements/custom-hooks/useEditElement"
import useOnDelete from "./elements/custom-hooks/useOnDelete"

function CourseElementDescriptor(props: {
  element: CourseSyllabusElement
  topic: Topic
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  optionsItems?: OptionsButtonProps["items"]
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
}) {
  const removeElement = useOnDelete()
  const editElement = useEditElement()
  const optionsItems = props.editMode
    ? [
        {
          text: "Remove",
          onSelect: () => {
            removeElement({
              topicId: props.topic.id,
              elementId: props.element.item.id,
            })
          },
          icon: appIcons.trash03,
        },
        {
          text: props.element.item.isHidden ? "Unhide" : "Hide",
          icon: props.element.item.isHidden ? appIcons.eye : appIcons.eyeOff,
          onSelect: () => {
            editElement(
              {
                type: props.element.type,
                item: {
                  ...props.element.item,
                  isHidden: !props.element.item.isHidden,
                },
              },
              props.topic,
            )
          },
        },
      ]
    : undefined

  switch (props.element.type) {
    case CourseSyllabusElementTypeEnum.DatacampLesson:
    case CourseSyllabusElementTypeEnum.EmptyLesson:
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
    case CourseSyllabusElementTypeEnum.NotionLesson:
    case CourseSyllabusElementTypeEnum.OtherLesson:
    case CourseSyllabusElementTypeEnum.CourseraLesson:
    case CourseSyllabusElementTypeEnum.TypeformLesson:
    case CourseSyllabusElementTypeEnum.TryHackmeLesson:
    case CourseSyllabusElementTypeEnum.UdacityLesson:
    case CourseSyllabusElementTypeEnum.RunestoneLesson:
      return <LessonDescriptor {...props} optionsItems={optionsItems} />
    case CourseSyllabusElementTypeEnum.EmptyProject:
    case CourseSyllabusElementTypeEnum.CodioProject:
      return <ProjectDescriptor {...props} optionsItems={optionsItems} />
    case CourseSyllabusElementTypeEnum.Survey:
      return <SurveyDescriptor {...props} optionsItems={optionsItems} />
    case CourseSyllabusElementTypeEnum.Test:
      return <TestDescriptor {...props} optionsItems={optionsItems} />
    default:
      console.error(`Unsupported element type ${props.element.type}`)
  }
}

function LessonDescriptor(props: {
  element: CourseSyllabusElement
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  optionsItems?: OptionsButtonProps["items"]
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
}) {
  const { element } = props
  const typeName = elementTypeDisplayName(element.type)

  return (
    <ItemDescriptor
      {...props}
      title={element.item.title}
      subtitle={`Lesson ${typeName ? `· ${typeName}` : ""} · ${Math.round(
        elementDuration(props.element),
      )} min`}
      icon={appIcons.bookOpen01}
    />
  )
}

function ProjectDescriptor(props: {
  element: CourseSyllabusElement
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  optionsItems?: OptionsButtonProps["items"]
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
}) {
  const { element } = props
  const typeName = elementTypeDisplayName(element.type)

  return (
    <ItemDescriptor
      {...props}
      title={element.item.title}
      subtitle={`Project · ${typeName} · ${Math.round(
        elementDuration(props.element),
      )} min`}
      icon={appIcons.codeSquare01}
    />
  )
}

function SurveyDescriptor(props: {
  element: CourseSyllabusElement
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  optionsItems?: OptionsButtonProps["items"]
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
}) {
  if (props.element.type !== CourseSyllabusElementTypeEnum.Survey) {
    throw new Error(`Expected Survey, got ${props.element.type}`)
  }

  const survey = props.element.item as Survey

  return (
    <ItemDescriptor
      {...props}
      title={survey.title}
      subtitle={`Survey · ${survey.estimatedDuration} min`}
      icon={appIcons.star01}
    />
  )
}

function TestDescriptor(props: {
  element: CourseSyllabusElement
  index?: number
  editMode?: boolean
  onClick?: () => void
  sx?: SxProps<Theme>
  leftIcon?: IconType
  leftIconSx?: SxProps<Theme>
  optionsItems?: OptionsButtonProps["items"]
  extraIcons?: (SvgIconProps & { id: string })[]
  extraText?: string
}) {
  if (props.element.type !== CourseSyllabusElementTypeEnum.Test) {
    throw new Error(`Expected Test, got ${props.element.type}`)
  }

  const test = props.element.item as Test
  const provider = capitalizeString((props.element.item as Test).provider)

  return (
    <ItemDescriptor
      {...props}
      title={test.title}
      subtitle={`Quiz · ${provider} · ${test.estimatedDuration} min`}
      icon={appIcons.file02}
    />
  )
}

function capitalizeString(str: string | undefined) {
  if (!str) {
    return undefined
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export default CourseElementDescriptor
