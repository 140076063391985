import {
  Box,
  Button,
  Chip,
  InputAdornment,
  Popover,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import { SearchAdvanceFilter } from "../features/search/searchSlice"
import { MainPageFilterContainer } from "../main/filter/filterContainer"
import appTheme from "../theme/appTheme"

export function SearchComponent(props: {
  onSearchTextChange: (text: string) => void
  searchText: string
  inputFilters: SearchAdvanceFilter[]
  onInputFiltersChange: (filters: SearchAdvanceFilter[]) => void
  searchPlaceholder?: string
  filterTitle?: string
}) {
  const {
    onSearchTextChange,
    searchText,
    inputFilters,
    onInputFiltersChange,
    searchPlaceholder,
    filterTitle,
  } = props

  const numberOfFilters = inputFilters
    .map((filter) => filter.selectedValues.length)
    .reduce((a, b) => a + b, 0)

  const handleSearchChange = (e: any) => {
    onSearchTextChange(e.target.value)
  }

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const advanceFilterPopoverId = open ? "simple-popover" : undefined
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  function onAdvanceFilterApplyClicked(filters: SearchAdvanceFilter[]) {
    setAnchorEl(null)
    onInputFiltersChange(filters)
  }

  function onRemoveFilter(filterName: string, filterValue: string) {
    const newFilters = inputFilters.map((filter) => {
      if (filter.filterName === filterName) {
        return {
          ...filter,
          selectedValues: filter.selectedValues.filter(
            (value) => value !== filterValue,
          ),
        }
      }
      return filter
    })

    onInputFiltersChange(newFilters)
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        width="100%"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TextField
          placeholder={searchPlaceholder ?? "Search"}
          value={searchText}
          onChange={handleSearchChange}
          size="small"
          fullWidth
          InputProps={{
            sx: {
              borderRadius: "8px",
              fontSize: 14,
              height: "40px",
              lineHeight: "22px",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  component={appIcons.searchSm}
                  inheritViewBox
                  sx={{
                    stroke: appTheme.palette.icon.black,
                    fill: "none",
                    width: "20px",
                    height: "20px",
                    cursor: "pointer",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Box marginLeft="8px">
          <Button
            onClick={handleClick}
            variant="outlined"
            style={{
              width: "fit-content",
            }}
          >
            <SvgIcon
              component={appIcons.funnel}
              inheritViewBox
              sx={{
                stroke: appTheme.palette.icon.default,
                fill: "none",
                width: "20px",
                height: "20px",
                cursor: "pointer",
                marginRight: "8px",
              }}
            />
            <Typography variant="body1">Filter</Typography>
            {numberOfFilters > 0 && (
              <Typography
                marginLeft="4px"
                variant="body1"
              >{`(${numberOfFilters})`}</Typography>
            )}
          </Button>
        </Box>
        <Popover
          id={advanceFilterPopoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{ marginTop: "4px" }}
        >
          <MainPageFilterContainer
            inputFilters={inputFilters}
            title={filterTitle ?? "Add filter"}
            onApplyClicked={onAdvanceFilterApplyClicked}
            onClose={() => setAnchorEl(null)}
          />
        </Popover>
      </Box>
      {inputFilters.flatMap((filter) => filter.selectedValues).length > 0 && (
        <Box display="flex" marginTop="16px" gap="4px">
          {inputFilters.flatMap((filter) =>
            filter.selectedValues.map((value) => (
              <Chip
                key={value}
                onDelete={() => onRemoveFilter(filter.filterName, value)}
                label={value}
                color="info"
                size="small"
                sx={{
                  width: "fit-content",
                  "& .MuiChip-deleteIcon": {
                    margin: "0 4px",
                  },
                }}
              />
            )),
          )}
        </Box>
      )}
    </Box>
  )
}
