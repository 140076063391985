import { CourseSyllabusElementTypeEnum } from "@masterschool/course-builder-api"
import OptionsButton from "@cmp/buttons/optionsButton"
import appIcons from "@utils/appIcons"
import { SxProps } from "@mui/material"

function AddItemButton(props: {
  onClick: (itemType: CourseSyllabusElementTypeEnum) => void
  sx?: SxProps
  iconSx?: SxProps
}) {
  const { onClick, sx, iconSx } = props

  return (
    <OptionsButton
      button={{
        text: "Add item",
        leftIcon: appIcons.plusCircle,
        size: "small",
        iconSx: {
          fill: "none",
          ...iconSx,
        },
        sx: {
          border: "none",
          boxSizing: "border-box",
          ...sx,
        },
        variant: "text",
      }}
      items={[
        {
          text: "Lesson",
          icon: appIcons.bookOpen01,
          onSelect: () => {
            onClick(CourseSyllabusElementTypeEnum.EmptyLesson)
          },
        },
        {
          text: "Quiz",
          icon: appIcons.file02,
          onSelect: () => {
            onClick(CourseSyllabusElementTypeEnum.Test)
          },
        },
        {
          text: "Project",
          icon: appIcons.codeSquare01,
          onSelect: () => {
            onClick(CourseSyllabusElementTypeEnum.EmptyProject)
          },
        },
        {
          text: "Survey",
          icon: appIcons.star01,
          onSelect: () => {
            onClick(CourseSyllabusElementTypeEnum.Survey)
          },
        },
      ]}
    />
  )
}

export default AddItemButton
