import { useAppSelector } from "@app/hooks"
import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { selectSyllabuses } from "../../features/syllabus/syllabusSelectors"

const SyllabusDependenciesPopup = (props: {
  syllabusIDs: string[]
  onClose: () => void
}) => {
  const { syllabusIDs, onClose } = props
  const syllabusesMetadata = useAppSelector(selectSyllabuses)
  const syllabuses = syllabusesMetadata.filter((syllabus) =>
    syllabusIDs.includes(syllabus.id),
  )
  const navigate = useNavigate()
  const onSyllabusClicked = (syllabusId: string) => {
    navigate(`/syllabus/${syllabusId}`)
    onClose()
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle sx={{ padding: "48px 0px 0px" }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "404px",
          padding: "32px",
        }}
      >
        <DialogTitle
          sx={{
            padding: "0px",
          }}
        >
          Dependent syllabuses
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "0px",
          }}
        >
          {syllabuses.length > 0
            ? "This course is used in:"
            : "This course is not used in any syllabus"}
        </DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {syllabuses.map((syllabus) => (
            <Box key={syllabus.id}>
              <span
                style={{
                  textDecoration: "none",
                }}
              >
                &nbsp;&nbsp;&#x2022;&nbsp;
              </span>
              <Link
                href={`/syllabus/${syllabus.id}`}
                variant="body2"
                onClick={() => {
                  onSyllabusClicked(syllabus.id)
                }}
              >
                {syllabus.name || "Unnamed syllabus"}
              </Link>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SyllabusDependenciesPopup
