import { useAppDispatch, useAppSelector } from "@app/hooks"
import { CourseDto } from "@masterschool/course-builder-api"
import { courseEdited } from "../../features/courseEditor/courseEditorSlice"
import { selectActiveCourse } from "../../features/courseEditor/courseEditorSelectors"

function useUpdateCourse() {
  const dispatch = useAppDispatch()
  const course = useAppSelector(selectActiveCourse)

  function updateCourse<K extends keyof CourseDto>(
    key: K,
    value: CourseDto[K],
  ) {
    if (!course) {
      return
    }

    dispatch(
      courseEdited({
        editStepId: window.crypto.randomUUID(),
        key,
        value,
      }),
    )
  }

  return updateCourse
}

export default useUpdateCourse
