import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import {
  CourseDto,
  CourseDtoStatusEnum,
} from "@masterschool/course-builder-api"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  SortOption,
  SortOrder,
  SortType,
} from "../../main/sort/coursesSortHelpers"
import { CourseClient, statusToUpdateStatus } from "@clients/courseClient"
import { selectSyllabuses, Syllabus } from "../syllabus/syllabusSelectors"
import { NIL as NIL_UUID } from "uuid"
import { selectLatestDraftCourse } from "./coursesSelectors"
import { publishCourse } from "../courseEditor/courseEditorSlice"

export enum TabIdentifierEnumeration {
  All = "All",
  Published = "Published",
  Draft = "Draft",
  Favorites = "Favorites",
  Archived = "Archived",
}

export interface CoursesMenuState {
  courses: "pending" | "rejected" | CourseState[]
  sortOption: SortOption
  favoriteCourses: string[]
  tab: TabIdentifierEnumeration
  highlightedCourseId: string | undefined
}

export type CourseState = CourseDto & {
  syllabusDependencies: string[]
  programDependencies: string[]
}

export const coursesMenuSlice = createSlice({
  name: "coursesMenu",
  initialState: {} as CoursesMenuState, // Defined in preloadedState in store.ts
  reducers: {
    sortAlphabeticallyClicked: (state, action: PayloadAction<SortOrder>) => {
      if (state.courses === "pending" || state.courses === "rejected") {
        return
      }
      state.sortOption.type = SortType.Alphabetic
      state.sortOption.order = action.payload
    },
    sortChronologicallyClicked: (state, action: PayloadAction<SortOrder>) => {
      if (state.courses === "pending" || state.courses === "rejected") {
        return
      }
      state.sortOption.type = SortType.Chronological
      state.sortOption.order = action.payload
    },
    toggleIsCourseFavorite: (
      state,
      action: PayloadAction<{ courseId: string }>,
    ) => {
      const { courseId } = action.payload
      const favoriteCourses = state.favoriteCourses
      if (favoriteCourses.includes(courseId)) {
        state.favoriteCourses = favoriteCourses.filter((id) => id !== courseId)
      } else {
        state.favoriteCourses.push(courseId)
      }
    },
    tabSelected: (state, action: PayloadAction<TabIdentifierEnumeration>) => {
      state.tab = action.payload
    },
    clickedAwayHighlightedCourse: (state) => {
      state.highlightedCourseId = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourses.pending, (state, action) => {
        state.courses = "pending"
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.courses = action.payload.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.courses = "rejected"
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        } else {
          state.courses.push({
            ...action.payload,
            syllabusDependencies: [],
            programDependencies: [],
          })
        }
      })
      .addCase(duplicateCourse.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        const originalCourse = state.courses.find(
          (course) => course.id === action.payload.originalCourseId,
        )

        const updatedCourses = [...state.courses].concat({
          ...action.payload.course,
          syllabusDependencies: originalCourse?.syllabusDependencies ?? [],
          programDependencies: originalCourse?.programDependencies ?? [],
        })

        state.courses = updatedCourses

        if (
          state.tab === TabIdentifierEnumeration.Favorites ||
          state.tab === TabIdentifierEnumeration.Published
        ) {
          state.tab = TabIdentifierEnumeration.Draft
        }

        state.highlightedCourseId = action.payload.course.id
      })
      .addCase(publishFromDescriptor.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        if (action.payload.kind === "failure") {
          return
        }

        const publishedCourse = action.payload.value

        const courseIndex = state.courses.findIndex(
          (course) => course.id === publishedCourse.id,
        )

        state.courses[courseIndex] = {
          ...publishedCourse,
          syllabusDependencies: state.courses[courseIndex].syllabusDependencies,
          programDependencies: state.courses[courseIndex].programDependencies,
        }
      })
      .addCase(archiveCourse.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        state.courses = state.courses.map((course) => {
          if (course.id === action.payload.id) {
            return {
              ...course,
              status: CourseDtoStatusEnum.Archived,
            }
          }

          return course
        })
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        state.courses = state.courses.filter(
          (course) => course.id !== action.payload.id,
        )
      })
      .addCase(createNewCourseVersion.fulfilled, (state, action) => {
        if (
          state.courses === "pending" ||
          state.courses === "rejected" ||
          !action.payload
        ) {
          return
        }
        const courseId = action.payload.id

        const previousCourse = state.courses.find(
          (course) => course.id === courseId,
        )

        if (!previousCourse) {
          return
        }

        const newCourse = {
          ...previousCourse,
          ...action.payload,
        }

        const updatedCourses = [...state.courses].concat(newCourse)

        state.courses = updatedCourses
      })
      .addCase(publishCourse.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        switch (action?.payload?.kind) {
          case "failure":
            return
          case "success":
            const publishedCourse = action.payload.value.publishedVersion
            state.courses = state.courses.map((course) => {
              if (course.id === publishedCourse.id) {
                return {
                  ...course,
                  ...publishedCourse,
                }
              }

              return course
            })

            const containsCourse = state.courses.some(
              (course) => course.id === publishedCourse.id,
            )

            if (!containsCourse) {
              state.courses.push({
                ...publishedCourse,
                syllabusDependencies: action.meta.arg?.syllabusId
                  ? [action.meta.arg.syllabusId]
                  : [],
                programDependencies: [],
              })
            }
        }
      })
  },
})

export const fetchCourses = createAppAsyncThunk(
  "coursesMenu/fetchCourses",
  async (_, thunkAPI) => {
    const syllabuses = selectSyllabuses(thunkAPI.getState())

    const courses = await CourseClient.listCourses()

    const courseViews: CourseState[] = courses.map((course) => ({
      ...course,
      syllabusDependencies: getSyllabusDependencyIds(course, syllabuses),
      programDependencies: [...getProgramDependencies(course, syllabuses)],
    }))

    return courseViews
  },
)

function getSyllabusDependencyIds(
  course: CourseDto,
  syllabuses: Syllabus[],
): string[] {
  return getSyllabusDependencies(course, syllabuses).map(
    (syllabus) => syllabus.id,
  )
}

function getProgramDependencies(
  course: CourseDto,
  syllabuses: Syllabus[],
): Set<string> {
  const syllabusDependencies = getSyllabusDependencies(course, syllabuses)
  const programDependencies = new Set<string>()

  syllabusDependencies.forEach((syllabus) => {
    const programName = syllabus.programDisplayName
    if (programName) {
      programDependencies.add(programName)
    }
  })
  return programDependencies
}

function getSyllabusDependencies(
  course: CourseDto,
  syllabuses: Syllabus[],
): Syllabus[] {
  return syllabuses.filter((syllabus) => {
    return syllabus.units.some((unit) =>
      unit.courseDescriptors.some((cd) => cd.courseId === course.id),
    )
  })
}

export const createCourse = createAppAsyncThunk<CourseDto, void, undefined>(
  "coursesMenu/createCourse",
  async () => {
    return CourseClient.createCourse({
      name: "",
      description: "",
      skillsAcquired: [],
      priorKnowledge: [],
      syllabus: {
        topics: [],
      },
      domains: [],
      createdBy: NIL_UUID,
    })
  },
)

export const duplicateCourse = createAppAsyncThunk<
  {
    course: CourseDto
    originalCourseId: string
  },
  CourseDto,
  undefined
>("coursesMenu/duplicateCourse", async (course: CourseDto) => {
  const duplicatedCourse = {
    ...course,
    title: course.title + " (Copy)",
  }
  return CourseClient.createCourse({
    ...duplicatedCourse,
  }).then((duplicatedCourse) => {
    return {
      course: duplicatedCourse,
      originalCourseId: course.id,
    }
  })
})

export const createNewCourseVersion = createAppAsyncThunk<
  CourseDto | undefined,
  CourseDto,
  undefined
>(
  "courseEditor/createNewCourseVersion",
  async (course: CourseDto, thunkAPI) => {
    if (course.status !== CourseDtoStatusEnum.Published) {
      return
    }
    const latestCourseVersion = selectLatestDraftCourse(course.id)(
      thunkAPI.getState(),
    )
    if (latestCourseVersion?.status === CourseDtoStatusEnum.Draft) {
      return
    }

    return CourseClient.updateCourse(
      {
        ...course,
        status: statusToUpdateStatus(course.status),
      },
      course.id,
    )
  },
)

export const publishFromDescriptor = createAppAsyncThunk(
  "coursesMenu/publishFromDescriptor",
  async (courseId: string) => {
    return CourseClient.publishCourse(courseId)
  },
)

export const archiveCourse = createAppAsyncThunk(
  "coursesMenu/archiveCourse",
  async (courseId: string) => {
    return CourseClient.archiveCourse(courseId)
  },
)

export const deleteCourse = createAppAsyncThunk(
  "coursesMenu/deleteCourse",
  async (courseId: string) => {
    return CourseClient.deleteCourse(courseId).then(() => {
      return {
        id: courseId,
      }
    })
  },
)

export const {
  toggleIsCourseFavorite,
  sortAlphabeticallyClicked,
  sortChronologicallyClicked,
  tabSelected,
  clickedAwayHighlightedCourse,
} = coursesMenuSlice.actions

export default coursesMenuSlice.reducer
