import { useAppDispatch, useAppSelector } from "@app/hooks"
import { Button, Slide, Snackbar, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { selectSyllabusStaleCourses } from "../../../features/syllabus/syllabusSelectors"
import { staleCoursesSnackbarClosed } from "../../../features/ui/uiSlice"
import StaleCourseModel, {
  compareStaleCoursesModels,
} from "./staleCoursesPopup/staleCourseModel"
import appTheme from "../../../theme/appTheme"

function StaleCoursesSnackbarPresenter() {
  const { syllabusId } = useParams()
  const [searchParams] = useSearchParams()
  const syllabusStaleCourses = useAppSelector(
    selectSyllabusStaleCourses(syllabusId),
  )
  const presentationHistory = useAppSelector(
    (state) => state.ui.sessionHistory.presentedStaleCoursesSnackbar,
  )
  const hasUnitsWithCoursesWithNewerVersions = syllabusStaleCourses.length > 0

  const popup = searchParams.get("popup")

  const presentingPopup = popup === "reviewStaleCourses"

  const didAlreadyShowStaleCoursesSnackbar = syllabusStaleCourses.every(
    (staleCourse) =>
      presentationHistory.some((presented) =>
        compareStaleCoursesModels(staleCourse, presented),
      ),
  )

  const open =
    !presentingPopup &&
    hasUnitsWithCoursesWithNewerVersions &&
    !didAlreadyShowStaleCoursesSnackbar

  if (!syllabusId) {
    return null
  }

  return (
    <StaleCoursesSnackbar
      open={open}
      syllabusId={syllabusId}
      staleCourses={syllabusStaleCourses}
    />
  )
}

function StaleCoursesSnackbar(props: {
  open: boolean
  syllabusId: string
  staleCourses: StaleCourseModel[]
}) {
  const { open, syllabusId, staleCourses } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return (
    <Snackbar
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      message={
        <>
          <SvgIcon
            inheritViewBox
            component={appIcons.infoCircle}
            sx={{
              width: 20,
              height: 20,
              mr: 1,
              color: "primary.contrast",
              stroke: "currentcolor",
              fill: "none",
            }}
          />
          There are updated versions of courses in this syllabus.
        </>
      }
      action={
        <Button
          size="small"
          variant="text"
          onClick={() => {
            dispatch(staleCoursesSnackbarClosed(staleCourses))
            searchParams.set("popup", "reviewStaleCourses")
            searchParams.set(
              "type",
              staleCourses.length > 1 ? "multi" : "single",
            )
            navigate({
              pathname: `/syllabus-edit/${syllabusId}`,
              search: searchParams.toString(),
            })
          }}
          sx={{
            color: "white",
            "&:hover": {
              bgcolor: appTheme.palette.action.active,
            },
          }}
        >
          Review
        </Button>
      }
      sx={{
        borderRadius: "4px",
        gap: "12px",
        display: "flex",
        flexDirection: "row",
        zIndex: 1,
      }}
    />
  )
}

export default StaleCoursesSnackbarPresenter
