import { Navigate, Outlet } from "react-router-dom"
import { useAppSelector } from "@app/hooks"
import { selectIsLoggedIn } from "../features/user/userSlice"

const ProtectedRoute = (): React.ReactElement | null => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  console.log("ProtectedRoute/isLoggedIn", isLoggedIn)

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoute
