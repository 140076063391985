import { useAppDispatch, useAppSelector } from "@app/hooks"
import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { selectSyllabusStaleCourses } from "../../../../features/syllabus/syllabusSelectors"
import {
  reviewStaleCoursesRequested,
  reviewUpdatedCoursesClosed,
  updateStaleCourse,
} from "../../../../features/syllabusEditor/syllabusEditorSlice"
import SyllabusValidationVersionHistory from "../syllabusValidationVersionHistory/syllabusValidationVersionHistory"
import MultiStaleCoursesPopup from "./multiStaleCoursesPopup"
import SingleStaleCoursePopup from "./singleStaleCoursePopup"
import StaleCourseModel from "./staleCourseModel"

function StaleCoursesPopupPresenter() {
  const { syllabusId } = useParams()
  const [searchParams] = useSearchParams()
  const popup = searchParams.get("popup")
  const type = searchParams.get("type") as "multi" | "single" | undefined
  const dispatch = useAppDispatch()
  const syllabusStaleCourses = useAppSelector(
    selectSyllabusStaleCourses(syllabusId),
  )
  const staleCoursesIds = syllabusStaleCourses.map(
    (staleCourse) => staleCourse.courseId,
  )

  useEffect(() => {
    if (popup !== "reviewStaleCourses") {
      return
    }

    dispatch(reviewStaleCoursesRequested({ staleCoursesIds }))
  }, [dispatch, popup, staleCoursesIds])

  return <StaleCoursesPopup staleCourses={syllabusStaleCourses} type={type} />
}

function StaleCoursesPopup(props: {
  staleCourses: StaleCourseModel[]
  type: "multi" | "single" | undefined
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { staleCourses, type } = props
  const staleCoursesPopupState = useAppSelector(
    (state) => state.syllabusEditor.reviewStaleCoursesPopup,
  )
  const dispatch = useAppDispatch()
  const [versionHistoryCourseToDisplay, setVersionHistoryCourseToDisplay] =
    useState<StaleCourseModel | undefined>(undefined)

  if (!staleCoursesPopupState) {
    return null
  }

  const close = () => {
    searchParams.delete("popup")
    setSearchParams(searchParams, { replace: true })
    dispatch(reviewUpdatedCoursesClosed())
  }

  if (staleCourses.length === 0) {
    return null
  }

  return (
    <>
      {(() => {
        switch (type) {
          case "single":
            return (
              <SingleStaleCoursePopup
                staleCourse={staleCourses[0]}
                onClose={close}
                onView={() => {
                  setVersionHistoryCourseToDisplay(staleCourses[0])
                }}
                onUpdate={() => {
                  dispatch(updateStaleCourse(staleCourses[0])).then(close)
                }}
              />
            )
          case "multi":
          case undefined:
            return (
              <MultiStaleCoursesPopup
                staleCourses={staleCourses}
                onClose={close}
                onView={(staleCourse) => {
                  setVersionHistoryCourseToDisplay(staleCourse)
                }}
                onUpdate={(staleCourse) => {
                  dispatch(updateStaleCourse(staleCourse))
                }}
                onUpdateAll={async () => {
                  Promise.all(
                    staleCourses.map((staleCourse) =>
                      dispatch(updateStaleCourse(staleCourse)),
                    ),
                  ).then(close)
                }}
              />
            )
        }
      })()}
      {versionHistoryCourseToDisplay && (
        <SyllabusValidationVersionHistory
          staleCourse={versionHistoryCourseToDisplay}
          onClose={() => {
            setVersionHistoryCourseToDisplay(undefined)
          }}
        />
      )}
    </>
  )
}

export default StaleCoursesPopupPresenter
