import { useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAppDispatch } from "../app/hooks"
import { fetchUserFromTemporaryToken } from "../features/user/userSlice"

export function LoginProxyToken() {
  const { token, nextURL } = useParams()
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!token) {
      console.error("Reached login proxy without token")
      navigate("/auth", { replace: true })
      return
    }
    console.log("Reached login proxy with token", token)

    const redirectUrl = searchParams.get("redirectUrl")
    console.log("redirectUrl", redirectUrl)
    dispatch(fetchUserFromTemporaryToken(token))
      .then(() => {
        console.log("got user")
        if (redirectUrl) {
          console.log("navigating to redirect url")
          navigate(redirectUrl, { replace: true })
        } else if (nextURL) {
          console.log("nextURL", nextURL)
          navigate(`${nextURL}`, { replace: true })
        } else {
          console.log("navigating to /")
          navigate("/", { replace: true })
        }
      })
      .catch((err) => {
        console.error("Error creating permanent token", err)
        navigate("/auth", { replace: true })
      })
  }, [dispatch, navigate, nextURL, searchParams, token])

  return null
}
