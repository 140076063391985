import {
  CourseSyllabusElementTypeEnum,
  MasterschoolLesson,
  DatacampLesson,
  UdacityLesson,
  Test,
  Survey,
  NotionLesson,
  TryHackmeLesson,
  OtherLesson,
  CourseraLesson,
  RunestoneLesson,
  CodioProject,
  EmptyLesson,
  EmptyProject,
} from "@masterschool/course-builder-api"
import useSelectedElement from "./useSelectedElement"

export function useSelectedMasterschoolLesson() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.MasterschoolLesson)
    ?.item as MasterschoolLesson | undefined
}

export function useSelectedDatacampLesson() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.DatacampLesson)
    ?.item as DatacampLesson | undefined
}

export function useSelectedEmptyLesson() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.EmptyLesson)?.item as
    | EmptyLesson
    | undefined
}

export function useSelectedNotionLesson() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.NotionLesson)
    ?.item as NotionLesson | undefined
}

export function useSelectedOtherLesson() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.OtherLesson)?.item as
    | OtherLesson
    | undefined
}

export function useSelectedCourseraLesson() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.CourseraLesson)
    ?.item as CourseraLesson | undefined
}

export function useSelectedTryHackmeLesson() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.TryHackmeLesson)
    ?.item as TryHackmeLesson | undefined
}

export function useSelectedUdacityLesson() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.UdacityLesson)
    ?.item as UdacityLesson | undefined
}

export function useSelectRunestoneLesson() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.RunestoneLesson)
    ?.item as RunestoneLesson | undefined
}

export function useSelectedEmptyProject() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.EmptyProject)
    ?.item as EmptyProject | undefined
}

export function useSelectedCodioProject() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.CodioProject)
    ?.item as CodioProject | undefined
}

export function useSelectedSurvey() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.Survey)?.item as
    | Survey
    | undefined
}

export function useSelectedTest() {
  return useSelectedElement(CourseSyllabusElementTypeEnum.Test)?.item as
    | Test
    | undefined
}
