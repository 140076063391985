import { useAppDispatch } from "@app/hooks"
import { elementRemoved } from "../../../features/courseEditor/courseEditorSlice"

/*
 * This hook is used to remove an element from the course editor.
 */
function useRemoveElement() {
  const dispatch = useAppDispatch()

  return ({ topicId, elementId }: { topicId?: string; elementId?: string }) => {
    if (!elementId || !topicId) {
      return
    }
    dispatch(
      elementRemoved({
        editStepId: window.crypto.randomUUID(),
        topicId: topicId,
        elementId: elementId,
      }),
    )
  }
}

export default useRemoveElement
