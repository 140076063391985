import {
  CourseState,
  TabIdentifierEnumeration,
} from "../features/coursesMenu/coursesMenuSlice"
import { FilterName, SearchAdvanceFilter } from "../features/search/searchSlice"
import { CourseDtoStatusEnum } from "@masterschool/course-builder-api"

export const filterCourses = (
  courses: CourseState[],
  searchText: string,
  filters: SearchAdvanceFilter[],
  tab: TabIdentifierEnumeration,
  favoriteCourses: string[],
) => {
  const withoutOldVersions = filterCoursesOldVersions(courses)
  const bySearchText = filterCoursesBySearchText(withoutOldVersions, searchText)
  const byTab = filterCoursesByTab(bySearchText, tab, favoriteCourses)
  return filterCoursesByAdvanceFilter(byTab, filters)
}

export const filterCoursesOldVersions: (c: CourseState[]) => CourseState[] = (
  courses: CourseState[],
) =>
  Object.values(
    courses.reduce((acc: { [key: string]: any }, current) => {
      if (
        !acc[current.id] ||
        (current.version ?? 1) > acc[current.id].version
      ) {
        acc[current.id] = current
      }
      return acc
    }, {}),
  )

export const filterCoursesBySearchText = (
  courses: CourseState[],
  searchText: string,
) => {
  if (searchText === "") {
    return courses
  }

  const filteredByName = courses.filter((course) =>
    course.name.toLowerCase().includes(searchText.toLowerCase()),
  )

  const filterByDescription = courses.filter((course) =>
    course.description.toLowerCase().includes(searchText.toLowerCase()),
  )

  const filterByTitle = courses.filter((course) =>
    course?.title?.toLowerCase().includes(searchText.toLowerCase()),
  )

  const filterByShortTitle = courses.filter((course) =>
    course?.shortTitle?.toLowerCase().includes(searchText.toLowerCase()),
  )

  return [
    ...new Set([
      ...filterByDescription,
      ...filteredByName,
      ...filterByTitle,
      ...filterByShortTitle,
    ]),
  ]
}

const filterCoursesByTab = (
  courses: CourseState[],
  tab: TabIdentifierEnumeration,
  favoriteCourses: string[],
) => {
  if (tab === TabIdentifierEnumeration.All) {
    return courses
  }

  if (tab === TabIdentifierEnumeration.Favorites) {
    return courses.filter((course) => favoriteCourses.includes(course.id))
  }

  return courses.filter((course) => compareTabAndStatus(tab, course.status))
}

const compareTabAndStatus = (
  tab: TabIdentifierEnumeration,
  status: CourseDtoStatusEnum,
) => {
  if (tab === TabIdentifierEnumeration.Published) {
    return status === CourseDtoStatusEnum.Published
  }

  if (tab === TabIdentifierEnumeration.Draft) {
    return status === CourseDtoStatusEnum.Draft
  }

  if (tab === TabIdentifierEnumeration.Archived) {
    return status === CourseDtoStatusEnum.Archived
  }

  return false
}

function filterCoursesByAdvanceFilter(
  courses: CourseState[],
  filters: SearchAdvanceFilter[],
) {
  const domainFilter = filters.find(
    (filter) => filter.filterName === FilterName.Domains,
  )
  const syllabusFilter = filters.find(
    (filter) => filter.filterName === FilterName.Syllabuses,
  )
  if (!domainFilter && !syllabusFilter) {
    return courses
  }

  const filteredByDomains = filterCoursesByDomains(
    courses,
    domainFilter?.selectedValues ?? [],
  )
  const filteredBySyllabuses = filterCoursesBySyllabuses(
    filteredByDomains,
    syllabusFilter?.selectedValues ?? [],
  )

  return filteredBySyllabuses
}

function filterCoursesByDomains(courses: CourseState[], domains: string[]) {
  if (domains.length === 0) {
    return courses
  }

  return courses.filter((course) =>
    course.domains.some((domain) => domains.includes(domain)),
  )
}

function filterCoursesBySyllabuses(
  courses: CourseState[],
  syllabuses: string[],
) {
  if (syllabuses.length === 0) {
    return courses
  }

  return courses.filter(
    (course) =>
      course.syllabusDependencies.filter((sd) => syllabuses.includes(sd))
        .length > 0,
  )
}
