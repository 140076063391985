import { useAppSelector } from "@app/hooks"
import { CourseDto } from "@masterschool/course-builder-api"
import { selectActiveCourse } from "../features/courseEditor/courseEditorSelectors"
import CourseStatus from "./chipStatus"
import { courseStatusTextAndColor } from "./courseDtoStatusEnum+Ui"
import {
  selectIsEditingPublishedCourse,
  selectLatestPublishedCourse,
} from "../features/coursesMenu/coursesSelectors"
import { areCourseDtosEqual, courseStateToDto } from "@utils/courseUtils"

function CourseEditorStatus(props: { course: CourseDto }) {
  const { course } = props
  const currentEditedCourseDto = useAppSelector(selectActiveCourse)
  const publishedCourseState = useAppSelector(
    selectLatestPublishedCourse(course.id),
  )
  const isEditingPublishedCourse = useAppSelector(
    selectIsEditingPublishedCourse(course.id),
  )

  const hasChanges =
    currentEditedCourseDto !== undefined &&
    publishedCourseState !== undefined &&
    !areCourseDtosEqual(
      courseStateToDto(publishedCourseState),
      currentEditedCourseDto,
    )

  const statusTextAndColor = isEditingPublishedCourse
    ? {
        text: "Published",
        color: "success" as const,
      }
    : courseStatusTextAndColor(course.status)

  return (
    <CourseStatus
      text={statusTextAndColor.text}
      color={statusTextAndColor.color}
      showIndicator={hasChanges}
    />
  )
}

export default CourseEditorStatus
