import { CourseDto } from "@masterschool/course-builder-api"
import { CourseState } from "../features/coursesMenu/coursesMenuSlice"

export const courseStateToDto = (course: CourseState): CourseDto => {
  return {
    id: course.id,
    version: course.version,
    name: course.name,
    status: course.status,
    title: course.title,
    shortTitle: course.shortTitle,
    description: course.description,
    skillsAcquired: course.skillsAcquired,
    priorKnowledge: course.priorKnowledge,
    syllabus: course.syllabus,
    createdAtTimestamp: course.createdAtTimestamp,
    domains: course.domains,
    createdBy: course.createdBy,
  }
}

export const areCourseDtosEqual = (dto1: CourseDto, dto2: CourseDto) => {
  return (
    JSON.stringify(extractCourseDtoFieldsToCompare(dto1)) ===
    JSON.stringify(extractCourseDtoFieldsToCompare(dto2))
  )
}

export const areCourseStatesEqual = (
  state1: CourseState,
  state2: CourseState,
) => {
  const dto1 = courseStateToDto(state1)
  const dto2 = courseStateToDto(state2)
  return (
    JSON.stringify(extractCourseDtoFieldsToCompare(dto1)) ===
    JSON.stringify(extractCourseDtoFieldsToCompare(dto2))
  )
}

const extractCourseDtoFieldsToCompare = (course: CourseDto) => {
  return {
    id: course.id,
    name: course.name,
    title: course.title,
    shortTitle: course.shortTitle,
    description: course.description,
    skillsAcquired: course.skillsAcquired,
    priorKnowledge: course.priorKnowledge,
    syllabus: course.syllabus,
    domains: course.domains,
    createdBy: course.createdBy,
  }
}
