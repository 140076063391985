import { useAppSelector } from "@app/hooks"
import OptionsButton from "@cmp/buttons/optionsButton"
import {
  CourseDto,
  CourseDtoStatusEnum,
} from "@masterschool/course-builder-api"
import { SxProps, Theme } from "@mui/material"
import appIcons from "@utils/appIcons"
import useCourseMenuActions from "./useCourseMenuActions"
import { selectCanPreviewCourse } from "../../features/courseEditor/courseEditorSelectors"

function CourseDescriptorMenu(props: {
  course: CourseDto
  sx?: SxProps<Theme>
  iconSx?: SxProps<Theme>
}) {
  const {
    onOpenClicked,
    onPreviewInCampusClicked,
    onEditClicked,
    onDuplicateClicked,
    onArchiveClicked,
    onVersionHistoryClicked,
    onDependentSyllabusesClicked,
    onDeleteClicked,
  } = useCourseMenuActions(props.course)

  const disableArchive = useAppSelector((state) => {
    const courses = state.coursesMenu.courses
    if (courses === "pending" || courses === "rejected") {
      return true
    }

    const course = courses.find((course) => course.id === props.course.id)

    if (!course) {
      return true
    }

    return course.syllabusDependencies.length > 0
  })
  const canPreview = useAppSelector(selectCanPreviewCourse(props.course.id))

  const publishedItems = [
    {
      text: "Open",
      onSelect: onOpenClicked,
    },
    {
      text: "Preview in campus",
      onSelect: onPreviewInCampusClicked,
      disabled: !canPreview,
    },
    {
      text: "Edit",
      onSelect: () => {
        onEditClicked(props.course)
      },
    },
    {
      text: "Duplicate",
      onSelect: onDuplicateClicked,
    },
    {
      text: "Archive",
      onSelect: onArchiveClicked,
      disabled: disableArchive,
    },
    {
      text: "View version history",
      onSelect: onVersionHistoryClicked,
    },
    {
      text: "Show dependent syllabuses",
      onSelect: onDependentSyllabusesClicked,
    },
  ]

  const draftItems = [
    {
      text: "Open",
      onSelect: onOpenClicked,
    },
    {
      text: "Preview in campus",
      onSelect: onPreviewInCampusClicked,
      disabled: !canPreview,
    },
    {
      text: "Duplicate",
      onSelect: onDuplicateClicked,
    },
    {
      text: "Archive",
      onSelect: onArchiveClicked,
    },
  ]

  const archivedItems = [
    {
      text: "Preview",
      onSelect: onPreviewInCampusClicked,
      disabled: !canPreview,
    },
    {
      text: "Delete",
      onSelect: onDeleteClicked,
    },
  ]

  const items = {
    [CourseDtoStatusEnum.Published]: publishedItems,
    [CourseDtoStatusEnum.Draft]: draftItems,
    [CourseDtoStatusEnum.Archived]: archivedItems,
  }[props.course.status]

  return (
    <OptionsButton
      button={{
        sx: { padding: "2px" },
        leftIcon: appIcons.dots,
        iconSx: props.iconSx,
      }}
      items={items}
      sx={props.sx}
    />
  )
}

export default CourseDescriptorMenu
