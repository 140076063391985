import { RootState } from "@app/store"
import { Theme } from "@emotion/react"
import { SxProps } from "@mui/material"
import { createSelector } from "@reduxjs/toolkit"
import appTheme from "../../theme/appTheme"
import { SnackbarAnchorOrigin, SnackbarType } from "./uiSlice"

export const selectPopup = (state: RootState) => state.ui.popup
const selectSyllabuses = (state: RootState) => state.syllabusesMenu.syllabuses
const selectSnackbar = (state: RootState) => state.ui.snackbar

export type SnackbarPresentationState = {
  type: SnackbarType
  duration: number
  message: string
  bgcolor: string
  anchorOrigin: SnackbarAnchorOrigin
  sx: SxProps<Theme>
}

export const selectDependentSyllabuses = (courseId?: string) =>
  createSelector([selectSyllabuses], (syllabuses) => {
    if (!courseId) {
      return []
    }

    return syllabuses
      .filter((s) =>
        s.units.some((u) =>
          u.courseDescriptors.some((cd) => cd.courseId === courseId),
        ),
      )
      .map((s) => s.id)
  })

export const selectSnackbarPresentationState = createSelector(
  [selectSnackbar],
  (snackbar): SnackbarPresentationState | undefined => {
    if (snackbar === undefined) {
      return undefined
    }

    return {
      type: snackbar.type,
      message: snackbarTypeToMessage(snackbar.type),
      bgcolor: snackbarTypeToBackgroundColor(snackbar.type),
      duration: snackbar.duration,
      anchorOrigin: snackbar.anchorOrigin,
      sx: {
        padding: "0px",
      },
    }
  },
)

function snackbarTypeToMessage(type: SnackbarType) {
  switch (type) {
    case SnackbarType.archiveCourseSuccess:
      return `The course was archived`
    case SnackbarType.archiveCourseError:
      return `Failed to archive course`
    case SnackbarType.unpublishedChanges:
      return `This course has unpublished changes`
    case SnackbarType.duplicateCourseSuccess:
      return `The course was duplicated`
    case SnackbarType.duplicateCourseError:
      return `Failed to duplicate course`
    case SnackbarType.duplicateSyllabusSuccess:
      return `The syllabus was duplicated`
    case SnackbarType.duplicateSyllabusError:
      return `The syllabus was duplicated`
    case SnackbarType.archiveSyllabusSuccess:
      return `The syllabus was archived`
    case SnackbarType.archiveSyllabusError:
      return `Failed to archive syllabus`
  }
}

function snackbarTypeToBackgroundColor(type: SnackbarType) {
  switch (type) {
    case SnackbarType.archiveCourseSuccess:
    case SnackbarType.unpublishedChanges:
    case SnackbarType.duplicateCourseSuccess:
    case SnackbarType.duplicateSyllabusSuccess:
    case SnackbarType.archiveSyllabusSuccess:
      return appTheme.palette.other.snackbarBackground
    case SnackbarType.archiveCourseError:
    case SnackbarType.duplicateCourseError:
    case SnackbarType.duplicateSyllabusError:
    case SnackbarType.archiveSyllabusError:
      return appTheme.palette.error.main
  }
}
