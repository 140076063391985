import { store } from "@app/store"
import {
  Configuration,
  CourseDto,
  CourseDtoStatusEnum,
  CourseItemValidation,
  CourseProvisionsApi,
  CoursesApi,
  CreateCourseRequest,
  UpdateCourseRequest,
  UpdateCourseRequestStatusEnum,
} from "@masterschool/course-builder-api"
import { selectAccessToken } from "../features/user/userSlice"

export type ClientResponse<S, F> =
  | {
      kind: "success"
      value: S
    }
  | {
      kind: "failure"
      value: F
    }

export class CourseClient {
  private static courseClient = new CoursesApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static courseProvisionsClient = new CourseProvisionsApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static createCourse(course: CreateCourseRequest) {
    return CourseClient.courseClient
      .courseControllerCreate(course, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static listCourses() {
    return CourseClient.courseClient
      .courseControllerList(CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getCourse(id: string) {
    return CourseClient.courseClient
      .courseControllerGet(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getCourseByVersion(id: string, version: number) {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerGet(id, version, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static updateCourse(request: UpdateCourseRequest, id: string) {
    return CourseClient.courseClient
      .courseControllerSave(request, id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static removeCourse(id: string) {
    return CourseClient.courseClient
      .courseControllerRemove(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static discardDraft(id: string) {
    return CourseClient.courseClient
      .courseControllerDiscardDraft(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static publishCourse(
    id: string,
  ): Promise<ClientResponse<CourseDto, CourseItemValidation>> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerPublish(id, CourseClient.makeHeaders())
      .then((response) => {
        return {
          kind: "success" as const,
          value: response.data,
        }
      })
      .catch((e) => {
        const validations = e.response.data.message
          .errors as CourseItemValidation
        if (
          e.response.data.message.message === "Invalid publish course request"
        ) {
          return {
            kind: "failure" as const,
            value: validations,
          }
        }

        throw e
      })
  }

  static archiveCourse(id: string): Promise<CourseDto> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerArchive(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static listCourseVersions(id: string) {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerList(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static deleteCourse(id: string) {
    return CourseClient.courseClient
      .courseControllerRemove(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getValidations(id: string) {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerGetPublishValidations(
        id,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static revertToVersion(id: string, version: number) {
    return CourseClient.getCourseByVersion(id, version).then((course) => {
      if (!course) {
        throw new Error("Course not found")
      }

      return CourseClient.updateCourse(
        {
          ...course,
          status: undefined,
        },
        id,
      )
    })
  }
}

export function statusToUpdateStatus(
  status: CourseDtoStatusEnum,
): UpdateCourseRequestStatusEnum {
  switch (status) {
    case CourseDtoStatusEnum.Draft:
      return UpdateCourseRequestStatusEnum.Draft
    case CourseDtoStatusEnum.Published:
      return UpdateCourseRequestStatusEnum.Published
    case CourseDtoStatusEnum.Archived:
      return UpdateCourseRequestStatusEnum.Archived

    default:
      throw new Error(`Unknown status ${status}`)
  }
}

export function updateStatusToStatus(
  status: CourseDtoStatusEnum,
): UpdateCourseRequestStatusEnum {
  switch (status) {
    case CourseDtoStatusEnum.Draft:
      return UpdateCourseRequestStatusEnum.Draft
    case CourseDtoStatusEnum.Published:
      return UpdateCourseRequestStatusEnum.Published
    case CourseDtoStatusEnum.Archived:
      return UpdateCourseRequestStatusEnum.Archived
    default:
      throw new Error(`Unknown status ${status}`)
  }
}
