import { useAppSelector } from "@app/hooks"
import { Box, Chip, Typography } from "@mui/material"
import { addDaysToDate, formatDateAsMonthAndDay } from "@utils/date"
import { startDateToCohortStyleDate } from "@utils/syllabusTags"
import { useSearchParams } from "react-router-dom"
import UnitPreviewPanel from "../editor/syllabus/panel/unitPreviewPanel"
import UnitDescriptorView from "../editor/syllabus/units/unitDescriptorView"
import useSelectedUnit from "../editor/syllabus/useSelectedUnit"
import { CourseState } from "../features/coursesMenu/coursesMenuSlice"
import { Syllabus } from "../features/syllabus/syllabusSelectors"
import appTheme from "../theme/appTheme"
import StaleCoursesSnackbarPresenter from "../editor/syllabus/validations/staleCoursesSnackbarPresenter"

export function SyllabusView(props: { syllabus: Syllabus }) {
  const { syllabus } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const unitId = searchParams.get("unitId")
  const selectedUnit = useSelectedUnit()
  const syllabusTitlePrefix = selectedUnit
    ? `${selectedUnit.shortTitle || ""}${selectedUnit.unitNumber || ""}`
    : undefined
  const translate = selectedUnit !== undefined

  const onUnitClicked = (unitId: string) => {
    setSearchParams({ ...searchParams, unitId }, { replace: true })
  }
  const courses = useAppSelector((state) => state.coursesMenu.courses)
  const weeksPerUnit = numberOfTopicsPerUnit(syllabus, courses)
  const unitTimeframes = weeksPerUnit
    ? syllabusUnitTimeframes(syllabus, weeksPerUnit)
    : undefined

  const closeSelectedUnit = () => {
    if (!unitId) return
    searchParams.delete("unitId")
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        overflow: "hidden",
        display: "flex",
      }}
    >
      <StaleCoursesSnackbarPresenter />
      <Box
        sx={{
          width: "60%",
          height: "100%",
          transform: translate ? "translateX(0%)" : "translateX(33%)",
          transition: "all .35s ease-in-out",
          display: "flex",
          justifyContent: "center",
          paddingX: "24px",
        }}
        onClick={closeSelectedUnit}
      >
        <Box
          sx={{
            overflow: "auto",
            height: "100%",
            width: "80%",
            maxWidth: "600px",
            padding: "48px 0px",
            gap: "16px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box marginTop="0px">
            <Typography variant="h4">{syllabus.name}</Typography>
          </Box>
          <Box display="flex" gap="8px">
            <Chip
              label={syllabus.domain}
              size="small"
              sx={{
                width: "fit-content",
              }}
            />
            {syllabus.programStartDate && (
              <Chip
                label={startDateToCohortStyleDate(syllabus.programStartDate)}
                size="small"
                sx={{
                  width: "fit-content",
                }}
              />
            )}
          </Box>
          <Box
            display="flex"
            sx={{
              width: "100%",
              gap: "16px",
              display: "flex",
              flexDirection: "column",
              marginTop: "24px",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {syllabus.units.map((unit, i) => {
              return (
                <UnitDescriptorView
                  key={unit.id}
                  index={i}
                  syllabusId={syllabus.id}
                  unit={unit}
                  editMode={false}
                  unitTimeFrame={unitTimeframes ? unitTimeframes[i] : undefined}
                  onClick={() => onUnitClicked(unit.id)}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "40%",
          height: "100%",
          transform: translate ? "translateX(0%)" : "translateX(100%)",
          transition: "all .35s ease-in-out",
          position: "relative",
          ":before": {
            content: '""',
            borderLeft: `1px solid ${appTheme.palette.divider}`,
            width: "1px",
            height: "100%",
            position: "absolute",
            top: "0",
          },
        }}
      >
        {selectedUnit && (
          <UnitPreviewPanel syllabusTitlePrefix={syllabusTitlePrefix} />
        )}
      </Box>
    </Box>
  )
}

function syllabusUnitTimeframes(
  syllabus: Syllabus,
  weeksPerUnit: number[],
): string[] | undefined {
  const programStartDate = syllabus.programStartDate
  if (!programStartDate) return undefined

  const timeframes: { startDate: Date; endDate: Date }[] = []
  syllabus.units.forEach((unit, i) => {
    const weeksInCourse = weeksPerUnit[i]
    const courseStartDate =
      i === 0
        ? new Date(programStartDate)
        : addDaysToDate(timeframes[i - 1].endDate, 1)

    timeframes.push(courseTimeframe(courseStartDate, weeksInCourse))
  })
  return timeframes.map(formattedTimeframe)
}

function courseTimeframe(startDate: Date, weeksInCourse: number) {
  const daysToAdd = weeksInCourse * 7 - 1
  const courseEndDate = addDaysToDate(startDate, daysToAdd)
  return {
    startDate,
    endDate: courseEndDate,
  }
}

function formattedTimeframe(timeframe: { startDate: Date; endDate: Date }) {
  return `${formatDateAsMonthAndDay(
    timeframe.startDate,
  )} - ${formatDateAsMonthAndDay(timeframe.endDate)}`
}

function numberOfTopicsPerUnit(
  syllabus: Syllabus,
  allCourses: CourseState[] | "pending" | "rejected",
): number[] | undefined {
  if (!allCourses || allCourses === "pending" || allCourses === "rejected") {
    return undefined
  }
  return syllabus.units.map((unit) => {
    if (unit.courseDescriptors.length === 0) {
      return 4
    }

    return unit.courseDescriptors.reduce((acc, courseDescriptor) => {
      const course = allCourses.find((c) => c.id === courseDescriptor.courseId)
      if (course) {
        return acc + course.syllabus.topics.length
      }
      return acc
    }, 0)
  })
}
