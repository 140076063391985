import { useAppSelector } from "@app/hooks"
import { selectPublishValidationsForItem } from "../../features/courseEditor/courseValidationsSelectors"
import EditorTextField, { EditorTextFieldProps } from "./editorTextField"

export type TimeEstimationTextFieldProps = EditorTextFieldProps & {
  elementId: string
}

function TimeEstimationTextField(props: TimeEstimationTextFieldProps) {
  const errors = useAppSelector(
    selectPublishValidationsForItem(props.elementId),
  )

  const errorMessage = errors?.includes("INVALID_ESTIMATED_DURATION")
    ? "This field must be a positive number"
    : undefined
  const { elementId, ...domProps } = props

  return (
    <EditorTextField
      type="number"
      label="Est. time (mins)"
      error={errorMessage !== undefined}
      helperText={errorMessage}
      InputProps={{
        sx: { fontSize: "14px" },
        inputProps: {
          min: 0,
        },
      }}
      {...domProps}
    />
  )
}

export default TimeEstimationTextField
