export function formatDateAsMonthAndDay(date: string | Date): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  }
  return new Date(date).toLocaleDateString("en-US", options)
}

export function addDaysToDate(
  date: Date | string | number,
  daysToAdd: number,
): Date {
  const dateCopy = new Date(date)
  dateCopy.setUTCDate(dateCopy.getUTCDate() + daysToAdd)
  return dateCopy
}
