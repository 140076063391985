import { SprintDto } from "@masterschool/course-builder-api"
import { TOPIC_DESCRIPTOR_CLASS_NAME } from "./unitContentEditor"
import { SPRINTS_TIMELINE_HEADER_ID } from "./unitSprintsTimeline"

export const SPRINT_ELEMENT_ID_PREFIX = "sprint-"
export const PADDING_BETWEEN_SPRINTS = 8

export function calcTopMarginForSprintTimeline() {
  const timelineHeader = document.getElementById(SPRINTS_TIMELINE_HEADER_ID)
  if (!timelineHeader) return 0
  return timelineHeader.offsetTop + timelineHeader.offsetHeight
}

export function calcSnappedSprintHeights(sprints: SprintDto[]) {
  const heights: number[] = []
  for (let i = 0; i < sprints.length; i++) {
    const sprint = sprints[i]
    const lastTopicOfSprint = document.getElementById(sprint.lastTopicId)
    if (!lastTopicOfSprint) {
      heights.push(0)
      continue
    }
    const firstElementTopOffset = calcTopMarginForSprintTimeline()
    const bottomOfLastElement =
      lastTopicOfSprint.offsetTop +
      lastTopicOfSprint.offsetHeight -
      firstElementTopOffset
    const previousHeights = sum(heights.slice(0, i))
    const paddingSoFar = PADDING_BETWEEN_SPRINTS * i
    heights.push(bottomOfLastElement - previousHeights - paddingSoFar)
  }
  return heights
}

function sum(array: number[]) {
  return array.reduce((a, b) => a + b, 0)
}

export function getSprintBottomOffset(index: number) {
  const sprint = document.getElementById(`${SPRINT_ELEMENT_ID_PREFIX}${index}`)
  if (!sprint) {
    return 0
  }
  return sprint.offsetTop + sprint.clientHeight
}

export function findClosestItem(offsetAtEndOfResize: number): string {
  const allTopicDescriptors = Array.from(
    document.getElementsByClassName(TOPIC_DESCRIPTOR_CLASS_NAME),
  )
    .filter((element) => element instanceof HTMLElement)
    .map((element) => {
      const elementBottom =
        (element as HTMLElement).offsetTop +
        (element as HTMLElement).offsetHeight
      return {
        id: element.id,
        offsetTop: elementBottom,
      }
    })
    .sort((a, b) => a.offsetTop - b.offsetTop)

  const timelineHeader = document.getElementById(SPRINTS_TIMELINE_HEADER_ID)
  if (timelineHeader) {
    const timelineHeaderBottom =
      timelineHeader.offsetTop + timelineHeader.offsetHeight
    const distanceFromTopOfTimeline = offsetAtEndOfResize - timelineHeaderBottom
    const distanceFromFirstTopicBottom =
      allTopicDescriptors[0].offsetTop - offsetAtEndOfResize
    if (distanceFromTopOfTimeline < distanceFromFirstTopicBottom) {
      return SPRINTS_TIMELINE_HEADER_ID
    }
  }

  for (let i = 0; i < allTopicDescriptors.length; i++) {
    const currentDescriptor = allTopicDescriptors[i]
    if (currentDescriptor.offsetTop < offsetAtEndOfResize) {
      continue
    }

    if (offsetAtEndOfResize === currentDescriptor.offsetTop) {
      return currentDescriptor.id
    }

    // If we reached here, this is the first element which is below the offsetAtEndOfResize
    if (i === 0) {
      return currentDescriptor.id
    }

    const distanceFromCurrent =
      currentDescriptor.offsetTop - offsetAtEndOfResize
    const previousDescriptor = allTopicDescriptors[i - 1]
    const distanceFromPrevious =
      offsetAtEndOfResize - previousDescriptor.offsetTop

    if (distanceFromCurrent < distanceFromPrevious) {
      return currentDescriptor.id
    } else {
      return previousDescriptor.id
    }
  }

  return allTopicDescriptors[allTopicDescriptors.length - 1].id
}
