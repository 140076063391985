import { Box } from "@mui/material"
import { ReactNode } from "react"

const FullScreenComponent = (props: { children: ReactNode }) => {
  return (
    <Box
      position="absolute"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      top={0}
      left="240px"
      width="calc(100vw - 240px)"
      height="100vh"
      gap="8px"
      sx={{
        backgroundColor: "rgba(255,255,255,0.8)",
        zIndex: 10,
      }}
    >
      {props.children}
    </Box>
  )
}

export default FullScreenComponent
