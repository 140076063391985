import { useAppDispatch, useAppSelector } from "@app/hooks"
import { SearchComponent } from "@cmp/searchComponent"
import {
  courseAdvanceFilterUpdated,
  courseSearchTextUpdated,
  SearchAdvanceFilter,
  SearchComponentFilter,
  selectCoursesSearch,
} from "../features/search/searchSlice"

export const CourseSearchComponent = () => {
  const dispatch = useAppDispatch()

  const searchFilters: SearchComponentFilter =
    useAppSelector(selectCoursesSearch)

  return (
    <SearchComponent
      onSearchTextChange={(text: string) =>
        dispatch(courseSearchTextUpdated(text))
      }
      searchText={searchFilters.text}
      inputFilters={searchFilters.advanceFilters}
      onInputFiltersChange={(advanceFilter: SearchAdvanceFilter[]) => {
        dispatch(courseAdvanceFilterUpdated(advanceFilter))
      }}
      searchPlaceholder="Search for a course name or description"
      filterTitle="Filter courses"
    />
  )
}
