import { selectAccessToken } from "../../features/user/userSlice"
import AppObserver from "./appObserver"

const localStorageObserver: AppObserver = {
  didUpdate: (previousState, currentState, action) => {
    const prevAccessToken = selectAccessToken(previousState)
    const newAccessToken = selectAccessToken(currentState)
    if (prevAccessToken !== newAccessToken) {
      localStorage.setItem("apiKey", newAccessToken || "")
    }

    const prevFavoriteCourses = previousState.coursesMenu.favoriteCourses
    const nextFavoriteCourses = currentState.coursesMenu.favoriteCourses
    if (!isArrayEqual(prevFavoriteCourses, nextFavoriteCourses)) {
      localStorage.setItem(
        "favoriteCourses",
        JSON.stringify(nextFavoriteCourses),
      )
    }
  },
}

function isArrayEqual<T>(array1: T[], array2: T[]): boolean {
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  )
}

export default localStorageObserver
