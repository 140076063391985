import { useAppDispatch } from "@app/hooks"
import CloseIcon from "@mui/icons-material/Close"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material"
import { popupClosed } from "../../features/ui/uiSlice"
import { useNavigate } from "react-router-dom"
import { deleteSyllabus } from "../../features/syllabusEditor/syllabusEditorSlice"

function SyllabusDeleteConfirmationPopup(props: { syllabusId: string }) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const onDelete = () => {
    dispatch(deleteSyllabus(props.syllabusId)).then(() => {
      dispatch(popupClosed())
      navigate("/syllabuses")
    })
  }

  const onCloseClicked = () => {
    dispatch(popupClosed())
  }

  return (
    <Dialog open={true}>
      <DialogTitle sx={{ padding: "48px 0px 0px" }}>
        <IconButton
          sx={{
            position: "absolute",
            padding: "4px",
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onCloseClicked}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "388px",
        }}
      >
        <Typography variant="body1_sb">Delete this syllabus?</Typography>
        <Typography variant="body1">
          It will be removed from existence. Note that courses created for this
          syllabus will not be deleted.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "24px", gap: "8px" }}>
        <Button variant="outlined" size="small" onClick={onCloseClicked}>
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SyllabusDeleteConfirmationPopup
