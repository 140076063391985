import { useAppSelector } from "@app/hooks"
import { selectPublishValidationsForItem } from "../../features/courseEditor/courseValidationsSelectors"
import EditorTextField, { EditorTextFieldProps } from "./editorTextField"

export type UrlTextFieldProps = EditorTextFieldProps & {
  elementId: string
}

function UrlTextField(props: UrlTextFieldProps) {
  const errors = useAppSelector(
    selectPublishValidationsForItem(props.elementId),
  )

  const isMissingUrl =
    isUrlMissing(props.value) && errors?.includes("MISSING_URL")
  const isInvalidUrl =
    isUrlInvalid(props.value) && errors?.includes("INVALID_URL")

  const errorMessage = isMissingUrl
    ? "This field is required"
    : isInvalidUrl
    ? "Invalid URL"
    : undefined

  const { elementId, ...domProps } = props

  return (
    <EditorTextField
      label={props.label || "URL"}
      error={errorMessage !== undefined}
      helperText={errorMessage}
      {...domProps}
    />
  )
}

function isUrlMissing(value: unknown) {
  return value === undefined || value === null || value === ""
}

function isUrlInvalid(value: unknown) {
  if (typeof value !== "string") {
    return true
  }

  try {
    new URL(value)
    return false
  } catch {
    return true
  }
}

export default UrlTextField
