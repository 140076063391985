import { startDateToCohortStyleDate } from "@utils/syllabusTags"
import {
  FilterName,
  SearchAdvanceFilter,
} from "../../features/search/searchSlice"
import { Syllabus } from "../../features/syllabus/syllabusSelectors"
import { SyllabusIdentifierEnumeration } from "../../features/syllabus/syllabusesMenuSlice"
import { SyllabusDtoStatusEnum } from "@masterschool/course-builder-api"

export const filterSyllabuses = (
  syllabuses: Syllabus[],
  searchText: string,
  filters: SearchAdvanceFilter[],
) => {
  const bySearchText = filterSyllabusesBySearchText(syllabuses, searchText)
  return filterCoursesByAdvanceFilter(bySearchText, filters)
}

export const filterSyllabusesBySearchText = (
  syllabuses: Syllabus[],
  searchText: string,
) => {
  if (searchText === "") {
    return syllabuses
  }

  const filteredByName = syllabuses.filter((syllabus) =>
    syllabus.name.toLowerCase().includes(searchText.toLowerCase()),
  )

  const filterByDomain = syllabuses.filter((syllabus) =>
    syllabus.domain.toLowerCase().includes(searchText.toLowerCase()),
  )

  return [...new Set([...filterByDomain, ...filteredByName])]
}

function filterCoursesByAdvanceFilter(
  syllabuses: Syllabus[],
  filters: SearchAdvanceFilter[],
) {
  const domainFilter = filters.find(
    (filter) => filter.filterName === FilterName.Domains,
  )
  const syllabusFilter = filters.find(
    (filter) => filter.filterName === FilterName.Cohort,
  )
  if (!domainFilter && !syllabusFilter) {
    return syllabuses
  }

  const filteredByDomains = filterSyllabusByDomains(
    syllabuses,
    domainFilter?.selectedValues ?? [],
  )
  const filteredBySyllabuses = filterSyllabusByCohort(
    filteredByDomains,
    syllabusFilter?.selectedValues ?? [],
  )

  return filteredBySyllabuses
}

function filterSyllabusByDomains(syllabuses: Syllabus[], domains: string[]) {
  if (domains.length === 0) {
    return syllabuses
  }

  return syllabuses.filter((syllabus) => domains.includes(syllabus.domain))
}

function filterSyllabusByCohort(syllabuses: Syllabus[], cohorts: string[]) {
  if (cohorts.length === 0) {
    return syllabuses
  }

  return syllabuses.filter(
    (syllabus) =>
      syllabus.programStartDate &&
      cohorts.includes(
        startDateToCohortStyleDate(syllabus.programStartDate.toString()),
      ),
  )
}

export function filterSyllabusesByTab(
  syllabuses: Syllabus[],
  tab: SyllabusIdentifierEnumeration,
) {
  if (tab === SyllabusIdentifierEnumeration.Active) {
    return syllabuses.filter((syllabus) => syllabus.isActive)
  }
  const inactiveSyllabuses = syllabuses.filter((syllabus) => !syllabus.isActive)
  switch (tab) {
    case SyllabusIdentifierEnumeration.Draft:
      return inactiveSyllabuses.filter(
        (syllabus) => syllabus.status === SyllabusDtoStatusEnum.Draft,
      )
    case SyllabusIdentifierEnumeration.Archived:
      return inactiveSyllabuses.filter(
        (syllabus) => syllabus.status === SyllabusDtoStatusEnum.Archived,
      )
    case SyllabusIdentifierEnumeration.Published:
      return inactiveSyllabuses.filter(
        (syllabus) => syllabus.status === SyllabusDtoStatusEnum.Published,
      )
    default:
      return syllabuses
  }
}
