import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { CourseDto } from "@masterschool/course-builder-api"
import StaleCourseModel from "../../editor/syllabus/validations/staleCoursesPopup/staleCourseModel"
import { Syllabus } from "../syllabus/syllabusSelectors"

export interface UiState {
  popup: PopupState | undefined
  snackbar: SnackbarState | undefined
  sessionHistory: {
    presentedStaleCoursesSnackbar: StaleCourseModel[]
  }
}

export type PopupState =
  | {
      type: PopupType.ArchiveConfirmation
      course: CourseDto
    }
  | {
      type: PopupType.CampusCoursePreview
      course: CourseDto
    }
  | { type: PopupType.CampusSyllabusPreview; syllabus: Syllabus }
  | {
      type: PopupType.DependentSyllabuses
      course: CourseDto
      syllabusDependencies: string[]
    }
  | {
      type: PopupType.Preview
      course: CourseDto
    }
  | {
      type: PopupType.VersionsHistory
      course: CourseDto
    }
  | {
      type: PopupType.SyllabusDeleteConfirmation
      syllabusId: string
    }
  | {
      type: PopupType.CampusSyllabusJSONPreview
      syllabusId: string
    }

export enum PopupType {
  VersionsHistory = "versions-history",
  DependentSyllabuses = "dependent-syllabuses",
  CampusCoursePreview = "campus-course-preview",
  CampusSyllabusPreview = "campus-syllabus-preview",
  Preview = "preview",
  ArchiveConfirmation = "archive-confirmation",
  SyllabusDeleteConfirmation = "syllabus-delete-confirmation",
  CampusSyllabusJSONPreview = "campus-syllabus-json-preview",
}

type SnackbarState = {
  type: SnackbarType
  duration: SnackbarDuration
  anchorOrigin: SnackbarAnchorOrigin
}

export enum SnackbarType {
  archiveCourseSuccess = "archive-course-success",
  archiveCourseError = "archive-course-error",
  unpublishedChanges = "unpublished-changes",
  duplicateCourseSuccess = "duplicate-course-success",
  duplicateCourseError = "duplicate-course-error",
  duplicateSyllabusSuccess = "duplicate-syllabus-success",
  duplicateSyllabusError = "duplicate-syllabus-error",
  archiveSyllabusSuccess = "archive-syllabus-success",
  archiveSyllabusError = "archive-syllabus-error",
}

export enum SnackbarDuration {
  short = 2000,
  long = 4000,
}

export type SnackbarAnchorOrigin =
  | { vertical: "top"; horizontal: "center" }
  | { vertical: "bottom"; horizontal: "center" }

const initialState: UiState = {
  popup: undefined,
  snackbar: undefined,
  sessionHistory: {
    presentedStaleCoursesSnackbar: [],
  },
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    popupItemClicked: (state, action: PayloadAction<PopupState>) => {
      state.popup = action.payload
    },
    popupClosed: (state) => {
      state.popup = undefined
    },
    showSnackbarItem: (state, action: PayloadAction<SnackbarState>) => {
      state.snackbar = action.payload
    },
    hideSnackbar: (state) => {
      state.snackbar = undefined
    },
    snackbarClicked: (state, action: PayloadAction<{ type: SnackbarType }>) => {
      state.snackbar = undefined
    },
    staleCoursesSnackbarClosed: (
      state,
      action: PayloadAction<StaleCourseModel[]>,
    ) => {
      state.sessionHistory.presentedStaleCoursesSnackbar =
        state.sessionHistory.presentedStaleCoursesSnackbar.concat(
          action.payload,
        )
    },
  },
})

export const {
  popupItemClicked,
  popupClosed,
  showSnackbarItem,
  hideSnackbar,
  snackbarClicked,
  staleCoursesSnackbarClosed,
} = uiSlice.actions

export default uiSlice.reducer
