import { Box, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../theme/appTheme"
import { useAppSelector } from "@app/hooks"
import { useState, useEffect } from "react"
import { selectIsCourseInvalid } from "../features/courseEditor/courseValidationsSelectors"

function CourseEditorErrorBanner() {
  const shouldShow = useAppSelector(selectIsCourseInvalid)

  const [height, setHeight] = useState(shouldShow ? "auto" : 0)

  useEffect(() => {
    setHeight(shouldShow ? "auto" : 0)
  }, [shouldShow])

  const padding = shouldShow ? "24px" : "0"

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        gap: "9px",
        background: appTheme.palette.error.alert?.background,
        overflow: "hidden",
        transition: "all 0.3s ease-in-out",
        padding,
        height,
      }}
    >
      <SvgIcon
        component={appIcons.alertCircle}
        inheritViewBox
        sx={{
          width: "20px",
          height: "20px",
          fill: "none",
          stroke: "#621B16",
        }}
      />
      <Typography color="#621B16">
        Correct the highlighted errors to publish or preview your course
      </Typography>
    </Box>
  )
}

export default CourseEditorErrorBanner
