import { Box, SvgIcon, SvgIconProps, Typography } from "@mui/material"
import appTheme from "../theme/appTheme"
import { GeneralNavigationSideBar } from "./generalNavigationSidebar"
import appIcons from "@utils/appIcons"
import { useLocation, useNavigate } from "react-router-dom"

function Sidebar() {
  const navigate = useNavigate()
  const selectedTab = useSelectedTab()

  const header = (
    <SvgIcon
      component={appIcons.masterschoolLms}
      inheritViewBox
      sx={{
        width: "240px",
        height: "64px",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate("/")
      }}
    />
  )

  const tabs = [
    <SidebarItem
      svgIconProps={{
        component:
          selectedTab === "Syllabuses"
            ? appIcons.syllabusesFilled
            : appIcons.syllabuses,
        sx: { fill: selectedTab === "Syllabuses" ? "black" : "none" },
        stroke:
          selectedTab === "Syllabuses" ? "none" : appTheme.palette.icon.black,
      }}
      text="Syllabuses"
      isSelected={selectedTab === "Syllabuses"}
      onClick={() => {
        navigate("/syllabuses")
      }}
      key="Syllabuses"
    />,
    <SidebarItem
      svgIconProps={{
        component:
          selectedTab === "Courses" ? appIcons.courseFilled : appIcons.course,
        stroke:
          selectedTab === "Courses" ? "none" : appTheme.palette.icon.black,
        sx: { fill: selectedTab === "Courses" ? "black" : "none" },
      }}
      text="Courses"
      isSelected={selectedTab === "Courses"}
      onClick={() => {
        navigate("/courses")
      }}
      key="Courses"
    />,
  ]

  return selectedTab ? (
    <GeneralNavigationSideBar
      tabs={tabs}
      header={header}
      sx={{
        width: "240px",
        maxWidth: "240px",
        minHeight: "100%",
        bgcolor: appTheme.palette.eTypes.sand25,
      }}
      listSx={{
        padding: "0px 16px",
        gap: "8px",
        display: "flex",
        flexDirection: "column",
        marginTop: "24px",
      }}
    />
  ) : null
}

function SidebarItem(props: {
  svgIconProps: SvgIconProps
  text: string
  isSelected: boolean
  onClick: () => void
}) {
  const { svgIconProps, text, isSelected, onClick } = props
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        padding: "8px 16px",
        borderRadius: "8px",
        cursor: "pointer",
        bgcolor: isSelected ? appTheme.palette.eTypes.sand50 : "transparent",
      }}
      onClick={onClick}
    >
      <SvgIcon
        inheritViewBox
        {...svgIconProps}
        sx={{
          width: "20px",
          height: "20px",
          ...svgIconProps.sx,
        }}
      />
      <Typography variant={isSelected ? "body2_sb" : "body2"}>
        {text}
      </Typography>
    </Box>
  )
}

type TabIdentifier = "Syllabuses" | "Courses"

function useSelectedTab(): TabIdentifier | undefined {
  const location = useLocation()

  if (location.pathname === "/syllabuses") {
    return "Syllabuses"
  } else if (location.pathname === "/courses") {
    return "Courses"
  }
  return undefined
}

export default Sidebar
