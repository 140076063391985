import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Divider, Tab } from "@mui/material"
import SyllabusPanelHeader from "./syllabusPanelHeader"
import UnitGeneralInfoEditor from "../units/unitGeneralInfoEditor"
import useActiveSyllabusUnit from "./useActiveSyllabusUnit"
import { useSearchParams } from "react-router-dom"
import UnitContentEditor from "../units/content-editor/unitContentEditor"

function SyllabusEditorPanel() {
  const unit = useActiveSyllabusUnit()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("unitTab") || "details"

  if (!unit) {
    return null
  }
  const isMissingUnitNumber = !unit.unitNumber || isNaN(unit.unitNumber)
  const isUnitGeneralInfoInvalid =
    isMissingUnitNumber && unit.courseDescriptors.length > 0

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <SyllabusPanelHeader />
      <TabContext value={tab}>
        <TabList
          onChange={(e, tab) => {
            searchParams.set("unitTab", tab)
            setSearchParams(searchParams, { replace: true })
          }}
          sx={{
            paddingLeft: "24px",
            paddingTop: "16px",
          }}
        >
          <Tab
            label="Details"
            value="details"
            style={{ color: isUnitGeneralInfoInvalid ? "red" : undefined }}
          />
          <Tab label="Content" value="content" />
        </TabList>
        <Divider
          sx={{
            mt: "0px",
          }}
        />
        <TabPanel value="details" sx={{ height: "100%", overflow: "hidden" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "auto",
            }}
          >
            <UnitGeneralInfoEditor />
          </Box>
        </TabPanel>
        <TabPanel
          value="content"
          sx={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
            padding: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              overflow: "auto",
            }}
          >
            <UnitContentEditor unit={unit} />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default SyllabusEditorPanel
