import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../../../theme/appTheme"
import useComputeStaleCourseContainers from "./useComputeStaleCourseContainers"
import { LoadingButton } from "@mui/lab"
import { useAppSelector } from "@app/hooks"

function SingleStaleCoursePopup(props: {
  staleCourse: {
    syllabusId: string
    unitId: string
    courseId: string
  }
  onClose: () => void
  onView: () => void
  onUpdate: () => void
}) {
  const { staleCourse, onClose, onView, onUpdate } = props

  const model = useSingleStaleCoursePopupViewModel(staleCourse)

  return (
    <Dialog
      open
      sx={{
        ".MuiDialog-paper": {
          borderRadius: "8px",
          padding: "24px",
          gap: "32px",
          maxWidth: "440px",
        },
        boxShadow: "1px 1px 1px 1px rgba(0,0,0,1)",
      }}
    >
      <DialogTitle
        sx={{
          padding: "0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              padding: "0px",
            }}
          >
            <SvgIcon
              inheritViewBox
              component={appIcons.xClose}
              sx={{
                stroke: appTheme.palette.icon.black,
              }}
            />
          </IconButton>
        </Box>
        {model?.title}
        <Typography mt="8px">
          Course changes may impact the unit structure.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textDecoration: "underline",
            ":hover": {
              cursor: "pointer",
            },
          }}
          onClick={onView}
        >
          View changes
        </Typography>
      </DialogTitle>
      <DialogActions sx={{ padding: "0px", gap: "16px" }}>
        <Button size="small" variant="outlined" onClick={onClose} fullWidth>
          Keep current version
        </Button>
        <LoadingButton
          loading={model?.isLoading}
          size="small"
          variant="contained"
          onClick={onUpdate}
          fullWidth
        >
          Update course
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

function useSingleStaleCoursePopupViewModel(staleCourse: {
  syllabusId: string
  unitId: string
  courseId: string
}) {
  if (!staleCourse) {
    debugger
  }
  const staleCourseModel = useComputeStaleCourseContainers(staleCourse)
  const isLoading = useAppSelector(
    (state) =>
      state.syllabusEditor.reviewStaleCoursesPopup?.statuses[
        staleCourse.courseId
      ] === "pending",
  )

  if (!staleCourseModel) {
    return undefined
  }

  const { course, unit } = staleCourseModel

  return {
    title: `Update to latest version of “${course.title}” in unit ${unit.shortTitle}${unit.unitNumber}?`,
    isLoading,
  }
}

export default SingleStaleCoursePopup
