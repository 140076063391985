import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"

enum Trigger {
  CLICK,
  HOVER,
}

const DEFAULT_LEAVE_DELAY = 1500

export type CustomTooltipProps = {
  children: JSX.Element
  title: NonNullable<React.ReactNode>
  trigger?: Trigger
  leaveDelay?: number
  fontColor?: string
  disableTooltip?: boolean
  offset?: { horizontal: number; vertical: number }
} & TooltipProps

const CustomTooltip = ({
  children,
  title,
  trigger = Trigger.HOVER,
  leaveDelay = DEFAULT_LEAVE_DELAY,
  fontColor = "white",
  disableTooltip = false,
  offset = { horizontal: 0, vertical: 0 },
  ...restTooltipProps
}: CustomTooltipProps) => {
  if (disableTooltip) {
    return children
  }

  let Component

  switch (trigger) {
    case Trigger.CLICK:
      Component = ClickTooltip
      break
    case Trigger.HOVER:
      Component = LightTooltip
      break
    default:
      throw Error("Unknown trigger")
  }

  return (
    <Component
      {...restTooltipProps}
      arrow={restTooltipProps.arrow ?? true}
      leaveDelay={leaveDelay}
      title={
        <Typography component={"span"} color={fontColor} variant="body2">
          {title}
        </Typography>
      }
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [offset.horizontal, offset.vertical],
              },
            },
          ],
        },
      }}
    >
      {children}
    </Component>
  )
}

const ClickTooltip = ({
  children,
  title,
  leaveDelay,
  ...restTooltipProps
}: Omit<CustomTooltipProps, "trigger">) => {
  const [isOpen, setIsOpen] = useState(false)
  const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>(null)

  const handleTooltipClose = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout)
    }

    setIsOpen(false)
  }

  const openTooltip = () => {
    setIsOpen(true)
    setCloseTimeout(setTimeout(handleTooltipClose, leaveDelay))
  }
  useEffect(() => {
    return () => {
      handleTooltipClose()
    }
  })

  return (
    <LightTooltip
      {...restTooltipProps}
      onClose={handleTooltipClose}
      open={isOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={title}
    >
      <Box
        component="span"
        display="inline-flex"
        sx={{
          width: "fit-content",
        }}
        onClick={openTooltip}
      >
        {children}
      </Box>
    </LightTooltip>
  )
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.other.snackbarBackground,
    boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.4)",
    padding: "8px 16px",
  },
}))

CustomTooltip.Triggers = Trigger

export default CustomTooltip
