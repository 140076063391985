import { useAppDispatch } from "@app/hooks"
import SimpleDialog from "@cmp/simpleDialog"
import {
  discardChangesApproved,
  discardChangesConfirmationClosed,
} from "../../features/courseEditor/courseEditorSlice"

function DiscardChangesConfirmationPopup() {
  const dispatch = useAppDispatch()

  const title = "Discard current changes?"
  const content =
    "This will delete any changes you’ve made during this session."

  return (
    <SimpleDialog
      title={title}
      content={content}
      onClose={() => dispatch(discardChangesConfirmationClosed())}
      buttons={[
        {
          children: "Cancel",
          variant: "outlined",
          size: "small",
          onClick: () => dispatch(discardChangesConfirmationClosed()),
        },
        {
          children: "Discard changes",
          variant: "contained",
          size: "small",
          onClick: () => {
            dispatch(discardChangesApproved())
          },
        },
      ]}
    />
  )
}

export default DiscardChangesConfirmationPopup
