import { useAppSelector } from "@app/hooks"
import { CourseDto } from "@masterschool/course-builder-api"
import { selectHasChanges } from "../features/coursesMenu/coursesSelectors"
import ChipStatus from "./chipStatus"
import { courseStatusTextAndColor } from "./courseDtoStatusEnum+Ui"

function CourseViewStatus(props: { course: CourseDto }) {
  const { course } = props
  const hasChanges = useAppSelector(selectHasChanges(course.id))

  const statusTextAndColor = courseStatusTextAndColor(course.status)

  return (
    <ChipStatus
      text={statusTextAndColor.text}
      color={statusTextAndColor.color}
      showIndicator={hasChanges}
    />
  )
}

export default CourseViewStatus
