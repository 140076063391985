import { RootState } from "@app/store"
import {
  CourseDtoStatusEnum,
  SyllabusDto,
  SyllabusDtoStatusEnum,
} from "@masterschool/course-builder-api"
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { startDateToCohortStyleDate } from "@utils/syllabusTags"
import { filterCourses } from "../../main/main-page-helpers"
import {
  selectCoursesMainPageTab,
  selectCoursesSortOption,
} from "../coursesMenu/coursesSelectors"
import {
  SortType,
  alphabeticCoursesSort,
  chronologicalCoursesSort,
} from "../../main/sort/coursesSortHelpers"
import {
  Syllabus,
  selectSyllabuses,
  selectSyllabusesMainPageTab,
  selectSyllabusesSortOption,
} from "../syllabus/syllabusSelectors"
import {
  filterSyllabuses,
  filterSyllabusesByTab,
} from "@utils/syllabus/syllabus-menu-helpers"
import {
  alphabeticSyllabusesSort,
  chronologicalSyllabusesSort,
} from "../../main/sort/syllabusSortHelpers"

export interface SearchState {
  courseFilters: SearchComponentFilter
  syllabusFilters: SearchComponentFilter
}

export type SearchComponentFilter = {
  advanceFilters: SearchAdvanceFilter[]
  text: string
}

export type SearchAdvanceFilter = {
  filterName: FilterName
  optionalValues: string[]
  selectedValues: string[]
}

export enum FilterName {
  Domains = "Domains",
  Syllabuses = "Syllabuses",
  Cohort = "Cohort",
}

export enum Domain {
  Software = "Software",
  Data = "Data",
  Cyber = "Cyber",
}
export const DomainsEnumeration = Object.values(Domain)

const initialState: SearchState = {
  courseFilters: { advanceFilters: [], text: "" },
  syllabusFilters: { advanceFilters: [], text: "" },
}

export const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    courseAdvanceFilterUpdated: (
      state,
      action: PayloadAction<SearchAdvanceFilter[]>,
    ) => {
      state.courseFilters.advanceFilters = action.payload
    },
    courseSearchTextUpdated: (state, action: PayloadAction<string>) => {
      state.courseFilters.text = action.payload
    },
    syllabusFilterUpdated: (
      state,
      action: PayloadAction<SearchAdvanceFilter[]>,
    ) => {
      state.syllabusFilters.advanceFilters = action.payload
    },
    syllabusSearchTextUpdated: (state, action: PayloadAction<string>) => {
      state.syllabusFilters.text = action.payload
    },
  },
})

export const selectCourseFilters = (state: RootState) =>
  state.search.courseFilters

const selectPrograms = (state: RootState) => state.program.programs

export const selectCoursesSearch = createSelector(
  [selectCourseFilters, selectSyllabuses, selectPrograms],
  (courseFilters, syllabuses, programs) => {
    return {
      advanceFilters: [
        {
          filterName: FilterName.Domains,
          optionalValues: DomainsEnumeration,
          selectedValues:
            courseFilters.advanceFilters.find(
              (cf) => cf.filterName === "Domains",
            )?.selectedValues || [],
        },
        {
          filterName: FilterName.Syllabuses,
          optionalValues: [...new Set(syllabuses.map((s) => s.name))],
          selectedValues:
            courseFilters.advanceFilters.find(
              (cf) => cf.filterName === "Syllabuses",
            )?.selectedValues || [],
        },
      ],
      text: courseFilters.text,
    }
  },
)

const selectSyllabusFilters = (state: RootState) => state.search.syllabusFilters

export const selectSyllabusSearch = createSelector(
  [selectSyllabusFilters, selectPrograms],
  (syllabusFilters, programs) => {
    return {
      advanceFilters: [
        {
          filterName: FilterName.Domains,
          optionalValues: [...new Set(programs.map((p) => p.domain))],
          selectedValues:
            syllabusFilters.advanceFilters.find(
              (cf) => cf.filterName === "Domains",
            )?.selectedValues || [],
        },
        {
          filterName: FilterName.Cohort,
          optionalValues: [
            ...new Set(
              programs.map((p) =>
                startDateToCohortStyleDate(p.startDate.toString()),
              ),
            ),
          ],
          selectedValues:
            syllabusFilters.advanceFilters.find(
              (cf) => cf.filterName === "Cohort",
            )?.selectedValues || [],
        },
      ],
      text: syllabusFilters.text,
    }
  },
)

const selectCourses = (state: RootState) => state.coursesMenu.courses

const selectFavoriteCourses = (state: RootState) =>
  state.coursesMenu.favoriteCourses

export const selectMainPageCourses = createSelector(
  [
    selectCourses,
    selectSyllabuses,
    selectCourseFilters,
    selectCoursesMainPageTab,
    selectCoursesSortOption,
    selectFavoriteCourses,
  ],
  (courses, syllabuses, courseFilters, tab, sortOption, favoriteCourses) => {
    if (courses === "pending" || courses === "rejected") {
      return
    }
    const syllabusIdsToFilter = getSyllabusIdsByFromCohortTag(
      syllabuses,
      courseFilters.advanceFilters,
    )

    const allCoursesBesideDraftsWithHighVersion = courses?.filter(
      (course) =>
        (course.status === CourseDtoStatusEnum.Draft && course.version === 1) ||
        course.status !== CourseDtoStatusEnum.Draft,
    )

    const advanceFilter = getAdvanceFilterWithSyllabusIdsAsSelectedValues(
      courseFilters.advanceFilters,
      syllabusIdsToFilter!,
    )

    const filtered = filterCourses(
      allCoursesBesideDraftsWithHighVersion,
      courseFilters.text,
      advanceFilter,
      tab,
      favoriteCourses,
    )

    switch (sortOption.type) {
      case SortType.Alphabetic:
        alphabeticCoursesSort(filtered, sortOption.order)
        break
      case SortType.Chronological:
        chronologicalCoursesSort(filtered, sortOption.order)
        break
    }

    return filtered
  },
)

function getSyllabusIdsByFromCohortTag(
  syllabuses: SyllabusDto[],
  filters: SearchAdvanceFilter[],
): string[] {
  const courseSyllabusFilters = filters.find(
    (af) => af.filterName === FilterName.Syllabuses,
  )?.selectedValues

  return courseSyllabusFilters?.map(
    (filteredSyllabusName) =>
      syllabuses.find((s) => s.name === filteredSyllabusName)?.id ?? "",
  )!
}

function getAdvanceFilterWithSyllabusIdsAsSelectedValues(
  filters: SearchAdvanceFilter[],
  newSelectedValue: string[],
) {
  return filters.map((filter) => {
    if (filter.filterName === FilterName.Syllabuses) {
      return { ...filter, selectedValues: newSelectedValue }
    } else return filter
  })
}

export const selectMainPageSyllabuses = createSelector(
  [
    selectSyllabuses,
    selectSyllabusesSortOption,
    selectSyllabusSearch,
    selectSyllabusesMainPageTab,
  ],
  (syllabuses, sortOption, filters, tab) => {
    if (syllabuses.length === 0) {
      return syllabuses
    }

    const latestVersions = getLatestSyllabusVersion(syllabuses)

    let filteredSyllabuses = filterSyllabuses(
      latestVersions,
      filters.text,
      filters.advanceFilters,
    )
    const archivedSyllabuses = filteredSyllabuses.filter(
      (syllabus) => syllabus.status === SyllabusDtoStatusEnum.Archived,
    )
    filteredSyllabuses = [
      ...filteredSyllabuses.filter(
        (syllabus) => syllabus.status !== SyllabusDtoStatusEnum.Archived,
      ),
      ...archivedSyllabuses,
    ]
    filteredSyllabuses = filterSyllabusesByTab(filteredSyllabuses, tab)
    switch (sortOption.type) {
      case SortType.Alphabetic:
        alphabeticSyllabusesSort(filteredSyllabuses, sortOption.order)
        break
      case SortType.Chronological:
        chronologicalSyllabusesSort(filteredSyllabuses, sortOption.order)
        break
    }
    return filteredSyllabuses
  },
)

function getLatestSyllabusVersion(syllabuses: Syllabus[]) {
  const allSyllabusesBesideDraftsWithHighVersion = syllabuses.filter(
    (syllabus) =>
      (syllabus.status === SyllabusDtoStatusEnum.Draft &&
        syllabus.version === 1) ||
      syllabus.status !== SyllabusDtoStatusEnum.Draft,
  )

  return Object.values(
    allSyllabusesBesideDraftsWithHighVersion.reduce((acc, syllabus) => {
      const existingSyllabus = acc[syllabus.id]
      if (
        !existingSyllabus ||
        (syllabus.version ?? 1) > acc[syllabus.id].version
      ) {
        acc[syllabus.id] = syllabus
      }
      return acc
    }, {} as Record<string, Syllabus>),
  )
}

export const {
  courseAdvanceFilterUpdated,
  syllabusFilterUpdated,
  courseSearchTextUpdated,
  syllabusSearchTextUpdated,
} = searchSlice.actions

export default searchSlice.reducer
