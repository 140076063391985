import { Box, BoxProps, Divider, Typography } from "@mui/material"
import { useCallback, useRef, useState } from "react"
import { SprintDto } from "@masterschool/course-builder-api"
import {
  PADDING_BETWEEN_SPRINTS,
  SPRINT_ELEMENT_ID_PREFIX,
  findClosestItem,
  getSprintBottomOffset,
} from "./unit.sprint.utils"
import { DraggedAnchorPosition as AnchorPosition } from "./unitSprintsTimeline"
import CustomTooltip from "@cmp/customTooltip"
import appTheme from "../../../../theme/appTheme"
import { SprintActionsMenu } from "./sprintActionsMenu"
import PracticeWeekThumbnail from "../../../../assets/images/practice.week.png"
import VacationWeekThumbnail from "../../../../assets/images/vacation.week.png"

// This is the minimal height of a sprint element, so that it is still rendered even if minimized; this is
// so that if we minimize a sprint, we can still drag it to resize it. If we don't do this, the sprint will
// be un-draggable once it is minimized.
const MINIMAL_ELEMENT_HEIGHT = 2

type SprintTimelineElementProps = {
  sprint: SprintDto
  index: number
  isLast: boolean
  bgColor: string
  height: number
  isAnotherSprintResizing: boolean
  onDragStart: () => void
  onAnchorDragged: (
    movementY: number,
    sprintIndex: number,
    anchorPosition: AnchorPosition,
  ) => void
  onDragEnded: (newLastItemId: string, sprintIndex: number) => void
}

const SprintTimelineElement = (props: SprintTimelineElementProps) => {
  const [showMenu, setShowMenu] = useState(false)

  const SpecialSprintBadge = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: "50%",
          width: "32px",
          height: "32px",
          bgcolor: props.bgColor,
          padding: "6.4px",
          fontSize: "14px",
          opacity:
            props.sprint.isVacationWeek || props.sprint.isPracticeWeek ? 1 : 0,
        }}
      >
        <img
          src={
            props.sprint.isVacationWeek
              ? VacationWeekThumbnail
              : PracticeWeekThumbnail
          }
          alt="week badge"
          width="16px"
          height="16px"
        />
      </Box>
    )
  }

  const hideBadge = props.height < 30

  return (
    <Box
      display="flex"
      gap="8px"
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
      maxHeight={
        props.height + PADDING_BETWEEN_SPRINTS + MINIMAL_ELEMENT_HEIGHT
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="8px"
        sx={{
          opacity: hideBadge ? 0 : 1,
          transition: "opacity ease-in-out 0.2s",
        }}
        alignItems="center"
      >
        <SpecialSprintBadge />
        <Box sx={{ opacity: showMenu ? 1 : 0 }}>
          <SprintActionsMenu sprint={props.sprint} />
        </Box>
      </Box>
      <ResizableSprintBlock {...props} />
    </Box>
  )
}

const ResizableSprintBlock = (props: SprintTimelineElementProps) => {
  const { sprint, index, isLast } = props
  const { bgColor, height, isAnotherSprintResizing } = props
  const { onDragStart, onAnchorDragged, onDragEnded } = props
  const [isDraggingAnchor, setIsDraggingAnchor] = useState<
    AnchorPosition | undefined
  >(undefined)

  const startResizing = useCallback(
    (e: DragEvent, anchor: AnchorPosition) => {
      e.preventDefault()
      setIsDraggingAnchor(anchor)
      onDragStart()
    },
    [onDragStart],
  )

  const resize = useCallback(
    (e: DragEvent, position: AnchorPosition) => {
      if (isDraggingAnchor) onAnchorDragged(e.movementY, index, position)
    },
    [isDraggingAnchor, onAnchorDragged, index],
  )

  const leaveTimeoutRef = useRef<NodeJS.Timeout | undefined>()
  const stopResizing = useCallback(() => {
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current)
      leaveTimeoutRef.current = undefined
    }
    if (isDraggingAnchor) {
      setIsDraggingAnchor(undefined)
      const indexOfSprintToChange =
        isDraggingAnchor === "top" ? index - 1 : index
      const topOffsetOfSprintBottomAnchor = getSprintBottomOffset(
        indexOfSprintToChange,
      )
      const closestItemId = findClosestItem(topOffsetOfSprintBottomAnchor)
      onDragEnded(closestItemId, indexOfSprintToChange)
    }
  }, [isDraggingAnchor, index, onDragEnded])

  // Added so we don't stop resizing in case the user moves the mouse faster than the rendered resizing
  const debouncedMouseLeave = useCallback(() => {
    leaveTimeoutRef.current = setTimeout(() => stopResizing(), 150)
  }, [stopResizing])

  const isDraggingTopAnchor = isDraggingAnchor === "top"
  const isDraggingBottomAnchor = isDraggingAnchor === "bottom"
  const isResizing = isDraggingTopAnchor || isDraggingBottomAnchor

  const isFirstSprint = index === 0
  const enableTopDrag =
    !isFirstSprint && !isDraggingBottomAnchor && !isAnotherSprintResizing
  const enableBottomDrag =
    !isLast && !isDraggingTopAnchor && !isAnotherSprintResizing

  const shouldAnimateHeightChange = !isAnotherSprintResizing && !isResizing
  const zIndex = isResizing ? 10 : 1
  const touchAreaHeight = `${Math.min(height * 0.4, 60)}px`

  /// Black magic. Don't touch.
  const halfPadding = PADDING_BETWEEN_SPRINTS / 2
  const marginTop =
    height >= 0 ? halfPadding : Math.max(halfPadding + height, -halfPadding)
  const marginBottom = Math.max(marginTop, 0)

  return (
    <Box
      id={`${SPRINT_ELEMENT_ID_PREFIX}${index}`}
      sx={{
        height: Math.max(height, MINIMAL_ELEMENT_HEIGHT),
        transition: shouldAnimateHeightChange
          ? "height ease-in-out 0.2s"
          : undefined,
        zIndex,
        marginTop: isFirstSprint ? 0 : `${marginTop}px`,
        marginBottom: isLast ? 0 : `${marginBottom}px`,
      }}
      position="relative"
    >
      {enableTopDrag && (
        <AnchorTouchArea
          position="top"
          maxHeight={touchAreaHeight}
          onMouseMove={(e) => resize(e, "top")}
          onMouseLeave={debouncedMouseLeave}
          onMouseUp={stopResizing}
          onMouseDown={(e) => startResizing(e, "top")}
          isBeingDragged={isDraggingTopAnchor}
          zIndex={zIndex}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        sx={{
          overflowY: "hidden",
          backgroundColor: bgColor,
          opacity: height < 30 ? 0 : 1,
          transition: "all ease-in-out 0.2s",
          borderRadius: "2px",
        }}
      >
        <Anchor bold={isDraggingTopAnchor} opacity={enableTopDrag ? 1 : 0} />
        <TitleWithTooltip title={sprint.title} />
        <Anchor
          bold={isDraggingBottomAnchor}
          opacity={enableBottomDrag ? 1 : 0}
        />
      </Box>
      {enableBottomDrag && (
        <AnchorTouchArea
          position="bottom"
          maxHeight={touchAreaHeight}
          onMouseMove={(e) => resize(e, "bottom")}
          onMouseLeave={debouncedMouseLeave}
          onMouseUp={stopResizing}
          onMouseDown={(e) => startResizing(e, "bottom")}
          isBeingDragged={isDraggingBottomAnchor}
          zIndex={zIndex}
        />
      )}
    </Box>
  )
}

const AnchorTouchArea = (props: {
  position: AnchorPosition
  maxHeight: BoxProps["height"]
  onMouseMove: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseLeave: () => void
  onMouseUp: () => void
  onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isBeingDragged: boolean
  zIndex: number
}) => {
  const { maxHeight, position, isBeingDragged, zIndex } = props
  const { onMouseMove, onMouseLeave, onMouseUp, onMouseDown } = props

  const offset = isBeingDragged ? "-300px" : "-4px"
  const sx = position === "top" ? { top: offset } : { bottom: offset }
  const SHOW_TOUCH_AREA_INDICATORS = false // change to true for debugging
  const BG_COLOR = position === "top" ? "red" : "blue"

  return (
    <Box
      sx={{
        position: "absolute",
        height: isBeingDragged ? "600px" : maxHeight,
        zIndex,
        ...sx,
        right: isBeingDragged ? "-170px" : "-16px",
        cursor: isBeingDragged ? "grabbing" : "ns-resize",
        width: isBeingDragged ? "400px" : "64px",
        backgroundColor: SHOW_TOUCH_AREA_INDICATORS ? BG_COLOR : undefined,
        opacity: SHOW_TOUCH_AREA_INDICATORS ? 0.3 : undefined,
      }}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
    />
  )
}

const Anchor = (props: { bold?: boolean; opacity: number }) => {
  const { bold, opacity } = props
  return (
    <Box padding="8px" sx={{ opacity }}>
      <Divider
        sx={{
          border: bold ? "1px solid #717171" : "1px solid #00000042",
          borderRadius: "4px",
        }}
      />
      <Divider
        sx={{
          border: bold ? "1px solid #717171" : "1px solid #00000042",
          borderRadius: "4px",
          marginTop: "2px",
        }}
      />
    </Box>
  )
}

export default SprintTimelineElement

type DragEvent = React.MouseEvent<HTMLDivElement, MouseEvent>

function TitleWithTooltip(props: { title: string }) {
  const ref = useRef<HTMLDivElement>(null)
  return (
    <CustomTooltip
      title={props.title}
      placement="right"
      leaveDelay={0}
      enterDelay={0}
      offset={{ vertical: 16, horizontal: 0 }}
      fontColor="white"
      PopperProps={{
        anchorEl: ref.current,
      }}
      slotProps={{
        tooltip: {
          style: { backgroundColor: appTheme.palette.eTypes.burgundy },
        },
      }}
    >
      <Typography
        sx={{
          writingMode: "vertical-rl",
          cursor: "default",
          textOrientation: "mixed",
          transform: "rotate(180deg)",
          padding: "8px",
          numberOfLines: 1,
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          paddingX: "11px",
        }}
        ref={ref}
        variant="body3_sb"
      >
        {props.title}
      </Typography>
    </CustomTooltip>
  )
}
