import { useAppDispatch } from "@app/hooks"
import CloseIcon from "@mui/icons-material/Close"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material"
import { archiveCourse } from "../../features/coursesMenu/coursesMenuSlice"
import { popupClosed } from "../../features/ui/uiSlice"

function ArchiveConfirmationPopup(props: {
  courseId: string
  onClose: () => void
}) {
  const dispatch = useAppDispatch()
  const onArchive = () => {
    dispatch(popupClosed())
    dispatch(archiveCourse(props.courseId))
  }
  const { onClose } = props

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle sx={{ padding: "48px 0px 0px" }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "388px",
        }}
      >
        <Typography variant="body1_sb">Archive this course?</Typography>
        <Typography variant="body1">
          The course will no longer be visible to all users in the main
          repository. You can restore it at any time.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "24px", gap: "8px" }}>
        <Button variant="outlined" size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={onArchive}>
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ArchiveConfirmationPopup
