import AppObserver from "@app/middlewares/appObserver"
import { store } from "@app/store"
import {
  SnackbarDuration,
  SnackbarType,
  showSnackbarItem,
} from "../../features/ui/uiSlice"

const snackbarObserver: AppObserver = {
  didUpdate: (previousState, currentState, action) => {
    switch (action.type) {
      case "coursesMenu/archiveCourse/fulfilled":
        store.dispatch(
          showSnackbarItem({
            type: SnackbarType.archiveCourseSuccess,
            duration: SnackbarDuration.short,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          }),
        )
        break
      case "coursesMenu/archiveCourse/rejected":
        store.dispatch(
          showSnackbarItem({
            type: SnackbarType.archiveCourseError,
            duration: SnackbarDuration.short,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          }),
        )
        break
      case "coursesMenu/duplicateCourse/fulfilled":
        store.dispatch(
          showSnackbarItem({
            type: SnackbarType.duplicateCourseSuccess,
            duration: SnackbarDuration.short,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          }),
        )
        break
      case "coursesMenu/duplicateCourse/rejected":
        store.dispatch(
          showSnackbarItem({
            type: SnackbarType.duplicateCourseError,
            duration: SnackbarDuration.short,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          }),
        )
        break
      case "syllabusesMenu/duplicateSyllabus/fulfilled":
        store.dispatch(
          showSnackbarItem({
            type: SnackbarType.duplicateSyllabusSuccess,
            duration: SnackbarDuration.short,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          }),
        )
        break
      case "syllabusesMenu/duplicateSyllabus/rejected":
        store.dispatch(
          showSnackbarItem({
            type: SnackbarType.duplicateSyllabusError,
            duration: SnackbarDuration.short,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          }),
        )
        break
      case "syllabusEditor/archiveSyllabus/fulfilled":
        store.dispatch(
          showSnackbarItem({
            type: SnackbarType.archiveSyllabusSuccess,
            duration: SnackbarDuration.short,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          }),
        )
        break
      case "syllabusEditor/archiveSyllabus/rejected":
        store.dispatch(
          showSnackbarItem({
            type: SnackbarType.archiveSyllabusError,
            duration: SnackbarDuration.short,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          }),
        )
        break
    }
  },
}

export default snackbarObserver
