import { useAppSelector } from "@app/hooks"
import { useParams } from "react-router-dom"
import {
  Syllabus,
  selectLastPublishedSyllabus,
  selectDraftSyllabus,
} from "../../features/syllabus/syllabusSelectors"

function useSelectedSyllabus(): SyllabusVersions {
  const { syllabusId } = useParams()

  const lastPublishedVersion = useAppSelector(
    selectLastPublishedSyllabus(syllabusId),
  )

  const draftVersion = useAppSelector(selectDraftSyllabus(syllabusId))
  return {
    lastPublishedVersion: lastPublishedVersion,
    draftVersion: draftVersion,
  }
}

export type SyllabusVersions = {
  lastPublishedVersion?: Syllabus
  draftVersion?: Syllabus
}

export default useSelectedSyllabus
