import { useAppDispatch, useAppSelector } from "@app/hooks"
import { CourseDtoStatusEnum } from "@masterschool/course-builder-api"
import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SvgIcon,
} from "@mui/material"
import { createSelector, unwrapResult } from "@reduxjs/toolkit"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import { createSearchParams, useNavigate } from "react-router-dom"
import {
  createNewCourseVersion,
  duplicateCourse,
} from "../../features/coursesMenu/coursesMenuSlice"
import { selectLatestCourseVersion } from "../../features/coursesMenu/coursesSelectors"
import { selectSyllabuses } from "../../features/syllabus/syllabusSelectors"
import { editCourseConfirmationPopupClosed } from "../../features/syllabusEditor/syllabusEditorSlice"
import appTheme from "../../theme/appTheme"

function EditCourseFromSyllabusConfirmationPopup() {
  const dispatch = useAppDispatch()
  const popupState = useAppSelector(
    (state) => state.syllabusEditor.editCourseConfirmationPopup,
  )
  const dependentSyllabuses = useAppSelector(
    selectDependentSyllabuses(popupState?.courseId),
  )
  const navigate = useNavigate()
  const course = useAppSelector(selectLatestCourseVersion(popupState?.courseId))
  const [isDuplicating, setIsDuplicating] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  if (!popupState || !course) {
    return null
  }

  return (
    <Dialog open={true}>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "24px",
          maxWidth: "388px",
        }}
      >
        <IconButton
          onClick={() => dispatch(editCourseConfirmationPopupClosed())}
          sx={{
            alignSelf: "flex-end",
          }}
        >
          <SvgIcon
            component={appIcons.xClose}
            inheritViewBox
            sx={{
              stroke: appTheme.palette.icon.black,
            }}
          />
        </IconButton>
        <DialogTitle
          sx={{
            padding: 0,
          }}
        >
          Edit this course?
        </DialogTitle>
        <DialogContentText mt="8px">
          The course is published and appears in {dependentSyllabuses?.length}{" "}
          syllabuses.
        </DialogContentText>
        <DialogActions
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            padding: 0,
            mt: "40px",
            gap: "16px",
          }}
        >
          <LoadingButton
            loading={isDuplicating}
            variant="outlined"
            size="small"
            sx={{
              width: "50%",
            }}
            onClick={() => {
              setIsDuplicating(true)
              dispatch(duplicateCourse(course))
                .then(unwrapResult)
                .then(({ course: duplicatedCourse }) => {
                  dispatch(editCourseConfirmationPopupClosed())
                  navigate({
                    pathname: `${popupState.unitId}/${duplicatedCourse.id}`,
                    search: createSearchParams({
                      duplicateOriginalCourseId: course.id,
                    }).toString(),
                  })
                  setIsDuplicating(false)
                })
            }}
          >
            Duplicate course
          </LoadingButton>
          <LoadingButton
            loading={isEditing}
            size="small"
            variant="contained"
            sx={{
              width: "50%",
            }}
            onClick={() => {
              setIsEditing(true)
              if (!course || course.status === CourseDtoStatusEnum.Archived) {
                setIsEditing(false)
                return
              }

              if (course.status === CourseDtoStatusEnum.Published) {
                dispatch(createNewCourseVersion(course))
                  .then(unwrapResult)
                  .then((course) => {
                    setIsEditing(false)
                    if (!course) {
                      return
                    }
                    dispatch(editCourseConfirmationPopupClosed())
                    navigate(`${popupState.unitId}/${course.id}`)
                  })
              } else {
                setIsEditing(false)
                dispatch(editCourseConfirmationPopupClosed())
                navigate(`${popupState.unitId}/${course.id}`)
              }
            }}
          >
            Edit course
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

const selectDependentSyllabuses = (courseId?: string) =>
  createSelector(selectSyllabuses, (syllabuses) => {
    if (!courseId) {
      return []
    }
    return syllabuses.filter((syllabus) =>
      syllabus.units.some((unit) =>
        unit.courseDescriptors.some(
          (courseDescriptor) => courseDescriptor.courseId === courseId,
        ),
      ),
    )
  })

export default EditCourseFromSyllabusConfirmationPopup
