import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { ProgramDto } from "@masterschool/course-builder-api"
import { ProgramClient } from "@clients/programClient"
import { RootState } from "@app/store"

export interface ProgramsState {
  programs: ProgramDto[]
  loadingState: "idle" | "loading" | "failed"
}

const initialState: ProgramsState = {
  programs: [],
  loadingState: "idle",
}

export const programSlice = createSlice({
  name: "program",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrograms.pending, (state) => {
      state.loadingState = "loading"
    })
    builder.addCase(fetchPrograms.fulfilled, (state, action) => {
      state.programs = action.payload
      state.loadingState = "idle"
    })
    builder.addCase(fetchPrograms.rejected, (state) => {
      state.loadingState = "failed"
    })
  },
})

export const fetchPrograms = createAsyncThunk(
  "program/fetchPrograms",
  async () => {
    return ProgramClient.listPrograms()
  },
)

export const selectPrograms = (state: RootState): ProgramDto[] => {
  return state.program.programs
}

export default programSlice.reducer
