import { store } from "@app/store"
import DragIcon from "@cmp/dragIcon"
import {
  CourseDto,
  UnitCourseDescriptor,
} from "@masterschool/course-builder-api"
import { Box, IconButton, SvgIcon, Typography } from "@mui/material"
import { BoxProps } from "@mui/system"
import appIcons from "@utils/appIcons"
import { useEffect, useState } from "react"
import { getCourse } from "../../../../features/syllabusEditor/syllabusEditorSlice"
import appTheme from "../../../../theme/appTheme"

function CourseRemovalButton(props: {
  courseDescriptor: UnitCourseDescriptor
  onRemoveClick: () => void
  sx?: BoxProps["sx"]
}) {
  const { courseDescriptor, onRemoveClick } = props
  const [course, setCourse] = useState<CourseDto | undefined>(undefined)

  useEffect(() => {
    getCourse(store.getState(), courseDescriptor).then((course) => {
      setCourse(course)
    })
  }, [courseDescriptor.courseId, courseDescriptor])

  if (!course) return null

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        gap: "8px",
        ...props.sx,
      }}
    >
      <DragIcon onMouseEnter={() => {}} onMouseLeave={() => {}} />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: "16px",
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
          borderRadius: "8px",
          ":hover": {
            backgroundColor: appTheme.palette.action.hover,
          },
          position: "relative",
          backgroundColor: "white",
        }}
      >
        <IconButton
          onClick={onRemoveClick}
          sx={{
            padding: "0px",
            position: "absolute",
            top: "-8px",
            right: "-8px",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            bgcolor: appTheme.palette.icon.default,
            "&:hover": {
              bgcolor: appTheme.palette.icon.default,
            },
          }}
        >
          <SvgIcon
            inheritViewBox
            component={appIcons.xClose}
            sx={{
              width: "16px",
              height: "16px",
              stroke: appTheme.palette.eTypes.white,
              "&:hover": {
                stroke: appTheme.palette.icon.black,
              },
            }}
          />
        </IconButton>
        <Box>
          <Typography
            variant="body2_sb"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: "100%",
              whiteSpace: "preLine",
            }}
          >
            {course?.title ?? ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CourseRemovalButton
