import { SortItemsButton } from "@cmp/buttons/sortItemsButton"
import PlusIcon from "@mui/icons-material/Add"
import { TabList } from "@mui/lab"
import TabContext from "@mui/lab/TabContext"
import { Box, Button, Tab, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import {
  TabIdentifierEnumeration,
  createCourse,
  sortAlphabeticallyClicked,
  sortChronologicallyClicked,
  tabSelected,
} from "../features/coursesMenu/coursesMenuSlice"
import {
  selectCoursesFetchingStatus,
  selectCoursesSortOption,
} from "../features/coursesMenu/coursesSelectors"
import { selectMainPageCourses } from "../features/search/searchSlice"
import { CourseSearchComponent } from "./courseSearchComponent"
import { CoursesDescriptorsContainer } from "./coursesDescriptorsContainer"
import FullScreenLoadingComponent from "@cmp/loaders/fullScreenLoadingComponent"
import FullScreenErrorComponent from "@cmp/loaders/fullScreenErrorComponent"

export function CoursesMainPage() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const loadingStatus = useAppSelector(selectCoursesFetchingStatus)

  const courses = useAppSelector(selectMainPageCourses)
  const sortOption = useAppSelector(selectCoursesSortOption)

  const tab = useAppSelector((state) => state.coursesMenu.tab)

  const highlightedCourseId = useAppSelector(
    (state) => state.coursesMenu.highlightedCourseId,
  )

  const onCreateCourseClicked = () => {
    return dispatch(createCourse()).then((result) => {
      if (!result.payload) return
      navigate(`/course-edit/${result.payload.id}`)
    })
  }

  return (
    <Box
      width="100%"
      height="100%"
      justifyContent="center"
      justifySelf="center"
      paddingX="24px"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          height: "100%",
          width: "100%",
          maxWidth: "1240px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {loadingStatus === "loading" && (
          <FullScreenLoadingComponent loadedComponents="courses" />
        )}
        {loadingStatus === "error" && (
          <FullScreenErrorComponent loadedComponents="courses" />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            pt: "80px",
            mb: "16px",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                marginBottom: "16px",
              }}
            >
              Courses
            </Typography>
            <CourseSearchComponent />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: "32px",
              }}
            >
              <TabContext value={tab}>
                <TabList
                  onChange={(event, value) => {
                    dispatch(tabSelected(value))
                  }}
                >
                  <Tab
                    label={TabIdentifierEnumeration.All}
                    value={TabIdentifierEnumeration.All}
                  />
                  <Tab
                    label={TabIdentifierEnumeration.Published}
                    value={TabIdentifierEnumeration.Published}
                  />
                  <Tab
                    label={"Drafts"}
                    value={TabIdentifierEnumeration.Draft}
                  />
                  <Tab
                    label={TabIdentifierEnumeration.Favorites}
                    value={TabIdentifierEnumeration.Favorites}
                  />
                  <Tab
                    label={TabIdentifierEnumeration.Archived}
                    value={TabIdentifierEnumeration.Archived}
                  />
                </TabList>
              </TabContext>
              <Box display="flex" gap="8px" alignItems="center">
                <SortItemsButton
                  sortOption={sortOption}
                  chronologicalClicked={(sortOrder) =>
                    dispatch(sortChronologicallyClicked(sortOrder))
                  }
                  alphabeticClicked={(sortOrder) =>
                    dispatch(sortAlphabeticallyClicked(sortOrder))
                  }
                />
                <Button
                  variant="contained"
                  startIcon={<PlusIcon />}
                  onClick={onCreateCourseClicked}
                  size="small"
                >
                  Add course
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            bottom: "0px",
            overflowY: "auto",
            width: "100%",
            paddingBottom: "24px",
          }}
        >
          {courses && (
            <CoursesDescriptorsContainer
              courses={courses}
              highlightCourseId={highlightedCourseId}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
