import { useAppDispatch } from "@app/hooks"
import { SelectedStatusChip } from "@cmp/syllabusStatusChip"
import { Box, Chip, Typography } from "@mui/material"
import { startDateToCohortStyleDate } from "@utils/syllabusTags"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  duplicate,
  rename,
} from "../../../features/syllabus/syllabusesMenuSlice"
import appTheme from "../../../theme/appTheme"
import { RenameSyllabusPopup } from "../../popups/renameSyllabusPopup"
import SyllabusDescriptorMenu from "./syllabusDescriptorMenu"
import { Syllabus } from "../../../features/syllabus/syllabusSelectors"
import { PopupType, popupItemClicked } from "../../../features/ui/uiSlice"
import { archiveSyllabus } from "../../../features/syllabusEditor/syllabusEditorSlice"
import { SyllabusDtoStatusEnum } from "@masterschool/course-builder-api"

function SyllabusDescriptorView(props: { syllabus: Syllabus }) {
  const { syllabus } = props

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [shouldShowRenamePopup, setShouldShowRenamePopup] =
    useState<boolean>(false)

  const onOpenClicked = () => {
    navigate(`/syllabus/${syllabus.id}`)
  }

  const onDescriptorClicked = () => {
    syllabus.isActive || syllabus.status !== SyllabusDtoStatusEnum.Draft
      ? onOpenClicked()
      : onEditClicked()
  }

  const onPreviewInCampusClicked = () => {
    dispatch(
      popupItemClicked({
        type: PopupType.CampusSyllabusPreview,
        syllabus: syllabus,
      }),
    )
  }

  const onRenameClicked = () => {
    setShouldShowRenamePopup(true)
  }

  const onDuplicateClicked = () => {
    dispatch(duplicate(syllabus.id))
  }

  const onEditClicked = () => {
    navigate(`/syllabus-edit/${syllabus.id}`)
  }

  const onArchiveClicked = () => {
    dispatch(archiveSyllabus({ syllabusId: syllabus.id }))
  }

  const onDeleteClicked = () => {
    dispatch(
      popupItemClicked({
        type: PopupType.SyllabusDeleteConfirmation,
        syllabusId: syllabus.id,
      }),
    )
  }

  const getMenuFunctionsBySyllabusStatus = (
    status: SyllabusDtoStatusEnum,
    isActive: boolean,
  ) => {
    if (isActive) {
      return {
        onOpenClicked,
        onPreviewInCampusClicked,
        onEditClicked,
        onDuplicateClicked,
      }
    }
    switch (status) {
      case SyllabusDtoStatusEnum.Draft:
        return {
          onOpenClicked,
          onRenameClicked,
          onPreviewInCampusClicked,
          onDuplicateClicked,
          onArchiveClicked,
        }
      case SyllabusDtoStatusEnum.Archived:
        return {
          onOpenClicked,
          onDeleteClicked,
        }
      case SyllabusDtoStatusEnum.Published:
        return {
          onOpenClicked,
          onPreviewInCampusClicked,
          onEditClicked,
          onDuplicateClicked,
        }
    }
  }

  const title = syllabus.name

  const cohort = syllabus.programStartDate
    ? startDateToCohortStyleDate(syllabus.programStartDate)
    : undefined

  return (
    <Box
      onClick={onDescriptorClicked}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        cursor: "pointer",
        padding: "24px",
        gap: "43px",
        border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        borderRadius: "8px",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            variant="body1"
            fontWeight={title.length > 0 ? 600 : 400}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxHeight: "24px",
              minHeight: "24px",
              maxWidth: "100%",
            }}
            color={title.length > 0 ? "text.primary" : "text.disabled"}
          >
            {title.length > 0 ? title : "Unnamed syllabus"}
          </Typography>
          <Box display="flex" marginTop="16px" gap="8px">
            <SelectedStatusChip
              syllabusId={syllabus.id}
              status={syllabus.status}
              isActive={syllabus.isActive}
            />
            {syllabus.domain && syllabus.domain.length && (
              <Chip
                label={syllabus.domain}
                size="small"
                sx={{
                  width: "fit-content",
                }}
              />
            )}
            {cohort && (
              <Chip
                label={cohort}
                size="small"
                sx={{
                  width: "fit-content",
                }}
              />
            )}
            {syllabus.programType && (
              <Chip
                label={syllabus.programType.replace("_", "-")}
                size="small"
                sx={{
                  width: "fit-content",
                  textTransform: "capitalize",
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <SyllabusDescriptorMenu
          {...getMenuFunctionsBySyllabusStatus(
            syllabus.status,
            syllabus.isActive,
          )}
        />
        {shouldShowRenamePopup && (
          <RenameSyllabusPopup
            name={syllabus.name}
            onSave={(syllabusName: string) => {
              dispatch(
                rename({
                  syllabusId: syllabus.id,
                  newName: syllabusName,
                }),
              )
            }}
            onClose={() => setShouldShowRenamePopup(false)}
          />
        )}
      </Box>
    </Box>
  )
}

export default SyllabusDescriptorView
