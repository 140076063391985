import { useAppSelector } from "@app/hooks"
import { useParams, useSearchParams } from "react-router-dom"
import { selectEditableSyllabus } from "../../features/syllabus/syllabusSelectors"

function useSelectedUnit() {
  const [searchParams] = useSearchParams()
  const unitId = searchParams.get("unitId")
  const editableSyllabus = useAppSelector(selectEditableSyllabus)

  const { syllabusId } = useParams()
  const originalSyllabus = useAppSelector((state) =>
    state.syllabusesMenu.syllabuses.find((s) => s.id === syllabusId),
  )

  const syllabus = editableSyllabus ?? originalSyllabus

  const selectedUnit =
    unitId !== undefined
      ? syllabus?.units.find((unit) => unit.id === unitId)
      : undefined

  return selectedUnit
}

export default useSelectedUnit
