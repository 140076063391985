import { Box, Button, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../../theme/appTheme"

function SyllabusCoursePreviewHeader(props: {
  onClose: () => void
  onEdit?: () => void
}) {
  const { onClose, onEdit } = props

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "16px 40px",
        alignItems: "center",
        width: "100%",
        gap: "24px",
        bgcolor: appTheme.palette.background.default,
        borderBottom: `1px solid ${appTheme.palette.other.outlineBorder}`,
      }}
    >
      <SvgIcon
        component={appIcons.xClose}
        inheritViewBox
        sx={{
          stroke: appTheme.palette.icon.black,
          cursor: "pointer",
        }}
        onClick={onClose}
      />
      <Typography
        variant="body1_sb"
        sx={{
          display: "flex",
          width: "100%",
          margin: "auto",
        }}
      >
        Unit information
      </Typography>
      {onEdit && (
        <Button
          onClick={onEdit}
          size="small"
          variant="outlined"
          sx={{
            width: "150px",
          }}
        >
          Edit course
        </Button>
      )}
    </Box>
  )
}

export default SyllabusCoursePreviewHeader
