import { useAppDispatch, useAppSelector } from "@app/hooks"
import {
  Topic,
  UnitCourseDescriptor,
  UnitDto,
} from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Skeleton,
  SvgIcon,
  Typography,
} from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import appIcons from "@utils/appIcons"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { createCourse } from "../../../../features/coursesMenu/coursesMenuSlice"
import { selectUnitCourse } from "../../../../features/syllabus/syllabusSelectors"
import { fetchUnitCourses } from "../../../../features/syllabus/syllabusesMenuSlice"
import {
  editCourseClicked,
  editUnitCoursesClicked,
  removeCourseFromUnit,
} from "../../../../features/syllabusEditor/syllabusEditorSlice"
import appTheme from "../../../../theme/appTheme"
import LinkCourseButton, { AddUnitItemType } from "./linkCourseButton"
import { topicDuration } from "@utils/syllabus+duration"
import OptionsButton from "@cmp/buttons/optionsButton"
import UnitSprintsTimeline from "./unitSprintsTimeline"
import { useState } from "react"
import CourseSyllabusElementView from "../../../topic/courseSyllabusElementView"

export const COURSE_VIEW_CLASS_NAME = "course-view"
export const TOPIC_DESCRIPTOR_CLASS_NAME = "topic-descriptor"

function UnitContentEditor(props: { unit: UnitDto }) {
  const { unit } = props
  const { courseDescriptors } = unit

  if (courseDescriptors.length === 0) {
    return <AddFirstCourseToUnit unit={unit} />
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Box overflow="scroll" height="100%">
        <Box
          display="flex"
          gap="16px"
          padding="32px"
          sx={{ width: "100%", minHeight: "100%", paddingRight: "72px" }}
        >
          <UnitSprintsTimeline sprints={unit.sprints} unitId={unit.id} />
          <Box display="flex" flexDirection="column" gap="40px" width="100%">
            {courseDescriptors.map((courseDescriptor, index) => {
              return (
                <CourseView
                  key={courseDescriptor.courseId}
                  unitId={unit.id}
                  unitCourseDescriptor={courseDescriptor}
                  index={index}
                  total={courseDescriptors.length}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
      <AddOrManageCoursesButton unitId={props.unit.id} />
    </Box>
  )
}

function CourseView(props: {
  unitId: string
  unitCourseDescriptor: UnitCourseDescriptor
  index: number
  total: number
}) {
  const { unitId, unitCourseDescriptor } = props
  const { index, total } = props
  const courseDto = useAppSelector(selectUnitCourse(unitCourseDescriptor))

  if (!courseDto) {
    return <CoursePlaceholderSkeleton />
  }
  const Title = () => {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="caption" color="text.secondary">
          {index + 1}/{total}
        </Typography>
        <Typography variant="h4">{courseDto.title ?? ""}</Typography>
      </Box>
    )
  }

  const Header = () => {
    return (
      <Box display="flex" width="100%" gap="8px" alignItems="end">
        <Title />
        <CourseActionsMenu
          courseId={unitCourseDescriptor.courseId}
          unitId={unitId}
        />
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      className={COURSE_VIEW_CLASS_NAME}
    >
      <Header />
      {courseDto.syllabus.topics.map((topic, index) => (
        <TopicDescriptor topic={topic} index={index} key={topic.id} />
      ))}
    </Box>
  )
}

function CourseActionsMenu(props: { unitId: string; courseId: string }) {
  const dispatch = useAppDispatch()

  const onReplaceClicked = () => {
    dispatch(editUnitCoursesClicked(props))
  }
  const onEditClicked = () => {
    dispatch(editCourseClicked(props))
  }
  const onRemoveClicked = () => {
    dispatch(removeCourseFromUnit(props))
  }
  const items = [
    { text: "Replace course", onSelect: onReplaceClicked },
    { text: "Edit course", onSelect: onEditClicked },
    { text: "Remove course", onSelect: onRemoveClicked },
  ]
  return (
    <OptionsButton
      button={{
        sx: { padding: "6px" },
        leftIcon: appIcons.dots,
      }}
      items={items}
    />
  )
}

function TopicDescriptor(props: { topic: Topic; index: number }) {
  const { topic, index } = props
  const [showPreviewButton, setShowPreviewButton] = useState(false)
  const [showPreview, setShowPreview] = useState(false)

  const NumberCircle = () => {
    return (
      <Box
        display="flex"
        sx={{
          width: "20px",
          height: "20px",
          bgcolor: appTheme.palette.eTypes.sand50,
          borderRadius: "50%",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography color="text.secondary" variant="body3">
          {index + 1}
        </Typography>
      </Box>
    )
  }

  const TitleAndDuration = () => {
    return (
      <Box display="flex" flexDirection="column" gap={0}>
        <Typography variant="body2" color="text.primary">
          {topic.title}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {(topicDuration(topic) / 60).toFixed(1)} hrs
        </Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        borderRadius: "4px",
        transition: "all .35s ease-in-out",
        padding: "24px",
        width: "100%",
        backgroundColor: "white",
      }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={TOPIC_DESCRIPTOR_CLASS_NAME}
      id={topic.id}
      onMouseEnter={() => setShowPreviewButton(true)}
      onMouseLeave={() => setShowPreviewButton(false)}
    >
      <Box display="flex" gap="16px" alignItems="center">
        <NumberCircle />
        <TitleAndDuration />
      </Box>
      <Button
        variant="text"
        size="tiny"
        sx={{
          paddingX: "12px",
          opacity: showPreviewButton ? 1 : 0,
          transition: "all .15s ease",
        }}
        startIcon={
          <SvgIcon
            component={appIcons.eye}
            inheritViewBox
            sx={{
              width: "16px",
              height: "16px",
              fill: "none",
              stroke: appTheme.palette.icon.black,
            }}
          />
        }
        onClick={() => {
          setShowPreview(true)
          setShowPreviewButton(false)
        }}
      >
        Show items
      </Button>
      <TopicItemsPreviewDialog
        open={showPreview}
        topic={topic}
        onCloseClicked={() => setShowPreview(false)}
      />
    </Box>
  )
}

function TopicItemsPreviewDialog(props: {
  topic: Topic
  open: boolean
  onCloseClicked: () => void
}) {
  const { topic, open, onCloseClicked } = props
  return (
    <Dialog
      open={open}
      onClose={onCloseClicked}
      PaperProps={{
        sx: { padding: "32px 24px", width: "600px" },
      }}
    >
      <Box display="flex" flexDirection="column" gap="24px">
        <IconButton
          onClick={onCloseClicked}
          sx={{
            padding: "0px",
            position: "absolute",
            right: "16px",
            top: "16px",
          }}
        >
          <SvgIcon
            inheritViewBox
            component={appIcons.xClose}
            sx={{
              stroke: appTheme.palette.icon.black,
            }}
          />
        </IconButton>
        <Typography variant="body1_sb">{topic.title}</Typography>

        <Box display="flex" flexDirection="column">
          {topic.elements.map((element, index) => {
            return (
              <CourseSyllabusElementView
                element={element}
                topic={topic}
                index={index}
                key={element.item.id}
                readonly={true}
                sx={{
                  backgroundColor: "white",
                  border: "none",
                  borderRadius: 0,
                  paddingX: 0,
                  borderTop: `1px solid ${appTheme.palette.other.outlineBorder}`,
                  cursor: "default",
                }}
              />
            )
          })}
        </Box>
      </Box>
    </Dialog>
  )
}

function AddFirstCourseToUnit(props: { unit: UnitDto }) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchUnitCourses(props.unit))
  }, [props.unit, dispatch])

  const onLinkCourseButtonClicked = (itemType: AddUnitItemType) => {
    switch (itemType) {
      case AddUnitItemType.ExistingCourse:
        dispatch(editUnitCoursesClicked({ unitId: props.unit.id }))
        break
      case AddUnitItemType.NewCourse:
        onCreateCourseClicked()
        break
    }
  }

  const onCreateCourseClicked = () => {
    dispatch(createCourse())
      .then(unwrapResult)
      .then((course) => {
        navigate(`${props.unit.id}/${course.id}`)
      })
  }

  return (
    <Box width="100%" padding="32px">
      <LinkCourseButton onClick={onLinkCourseButtonClicked} />
    </Box>
  )
}

function AddOrManageCoursesButton(props: { unitId: string }) {
  const dispatch = useAppDispatch()
  return (
    <Box
      width="100%"
      paddingX="32px"
      paddingY="24px"
      sx={{
        backgroundColor: "white",
        boxShadow:
          "0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Button
        variant="outlined"
        size="small"
        fullWidth
        startIcon={
          <SvgIcon
            component={appIcons.plus}
            inheritViewBox
            sx={{
              stroke: appTheme.palette.icon.black,
            }}
          />
        }
        onClick={() => {
          dispatch(editUnitCoursesClicked(props))
        }}
      >
        Manage courses
      </Button>
    </Box>
  )
}

function CoursePlaceholderSkeleton() {
  return (
    <Skeleton
      variant="rectangular"
      width="100%"
      height="74px"
      animation="wave"
      sx={{
        borderRadius: "8px",
        bgcolor: appTheme.palette.action.hover,
        border: "1px solid #00000009",
      }}
    />
  )
}

export default UnitContentEditor
