import BackButton from "@cmp/buttons/backButton"
import { SelectedStatusChip } from "@cmp/syllabusStatusChip"
import { Box, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { duplicate } from "../features/syllabus/syllabusesMenuSlice"
import appTheme from "../theme/appTheme"
import OptionsButton from "@cmp/buttons/optionsButton"
import appIcons from "@utils/appIcons"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { PopupType, popupItemClicked } from "../features/ui/uiSlice"
import {
  Syllabus,
  selectSyllabus,
} from "../features/syllabus/syllabusSelectors"
import { archiveSyllabus } from "../features/syllabusEditor/syllabusEditorSlice"
import { SyllabusDtoStatusEnum } from "@masterschool/course-builder-api"

export function SyllabusViewHeader(props: {
  syllabus: Syllabus
  onEditClicked: () => void
  onActivateClicked: () => void
}) {
  const { syllabus, onEditClicked, onActivateClicked } = props

  const { name, status, isActive, id: syllabusId } = syllabus

  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px 16px",
        width: "100%",
        borderBottom: `1px solid ${appTheme.palette.divider}`,
      }}
    >
      <Box width="33%">
        <BackButton
          onClick={() => {
            navigate(`/syllabuses`)
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "34%",
          justifyContent: "center",
          alignItems: "center",
          gap: "18px",
        }}
      >
        <Box>
          {name.length > 0 ? name : "Unnamed syllabus"}
          <span
            style={{
              color: appTheme.palette.text.disabled,
            }}
          >
            {" "}
          </span>
        </Box>
        <SelectedStatusChip
          syllabusId={syllabus.id}
          status={status}
          isActive={isActive}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "33%",
          justifyContent: "end",
          gap: "8px",
        }}
      >
        <OptionsMenu syllabusId={syllabusId} />
        <Button onClick={onEditClicked} size="small" variant="outlined">
          Edit
        </Button>
        {!isActive && syllabus.status === SyllabusDtoStatusEnum.Published && (
          <Button onClick={onActivateClicked} size="small" variant="contained">
            Activate
          </Button>
        )}
      </Box>
    </Box>
  )
}

const OptionsMenu = (props: { syllabusId: string }) => {
  const { syllabusId } = props
  const dispatch = useAppDispatch()
  const syllabus = useAppSelector(selectSyllabus(syllabusId))

  const onDuplicateClicked = () => {
    dispatch(duplicate(syllabusId))
  }

  const onPreviewInCampusClicked = () => {
    if (!syllabus) return
    dispatch(
      popupItemClicked({
        type: PopupType.CampusSyllabusPreview,
        syllabus,
      }),
    )
  }

  const onDeleteClicked = () => {
    dispatch(
      popupItemClicked({
        type: PopupType.SyllabusDeleteConfirmation,
        syllabusId,
      }),
    )
  }

  const items = [
    {
      text: "Preview in campus",
      onSelect: onPreviewInCampusClicked,
    },
    {
      text: "Duplicate",
      onSelect: onDuplicateClicked,
    },
  ]

  if (syllabus?.status === SyllabusDtoStatusEnum.Archived) {
    items.push({
      text: "Delete",
      onSelect: onDeleteClicked,
    })
  } else if (syllabus?.status === SyllabusDtoStatusEnum.Draft) {
    items.push({
      text: "Archive",
      onSelect: () => {
        dispatch(archiveSyllabus({ syllabusId: syllabus.id }))
      },
    })
  }

  return (
    <OptionsButton
      button={{
        sx: {
          padding: "3px",
          border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        },
        leftIcon: appIcons.dots,
      }}
      items={items}
    />
  )
}
