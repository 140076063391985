import { useAppDispatch } from "@app/hooks"
import { elementEdited } from "../../../features/courseEditor/courseEditorSlice"
import { CourseSyllabusElement, Topic } from "@masterschool/course-builder-api"

function useEditElement() {
  const dispatch = useAppDispatch()
  return (element: CourseSyllabusElement, topic: Topic) => {
    dispatch(
      elementEdited({
        editStepId: window.crypto.randomUUID(),
        topicId: topic.id,
        element: element,
      }),
    )
  }
}

export default useEditElement
