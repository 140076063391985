import { useAppDispatch, useAppSelector } from "@app/hooks"
import ItemDescriptor from "@cmp/itemDescriptor"
import { CourseDto, UnitDto } from "@masterschool/course-builder-api"
import appIcons from "@utils/appIcons"
import {
  courseSyllabusDuration,
  durationFormatting,
} from "@utils/syllabus+duration"
import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { isCourse } from "../../../features/courseEditor/courseEditorUtils"
import {
  selectIsUnitStale,
  selectUnitCourses,
} from "../../../features/syllabus/syllabusSelectors"
import { fetchUnitCourses } from "../../../features/syllabus/syllabusesMenuSlice"
import { syllabusUnitRemoved } from "../../../features/syllabusEditor/syllabusEditorSlice"
import appTheme from "../../../theme/appTheme"
import { Badge } from "@mui/material"

function UnitDescriptorView(props: {
  syllabusId: string
  unit: UnitDto
  index: number
  editMode: boolean
  hide?: boolean
  unitTimeFrame?: string
  onClick?: () => void
}) {
  const { syllabusId, unit, index, editMode, hide, unitTimeFrame, onClick } =
    props
  const [searchParams] = useSearchParams()
  const unitId = searchParams.get("unitId")
  const isSelected = unitId === unit.id
  const dispatch = useAppDispatch()
  const courses = useAppSelector(selectUnitCourses(syllabusId, unit.id))
  const descriptorModel = getDescriptorModel(
    unit,
    courses.filter(isCourse),
    isSelected,
    editMode,
    unitTimeFrame,
  )

  const isUnitStale = useAppSelector(selectIsUnitStale(syllabusId, unit.id))
  const didCloseStaleCoursesPopup = useAppSelector((state) =>
    state.ui.sessionHistory.presentedStaleCoursesSnackbar.some(
      (model) => model.syllabusId === syllabusId,
    ),
  )
  const showBadge = isUnitStale && !didCloseStaleCoursesPopup
  const isMissingUnitNumber = !unit.unitNumber || isNaN(unit.unitNumber)
  const isInvalid = isMissingUnitNumber && unit.courseDescriptors.length > 0

  useEffect(() => {
    dispatch(fetchUnitCourses(unit))
  }, [unit, dispatch])

  return (
    <Badge
      variant="dot"
      color={hide ? undefined : showBadge ? "info" : undefined}
      sx={{
        "& .MuiBadge-badge": {
          right: 4,
          top: 2,
          zIndex: 1000,
        },
        width: "100%",
      }}
    >
      <ItemDescriptor
        title={descriptorModel.title}
        subtitle={descriptorModel.subtitle}
        onClick={onClick}
        leftIcon={editMode ? appIcons.dotsGrid : undefined}
        leftIconSx={{
          cursor: "grab",
          opacity: hide ? 0 : 1,
        }}
        index={editMode ? index : undefined}
        optionsItems={
          editMode
            ? [
                {
                  text: "Remove unit",
                  icon: appIcons.trash03,
                  onSelect: () => {
                    dispatch(
                      syllabusUnitRemoved({
                        unitId: unit.id,
                      }),
                    )
                  },
                },
              ]
            : undefined
        }
        sx={{
          opacity: hide ? 0 : 1,
          border: isInvalid
            ? `2px solid ${appTheme.palette.error.main}`
            : `1px solid ${descriptorModel.borderColor}`,
          backgroundColor: descriptorModel.background,
          ":hover": {
            backgroundColor: descriptorModel.hoverBackground,
          },
          overflow: "visible",
        }}
      />
    </Badge>
  )
}

type DescriptorModel = {
  title: string
  subtitle: string
  background: string
  hoverBackground: string
  borderColor: string
}

function getDescriptorModel(
  unit: UnitDto,
  courses: CourseDto[],
  isSelected: boolean,
  editMode: boolean,
  unitTimeFrame?: string,
): DescriptorModel {
  const borderColor = isSelected
    ? appTheme.palette.info.main
    : appTheme.palette.other.outlineBorder

  const titleFirstLine = `${unit.shortTitle || ""}${unit.unitNumber || ""}`
  const title =
    titleFirstLine.length > 0
      ? `${titleFirstLine}\n${unit.name || "Unnamed unit"}`
      : `${unit.name || "Unnamed unit"}`

  const coursesDuration = courses.map((c) => courseSyllabusDuration(c.syllabus))
  const totalDuration = coursesDuration.reduce((a, b) => a + b, 0)

  const subtitle =
    unitTimeFrame ??
    (courses.length > 0 ? durationFormatting(totalDuration) : "Empty unit")

  const background = isSelected
    ? "rgba(33, 150, 243, 0.08)"
    : !editMode && unit.courseDescriptors.length === 0
    ? appTheme.palette.grey[100]
    : "transparent"

  const hoverBackground = isSelected
    ? "rgba(33, 150, 243, 0.08)"
    : appTheme.palette.action.hover

  return {
    title,
    subtitle,
    background,
    hoverBackground,
    borderColor,
  }
}

export default UnitDescriptorView
