import appIcons from "@utils/appIcons"
import appTheme from "../../../../theme/appTheme"
import OptionsButton from "@cmp/buttons/optionsButton"
import { SprintDto } from "@masterschool/course-builder-api"
import { Box, Button, Dialog, Typography } from "@mui/material"
import EditorTextField from "../../../components/editorTextField"
import { useCallback, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import {
  sprintMetadataEdited,
  splitSprint,
} from "../../../../features/syllabusEditor/syllabusEditorSlice"
import useActiveSyllabusUnit from "../../panel/useActiveSyllabusUnit"
import { selectTopicsInSprint } from "./unit.editor.selectors"
import PracticeWeekThumbnail from "../../../../assets/images/practice.week.png"
import VacationWeekThumbnail from "../../../../assets/images/vacation.week.png"

export const SprintActionsMenu = (props: { sprint: SprintDto }) => {
  const { title, id, isVacationWeek, isPracticeWeek } = props.sprint
  const dispatch = useAppDispatch()
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false)
  const canSplitSprint = useCanSplitSprint(id)
  const unitId = useActiveSyllabusUnit()?.id
  const inputRef = useRef<HTMLInputElement>(null)

  const onRenameClicked = () => {
    setIsRenameDialogOpen(true)
  }

  const onMarkPracticeWeekClicked = () => {
    dispatch(
      sprintMetadataEdited({
        id,
        metadata: {
          isPracticeWeek: !isPracticeWeek,
          isVacationWeek: false,
        },
      }),
    )
  }

  const onMarkVacationWeekClicked = () => {
    dispatch(
      sprintMetadataEdited({
        id,
        metadata: {
          isVacationWeek: !isVacationWeek,
          isPracticeWeek: false,
        },
      }),
    )
  }

  const onSaveClicked = useCallback(() => {
    if (inputRef.current === null) return
    dispatch(
      sprintMetadataEdited({
        id,
        metadata: { title: inputRef.current.value },
      }),
    )
    setIsRenameDialogOpen(false)
  }, [id, dispatch])

  useEffect(() => {
    if (!isRenameDialogOpen) return
    function handleKeyDown(event: KeyboardEvent): void {
      if (event.key === "Enter") {
        onSaveClicked()
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [isRenameDialogOpen, onSaveClicked])

  const onSplitSprintClicked = () => {
    if (!unitId) return
    dispatch(splitSprint({ sprintId: id, unitId }))
  }

  const items = [
    {
      text: "Rename sprint",
      onSelect: onRenameClicked,
      icon: appIcons.pencilLine,
    },
    {
      text: `${isPracticeWeek ? `Remove` : `Mark as`} practice week`,
      onSelect: onMarkPracticeWeekClicked,
      thumbnail: (
        <img
          src={PracticeWeekThumbnail}
          alt="practice week"
          width="24px"
          height="20px"
        />
      ),
    },
    {
      text: `${isVacationWeek ? `Remove` : `Mark as`} vacation week`,
      onSelect: onMarkVacationWeekClicked,
      thumbnail: (
        <img
          src={VacationWeekThumbnail}
          alt="practice week"
          width="24px"
          height="20px"
        />
      ),
    },
    {
      text: "Split sprint",
      onSelect: onSplitSprintClicked,
      disabled: !canSplitSprint,
      icon: appIcons.splitSprint,
    },
  ]
  return (
    <div>
      <Dialog
        open={isRenameDialogOpen}
        onClose={() => setIsRenameDialogOpen(false)}
        PaperProps={{ sx: { padding: "32px 24px", width: "440px" } }}
        onAnimationEnd={() => {
          inputRef.current?.focus()
        }}
        disableRestoreFocus
      >
        <Box display="flex" flexDirection="column" gap="24px">
          <Typography variant="body1_sb">Rename sprint</Typography>
          <EditorTextField
            label="Sprint name"
            defaultValue={title}
            inputRef={inputRef}
          />

          <Box display="flex" justifyContent="end" gap="16px">
            <Button
              variant="outlined"
              onClick={() => setIsRenameDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={onSaveClicked}>
              Save
            </Button>
          </Box>
        </Box>
      </Dialog>
      <OptionsButton
        button={{
          sx: {
            padding: "3px",
          },
          leftIcon: appIcons.edit02,
          iconSx: {
            fill: "none",
            width: "20px",
            height: "20px",
            stroke: appTheme.palette.icon.black,
          },
        }}
        items={items}
      />
    </div>
  )
}

const useCanSplitSprint = (sprintId: string) => {
  const unit = useActiveSyllabusUnit()

  const sprintIndex = unit?.sprints.findIndex((s) => s.id === sprintId) ?? -1
  const topicsInCurrentSprint = useAppSelector(
    selectTopicsInSprint(unit?.id ?? "", sprintIndex),
  )

  return topicsInCurrentSprint.length > 1
}
