import { useAppSelector } from "@app/hooks"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { UnitCourseDescriptor } from "@masterschool/course-builder-api"
import { selectUnitCourse } from "../../../features/syllabus/syllabusSelectors"
import CoursePreview from "../../coursePreview"

function UnitCoursePreviewPanel(props: {
  descriptor: UnitCourseDescriptor
  syllabusTitlePrefix?: string
}) {
  const { descriptor, syllabusTitlePrefix } = props
  const course = useAppSelector(selectUnitCourse(descriptor))

  if (!course) {
    return <LoadingComponent loadedComponents="course" />
  }

  return (
    <CoursePreview course={course} syllabusTitlePrefix={syllabusTitlePrefix} />
  )
}

export default UnitCoursePreviewPanel
