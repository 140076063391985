import { store } from "@app/store"
import { Configuration, ProgramApi } from "@masterschool/course-builder-api"
import { selectAccessToken } from "../features/user/userSlice"

export class ProgramClient {
  static programClient = new ProgramApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static listPrograms() {
    return ProgramClient.programClient
      .programControllerList(ProgramClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static setSyllabusForProgram(programId: string, syllabusId: string) {
    return ProgramClient.programClient
      .programControllerPutSyllabusForProgram({
        ...ProgramClient.makeHeaders(),
        data: {
          programId,
          syllabusId,
        },
      })
      .then((response) => {
        return response.data
      })
  }
}
