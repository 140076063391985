import {
  CourseSyllabusElement,
  CourseSyllabusElementTypeEnum,
  Topic,
} from "@masterschool/course-builder-api"
import { elementDuration } from "@utils/syllabus+duration"

export function isTopicValid(topic: Topic) {
  return (
    isTitleValid(topic) &&
    !isTopicEmpty(topic) &&
    !doesTopicContainMandatoryItem(topic)
  )
}

export function isTitleValid(topic: Topic) {
  return topic.title.length > 0
}

export function isTopicEmpty(topic: Topic) {
  return topic.elements.length === 0
}

export function doesTopicContainMandatoryItem(topic: Topic) {
  return topic.elements.some(isElementMandatory)
}

function isElementMandatory(element: CourseSyllabusElement) {
  switch (element.type) {
    case CourseSyllabusElementTypeEnum.Test:
    case CourseSyllabusElementTypeEnum.Survey:
      return true
    case CourseSyllabusElementTypeEnum.DatacampLesson:
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
    case CourseSyllabusElementTypeEnum.NotionLesson:
    case CourseSyllabusElementTypeEnum.OtherLesson:
    case CourseSyllabusElementTypeEnum.CourseraLesson:
    case CourseSyllabusElementTypeEnum.TryHackmeLesson:
    case CourseSyllabusElementTypeEnum.TypeformLesson:
    case CourseSyllabusElementTypeEnum.UdacityLesson:
    case CourseSyllabusElementTypeEnum.RunestoneLesson:
      return (element.item as { isMandatory: boolean }).isMandatory
    case CourseSyllabusElementTypeEnum.CodioProject:
      return false
  }
}

export function isElementValid(element: CourseSyllabusElement) {
  if (element.item.title.length === 0) return false
  if (elementDuration(element) === 0) return false

  switch (element.type) {
    case CourseSyllabusElementTypeEnum.DatacampLesson:
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
    case CourseSyllabusElementTypeEnum.NotionLesson:
    case CourseSyllabusElementTypeEnum.OtherLesson:
    case CourseSyllabusElementTypeEnum.CourseraLesson:
    case CourseSyllabusElementTypeEnum.TryHackmeLesson:
    case CourseSyllabusElementTypeEnum.TypeformLesson:
    case CourseSyllabusElementTypeEnum.UdacityLesson:
    case CourseSyllabusElementTypeEnum.CodioProject:
    case CourseSyllabusElementTypeEnum.Test:
    case CourseSyllabusElementTypeEnum.Survey:
      return element.item.title.length > 0
  }
}

export function isValidURL(str: string) {
  try {
    new URL(str)
    return true
  } catch {
    return false
  }
}
