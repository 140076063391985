import { useAppDispatch, useAppSelector } from "@app/hooks"
import { Box, Typography } from "@mui/material"
import {
  topicAdded,
  dragEnded,
  dragStarted,
  elementDraggedOver,
} from "../../features/courseEditor/courseEditorSlice"
import {
  selectActiveCourse,
  selectDraggedId,
} from "../../features/courseEditor/courseEditorSelectors"
import { CourseDto } from "@masterschool/course-builder-api"
import AddTopicButton from "./addTopicButton"
import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { createPortal } from "react-dom"
import CourseSyllabusElementView from "./courseSyllabusElementView"
import TopicView from "./topicView"
import Droppable from "@cmp/droppable"
import { useEffect, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"

function TopicsContainer() {
  const course = useAppSelector(selectActiveCourse)
  const ref = useRef<HTMLDivElement>(null)
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false)
  const dispatch = useAppDispatch()
  const [lastCreatedTopicId, setLastCreatedTopicId] = useState<string | null>(
    null,
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const closeCurrentElement = () => {
    searchParams.delete("elementId")
    setSearchParams(searchParams, {
      replace: true,
    })
  }

  useEffect(
    () => {
      if (shouldScrollToBottom) {
        ref.current?.scrollTo({
          top: ref.current.scrollHeight,
          behavior: "smooth",
        })
        setShouldScrollToBottom(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldScrollToBottom],
  )

  if (!course) {
    return null
  }
  const hasTopics = course.syllabus.topics.length > 0

  return (
    <Box
      sx={{
        display: "flex",
        gap: "12px",
        flexDirection: "column",
        maxHeight: "100%",
        width: "100%",
        paddingBottom: "40px",
      }}
    >
      <Typography variant="body2" color="text.secondary" sx={{ mb: "8px" }}>
        Add at least one topic with at least one item to publish your course.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          maxHeight: "100%",
          width: "100%",
          overflow: "auto",
        }}
        ref={ref}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <TopicsView course={course} shouldOpenTopicId={lastCreatedTopicId} />
      </Box>
      <Box width="100%" paddingTop={hasTopics ? "20px" : "0"}>
        <AddTopicButton
          onClick={() => {
            setShouldScrollToBottom(true)
            closeCurrentElement()
            const newTopicId = window.crypto.randomUUID()
            setLastCreatedTopicId(newTopicId)
            dispatch(
              topicAdded({
                editStepId: window.crypto.randomUUID(),
                topic: {
                  id: newTopicId,
                  title: "",
                  isHidden: false,
                  elements: [],
                },
              }),
            )
          }}
        />
      </Box>
    </Box>
  )
}

function TopicsView(props: {
  course: CourseDto
  shouldOpenTopicId: string | null
}) {
  const { course, shouldOpenTopicId } = props
  const topics = course.syllabus.topics
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )
  const dispatch = useAppDispatch()
  const draggedId = useAppSelector(selectDraggedId)
  const draggedTopic = topics.find((t) => t.id.toString() === draggedId)
  const draggedElementTopic = topics.find((t) =>
    t.elements.some((e) => e.item.id === draggedId),
  )
  const draggedElement = draggedElementTopic?.elements.find(
    (e) => e.item.id === draggedId,
  )

  function handleDragOver(event: DragOverEvent) {
    const { active, over } = event
    const activeIndex = active?.data.current?.sortable?.index
    const activeContainerId = active?.data.current?.sortable?.containerId
    const overIndex = over?.data.current?.sortable?.index
    const overContainerId = over?.data.current?.sortable?.containerId

    dispatch(
      elementDraggedOver({
        editStepId: window.crypto.randomUUID(),
        active: {
          index: activeIndex,
          containerId: activeContainerId,
        },
        over: {
          index: overIndex,
          containerId: overContainerId,
        },
      }),
    )
  }

  return (
    <DndContext
      onDragStart={(event) => {
        dispatch(dragStarted({ id: event.active.id.toString() }))
      }}
      onDragEnd={() => {
        dispatch(dragEnded())
      }}
      onDragCancel={() => {
        dispatch(dragEnded())
      }}
      onDragOver={handleDragOver}
      sensors={sensors}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          paddingTop: "3px",
        }}
      >
        <Droppable
          id={course.id}
          items={topics.map((topic, index) => {
            return {
              identifier: topic.id.toString(),
              element: (
                <TopicView
                  key={topic.id}
                  topic={topic}
                  isOpenByDefault={
                    index === 0 || shouldOpenTopicId === topic.id
                  }
                />
              ),
            }
          })}
          placeholder={<></>}
          style={(isDragging) => ({
            opacity: isDragging ? 0 : 1,
          })}
        />
      </Box>
      {createPortal(
        <DragOverlay
          dropAnimation={{
            duration: 300,
            easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
          }}
          style={{
            zIndex: 9999999,
          }}
        >
          {draggedTopic ? (
            <TopicView topic={draggedTopic} />
          ) : draggedElement && draggedElementTopic ? (
            <CourseSyllabusElementView
              element={draggedElement}
              topic={draggedElementTopic}
              index={NaN}
            />
          ) : null}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  )
}

export default TopicsContainer
