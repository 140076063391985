import { CourseDto } from "@masterschool/course-builder-api"

export function isCourse(
  course: CourseDto | string | undefined,
): course is CourseDto {
  return typeof course !== "string" && course !== undefined
}

type ExtractKeysOfValueType<T, K> = {
  [I in keyof T]: T[I] extends K ? I : never
}[keyof T]

export type StringCourseDtoFields = ExtractKeysOfValueType<
  CourseDto,
  string | undefined
>
