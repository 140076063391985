import { SyllabusDtoStatusEnum } from "@masterschool/course-builder-api"
import { Badge, Chip } from "@mui/material"
import { useAppSelector } from "@app/hooks"
import { selectIsSyllabusPublishable } from "../features/syllabus/syllabusSelectors"

export function SelectedStatusChip(props: {
  syllabusId: string
  status: SyllabusDtoStatusEnum
  isActive: boolean
}) {
  const { syllabusId, status, isActive } = props

  const showIndicator =
    useAppSelector(selectIsSyllabusPublishable(syllabusId)) &&
    status !== SyllabusDtoStatusEnum.Draft

  return (
    <SyllabusStatusChip
      status={status}
      isActive={isActive}
      showIndicator={showIndicator}
    />
  )
}

function SyllabusStatusChip(props: {
  status: SyllabusDtoStatusEnum
  isActive: boolean
  showIndicator?: boolean
}) {
  const { status, isActive, showIndicator } = props

  const statusTextAndColor = ((): {
    text: string
    color: "success" | "error" | undefined
  } => {
    if (isActive) {
      return {
        text: "Active",
        color: "success",
      }
    }
    switch (status) {
      case undefined:
        return {
          text: "Unknown",
          color: undefined,
        }
      case SyllabusDtoStatusEnum.Draft:
        return {
          text: "Drafts",
          color: undefined,
        }
      case SyllabusDtoStatusEnum.Archived:
        return {
          text: "Archived",
          color: "error",
        }
      case SyllabusDtoStatusEnum.Published:
        return {
          text: "Published",
          color: "success",
        }
      default:
        return {
          text: "Unknown",
          color: undefined,
        }
    }
  })()

  const chip = (
    <Chip
      label={statusTextAndColor.text}
      variant={isActive ? "filled" : "outlined"}
      size="small"
      color={statusTextAndColor.color}
      sx={{
        width: "fit-content",
        color: isActive ? "white" : undefined,
      }}
    />
  )

  return showIndicator ? (
    <Badge
      variant="dot"
      color="info"
      sx={{
        "& .MuiBadge-badge": {
          right: 12,
          top: 2,
        },
      }}
    >
      {chip}
    </Badge>
  ) : (
    chip
  )
}

export default SyllabusStatusChip
