import { Button, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"

export function AddItemButton(props: { text: string; onClick: () => void }) {
  const { text, onClick } = props
  return (
    <Button
      variant="contained"
      onClick={onClick}
      style={{
        width: "fit-content",
        height: "fit-content",
        gap: "8px",
      }}
      size="small"
    >
      <SvgIcon
        component={appIcons.plus}
        sx={{
          stroke: appTheme.palette.primary.contrastText,
          fill: "inherit",
          width: "20px",
          height: "20px",
        }}
        inheritViewBox
      />
      <Typography
        fontSize="14px"
        variant="body2"
        color={appTheme.palette.primary.contrastText}
      >
        {text}
      </Typography>
    </Button>
  )
}
