import { createSelector } from "@reduxjs/toolkit"
import {
  selectEditableSyllabus,
  selectFetchedCourses,
} from "../../../../features/syllabus/syllabusSelectors"
import { CourseDto } from "@masterschool/course-builder-api"
import { RootState } from "@app/store"
import { selectPublishedCourses } from "../../../../features/coursesMenu/coursesSelectors"
import { CourseState } from "../../../../features/coursesMenu/coursesMenuSlice"

export const selectTopicsInEditedUnit = (unitId: string) =>
  createSelector(selectEditedUnitCourses(unitId), (courses) =>
    courses.flatMap((c) => c.syllabus.topics),
  )

const selectEditedUnit = (unitId: string) =>
  createSelector([selectEditableSyllabus], (syllabus) =>
    syllabus?.units.find((u) => u.id === unitId),
  )

export const selectEditedUnitCourses = (unitId: string) =>
  createSelector(
    [selectEditedUnit(unitId), selectFetchedCourses],
    (unit, courses) => {
      if (!unit) return []

      return unit.courseDescriptors
        .map((cd) => courses[cd.courseId]?.[cd.version])
        .filter((c): c is CourseDto => c !== undefined)
    },
  )
const selectPopupSelectedCourses = (state: RootState) =>
  state.syllabusEditor.coursesSelectionPopup?.selectedCourses ?? []

export const selectUpdatedUnitCourses = createSelector(
  [selectPopupSelectedCourses, selectPublishedCourses],
  (selectedDescriptors, courses) => {
    if (!courses) return []
    return selectedDescriptors
      .map((cd) => courses.find((c) => c.id === cd.courseId))
      .filter((c): c is CourseState => c !== undefined)
  },
)

export const selectTopicsInSprint = (unitId: string, index: number) =>
  createSelector(
    [selectSprintsInUnit(unitId), selectTopicsInEditedUnit(unitId)],
    (sprints, topics) => {
      if (index > sprints.length - 1) return []
      const sprint = sprints[index]
      const lastTopicIndex = topics.findIndex(
        (t) => t.id === sprint.lastTopicId,
      )
      if (index === 0) return topics.slice(0, lastTopicIndex + 1)

      const prevSprint = sprints[index - 1]
      const prevSprintLastTopicIndex = topics.findIndex(
        (t) => t.id === prevSprint.lastTopicId,
      )
      return topics.slice(prevSprintLastTopicIndex + 1, lastTopicIndex + 1)
    },
  )

const selectSprintsInUnit = (unitId: string) =>
  createSelector(selectEditedUnit(unitId), (unit) => {
    return unit?.sprints ?? []
  })
