import { Box } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import useSelectedUnit from "../useSelectedUnit"
import SyllabusCoursePreviewHeader from "./syllabusCoursePreviewHeader"
import UnitCoursePreviewPanel from "./unitCoursePreviewPanel"

function UnitPreviewPanel(props: { syllabusTitlePrefix?: string }) {
  const { syllabusTitlePrefix } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const unit = useSelectedUnit()

  if (!unit) {
    return null
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <SyllabusCoursePreviewHeader
        onClose={() => {
          searchParams.delete("unitId")
          setSearchParams(searchParams, {
            replace: true,
          })
        }}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          padding: "0px 40px",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        {unit.courseDescriptors.map((descriptor) => {
          return (
            <div
              key={descriptor.courseId}
              style={{
                width: "100%",
              }}
            >
              <UnitCoursePreviewPanel
                descriptor={descriptor}
                syllabusTitlePrefix={syllabusTitlePrefix}
              />
            </div>
          )
        })}
      </Box>
    </Box>
  )
}

export default UnitPreviewPanel
