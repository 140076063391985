import { createSlice } from "@reduxjs/toolkit"
import { LoginClient } from "@clients/loginClient"
import { LoginRequest } from "@masterschool/course-builder-api"
import { createAppAsyncThunk } from "@app/createAppAsyncThunk"

export type User = {
  id: number
  name: string
  email: string
  firstName: string
  lastName: string
  pictureUrl: string | undefined
  roles: Role[]
}

enum Role {
  MasterschoolEmployee = "ms_employee",
  Instructor = "instructor",
}
type UserState =
  | "pending"
  | "error"
  | (User & { accessToken: string })
  | undefined
  | null

const userFromState = (
  state: UserState,
): (User & { accessToken: string }) | undefined => {
  console.log(`typeof state !== "string"`, typeof state !== "string")
  if (typeof state !== "string" && state !== undefined && state !== null) {
    return state
  } else {
    return undefined
  }
}

const getInitialState: () => UserState = () => {
  return null
}

export const login = createAppAsyncThunk(
  "user/login",
  async (request: LoginRequest) => {
    return LoginClient.login(request)
  },
)

const userSlice = createSlice({
  name: "user",
  initialState: getInitialState,
  reducers: {
    loggedOut: (state) => {
      return getInitialState()
    },
    loadedUser: (state, action) => {
      return action.payload
    },
    accessTokenNotFoundInStorage: (state) => {
      return undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserForAccessToken.pending, (state) => {
        state = "pending"
      })
      .addCase(fetchUserForAccessToken.rejected, (state) => {
        state = "error"
      })
      .addCase(fetchUserForAccessToken.fulfilled, (state, action) => {
        console.log("setting state", action.payload)
        state = action.payload
      })
  },
})

export const selectIsLoggedIn = (state: { user: UserState }) => {
  console.log("state", state)
  console.log("userFromState(state.user)", userFromState(state.user))
  const isLoggedIn = userFromState(state.user) !== undefined
  console.log("userSlice/isLoggedIn", isLoggedIn)
  return isLoggedIn
}

export const loginState = (state: { user: UserState }) => state.user

export const selectAccessToken = (state: { user: UserState }) => {
  const user = userFromState(state.user)
  return user?.accessToken
}

export const {
  loggedOut: logout,
  accessTokenNotFoundInStorage: noAccessTokenFoundLocally,
} = userSlice.actions
export default userSlice.reducer

export const loadAccessTokenFromLocalStorage = createAppAsyncThunk(
  "user/loadAccessToken",
  async (_, thunkApi) => {
    const accessToken = localStorage.getItem("accessToken")
    if (accessToken) {
      thunkApi.dispatch(fetchUserForAccessToken(accessToken))
    } else {
      thunkApi.dispatch(noAccessTokenFoundLocally())
    }
  },
)

export const fetchUserForAccessToken = createAppAsyncThunk(
  "user/fetchUserForAccessToken",
  async (accessToken: string) => {
    return LoginClient.getUser(accessToken).then((user) => {
      return { accessToken, ...user }
    })
  },
)

export const fetchUserFromTemporaryToken = createAppAsyncThunk(
  "user/fetchUserFromTemporaryToken",
  async (temporaryToken: string, thunkApi) => {
    console.log("fetching user from temporary token")
    await LoginClient.getPermanentToken(temporaryToken).then((res) => {
      console.log("fetching user from permanent token")
      return thunkApi.dispatch(fetchUserForAccessToken(res.accessToken))
    })
  },
)
