import { useAppDispatch, useAppSelector } from "@app/hooks"
import Droppable from "@cmp/droppable"
import {
  Topic,
  TopicItemValidationErrorsEnum,
} from "@masterschool/course-builder-api"
import { Box, Collapse, FormControl, FormHelperText } from "@mui/material"
import SyllabusElementFactory from "@utils/syllabusElementFactory"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import {
  selectIsTopicInvalid,
  selectPublishValidationsForItem,
} from "../../features/courseEditor/courseValidationsSelectors"
import {
  elementAdded,
  topicEdited,
} from "../../features/courseEditor/courseEditorSlice"
import appTheme from "../../theme/appTheme"
import AddItemButton from "../components/addItemButton"
import EditorTextField from "../components/editorTextField"
import CourseSyllabusElementView from "./courseSyllabusElementView"
import Placeholder from "./placeholder"
import TopicHeader from "./topicHeader"
import { selectActiveCourse } from "../../features/courseEditor/courseEditorSelectors"
import { doesTopicContainMandatoryItem } from "./topicValidations"

function TopicView(props: { topic: Topic; isOpenByDefault?: boolean }) {
  const { topic, isOpenByDefault } = props

  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState<boolean>(isOpenByDefault === true)
  const activeCourse = useAppSelector(selectActiveCourse)
  const elementsNumber =
    activeCourse?.syllabus.topics.flatMap((t) => t.elements).length ?? 0
  const placeholder = elementsNumber === 0 ? <></> : <Placeholder />
  const errors = useAppSelector(selectPublishValidationsForItem(topic.id))
  const hasMandatoryItem = doesTopicContainMandatoryItem(topic)

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: `1px solid ${appTheme.palette.other.outlineBorder}`,
        borderRadius: "8px",
        backgroundColor: "#F5F5F0",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <TopicHeader
        topic={topic}
        onClick={() => setIsOpen((current) => !current)}
        isTopicOpen={isOpen}
      />
      <Collapse in={isOpen} sx={{ width: "100%" }}>
        <Box width="100%" padding="16px">
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginBottom: "16px",
            }}
          >
            <EditorTextField
              label="Topic name"
              onChange={(e) => {
                dispatch(
                  topicEdited({
                    editStepId: window.crypto.randomUUID(),
                    topicId: topic.id,
                    key: "title",
                    value: e.target.value,
                  }),
                )
              }}
              value={topic.title}
              error={errors?.includes("MISSING_TITLE") && topic.title === ""}
              helperText={
                errors?.includes("MISSING_TITLE")
                  ? "This field is required"
                  : ""
              }
              multiline
              maxRows={2}
            />
            <Droppable
              id={topic.id}
              items={topic.elements.map((e, index) => {
                return {
                  identifier: e.item.id,
                  element: (
                    <CourseSyllabusElementView
                      element={e}
                      topic={topic}
                      index={index}
                    />
                  ),
                }
              })}
              placeholder={placeholder}
              style={(isDragging) => ({
                opacity: isDragging ? 0 : 1,
              })}
            />
            {errors?.includes("MISSING_MANDATORY_ITEM") &&
              !hasMandatoryItem && (
                <FormHelperText
                  error
                  sx={{
                    alignSelf: "center",
                    textTransform: "none",
                  }}
                >
                  Add at least one mandatory item, quiz or survey.
                </FormHelperText>
              )}
          </Box>
          <AddItemFormButton topic={topic} />
        </Box>
      </Collapse>
    </Box>
  )
}

export default TopicView

const AddItemFormButton = (props: { topic: Topic }) => {
  const { topic } = props
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const isInvalidBecauseMissingElements =
    useAppSelector(
      selectIsTopicInvalid(topic, TopicItemValidationErrorsEnum.ELEMENTS),
    ) && topic.elements.length === 0

  return (
    <FormControl sx={{ display: "flex" }}>
      <AddItemButton
        onClick={(type) => {
          const element = SyllabusElementFactory.makeElement(type)
          dispatch(
            elementAdded({
              editStepId: window.crypto.randomUUID(),
              topicId: topic.id,
              element: element,
            }),
          )
          searchParams.set("elementId", element.item.id)
          setSearchParams(searchParams, {
            replace: true,
          })
        }}
        sx={{
          color: isInvalidBecauseMissingElements
            ? appTheme.palette.error.main
            : undefined,
        }}
        iconSx={{
          stroke: isInvalidBecauseMissingElements
            ? appTheme.palette.error.main
            : appTheme.palette.icon.black,
        }}
      />
      <FormHelperText
        error={isInvalidBecauseMissingElements}
        variant="standard"
        sx={{
          textTransform: "none",
          paddingTop: "4px",
          paddingLeft: "14px",
        }}
      >
        {isInvalidBecauseMissingElements
          ? "Topic must have at least one element"
          : ""}
      </FormHelperText>
    </FormControl>
  )
}
