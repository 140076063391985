import {
  CourseDto,
  CourseSyllabusElement,
  Topic,
} from "@masterschool/course-builder-api"
import { Box, SvgIcon, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import {
  courseSyllabusDuration,
  durationFormatting,
} from "@utils/syllabus+duration"
import appTheme from "../theme/appTheme"
import CourseElementDescriptor from "./courseElementDescriptor"

function CoursePreview({
  course,
  syllabusTitlePrefix,
}: {
  course: CourseDto
  syllabusTitlePrefix?: string
}) {
  return (
    <Box
      sx={{
        overflow: "auto",
        height: "100%",
        width: "100%",
        padding: "48px 0px",
        gap: "24px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CourseGeneralView
        course={course}
        syllabusTitlePrefix={syllabusTitlePrefix}
      />
      <CourseTopics course={course} />
    </Box>
  )
}

function CourseGeneralView(props: {
  course: CourseDto
  syllabusTitlePrefix?: string
}) {
  const { course, syllabusTitlePrefix } = props
  const title =
    syllabusTitlePrefix !== undefined
      ? `${syllabusTitlePrefix} · ${course.title}`
      : course.title

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Typography variant="h4">{title}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "8px",
          gap: "8px",
        }}
      >
        <SvgIcon
          component={appIcons.clock}
          sx={{
            stroke: appTheme.palette.icon.black,
            fill: "none",
            width: "16px",
            height: "16px",
          }}
          inheritViewBox
        />
        <Typography variant="body2">
          {durationFormatting(courseSyllabusDuration(course.syllabus))}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        sx={{
          marginTop: "8px",
        }}
      >
        {course.description}
      </Typography>
    </Box>
  )
}

function CourseTopics(props: { course: CourseDto }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "40px",
      }}
    >
      {props.course.syllabus.topics.map((topic) => (
        <TopicView topic={topic} key={topic.id} />
      ))}
    </Box>
  )
}

function TopicView(props: { topic: Topic }) {
  const firstElementBorderRadius = {
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
  }

  const lastElementBorderRadius = {
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography variant="body2_sb">{props.topic.title}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {props.topic.elements.map(
          (element: CourseSyllabusElement, index: number) => (
            <CourseElementDescriptor
              key={element.item.id}
              element={element}
              topic={props.topic}
              editMode={false}
              extraText={
                element.item.isExtraCurricular ? "Advanced" : undefined
              }
              sx={{
                cursor: "default",
                borderRadius: "0px",
                ...(index === 0 ? firstElementBorderRadius : {}),
                ...(index === props.topic.elements.length - 1
                  ? lastElementBorderRadius
                  : {}),
                ...(index === props.topic.elements.length - 1
                  ? {}
                  : { borderBottom: "none" }),
              }}
            />
          ),
        )}
      </Box>
    </Box>
  )
}

export default CoursePreview
