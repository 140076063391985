import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  SvgIcon,
  Typography,
} from "@mui/material"

import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"
import { useEffect, useState } from "react"
import { ProgramClient } from "@clients/programClient"
import { ProgramDto } from "@masterschool/course-builder-api"
import { useNavigate } from "react-router-dom"

const ActivateSyllabusPopup = (props: {
  syllabusId: string
  onClose: () => void
  domain: string | undefined
}) => {
  const { syllabusId, onClose, domain } = props
  const [selectedProgramId, setSelectedProgramId] = useState<string>("")
  const [programs, setPrograms] = useState<ProgramDto[]>([])
  const selectedProgram = programs.find(
    (program) => program.id === selectedProgramId,
  )
  const [activationStatus, setActivationStatus] = useState<
    "success" | "error" | "loading" | undefined
  >(undefined)
  const navigate = useNavigate()

  useEffect(() => {
    ProgramClient.listPrograms().then((programs) => {
      setPrograms(
        programs.filter(
          (program) =>
            program.syllabusId === undefined || program.syllabusId === null,
        ),
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const filteredPrograms = programs.filter(
    (program) =>
      domain === undefined || domain === "" || program.domain === props.domain,
  )

  const onPublishClicked = () => {
    setActivationStatus("loading")
    ProgramClient.setSyllabusForProgram(selectedProgramId, syllabusId)
      .then(() => {
        setActivationStatus("success")
      })
      .catch((e) => {
        setActivationStatus("error")
      })
  }

  if (activationStatus) {
    return (
      <Dialog
        onClose={onClose}
        open={true}
        PaperProps={{
          sx: { borderRadius: "8px", maxWidth: "388px" },
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: "24px",
            minWidth: "388px",
          }}
        >
          <ContentForStatus
            status={activationStatus}
            selectedProgram={selectedProgram}
            onClose={() => {
              onClose()
              if (activationStatus === "success") {
                navigate(`/syllabus/${syllabusId}`)
              }
            }}
          />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog
      onClose={onClose}
      open={true}
      PaperProps={{
        sx: { borderRadius: "8px", maxWidth: "388px" },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "24px",
        }}
      >
        <Box display="flex" width="100%" justifyContent="end">
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
            sx={{ padding: "2px", borderRadius: "50%" }}
          >
            <SvgIcon
              component={appIcons.xClose}
              inheritViewBox
              sx={{
                stroke: appTheme.palette.icon.black,
                width: "20px",
                height: "20px",
              }}
            />
          </IconButton>
        </Box>
        <DialogTitle
          variant="body1_sb"
          sx={{ padding: 0, paddingBottom: "8px" }}
        >
          Publish this syllabus?
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          component="div"
          paddingBottom="24px"
          color="text.primary"
        >
          This syllabus will be linked to a cohort and it will be visible to all
          users in the main repository.
        </DialogContentText>
        <FormControl>
          <Select
            value={selectedProgramId}
            onChange={(e) => setSelectedProgramId(e.target.value)}
            displayEmpty
            renderValue={(value) => {
              if (value === "") {
                return (
                  <Typography color="text.disabled">Choose a cohort</Typography>
                )
              } else {
                return (
                  <Typography color="text.primary">
                    {programDisplayName(
                      programs.find((program) => program.id === value),
                    )}
                  </Typography>
                )
              }
            }}
          >
            {filteredPrograms.map((program) => (
              <MenuItem value={program.id} key={program.id}>
                {programDisplayName(program)}
              </MenuItem>
            ))}
          </Select>
          {domain && domain !== "" && (
            <FormHelperText>
              Only programs from the selected domain are presented
            </FormHelperText>
          )}
        </FormControl>
        <DialogActions sx={{ padding: "32px 0px 0px 24px" }}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              onClose()
            }}
            sx={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={selectedProgramId === ""}
            onClick={onPublishClicked}
          >
            Activate
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

const ContentForStatus = (props: {
  status: "success" | "error" | "loading"
  selectedProgram: ProgramDto | undefined
  onClose: () => void
}) => {
  const { status, onClose } = props
  switch (status) {
    case "success":
      return (
        <>
          <DialogTitle
            variant="body1_sb"
            sx={{ padding: 0, paddingBottom: "8px" }}
          >
            Syllabus published!
          </DialogTitle>
          <DialogContentText
            id="alert-dialog-description"
            component="div"
            paddingBottom="24px"
          >
            This syllabus has been successfully published to program{" "}
            <b>{programDisplayName(props.selectedProgram)}</b>.
          </DialogContentText>
          <DialogActions sx={{ padding: "32px 24px 24px 24px" }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                onClose()
              }}
            >
              Done
            </Button>
          </DialogActions>
        </>
      )
    case "error":
      return (
        <>
          <DialogTitle
            variant="body1_sb"
            sx={{ padding: 0, paddingBottom: "8px" }}
          >
            Error
          </DialogTitle>
          <DialogContentText
            id="alert-dialog-description"
            component="div"
            paddingBottom="24px"
          >
            There was an error publishing this syllabus.
          </DialogContentText>
          <DialogActions sx={{ padding: "32px 24px 24px 24px" }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                onClose()
              }}
            >
              Done
            </Button>
          </DialogActions>
        </>
      )
    case "loading":
      return (
        <>
          <DialogTitle
            variant="body1_sb"
            sx={{ padding: 0, paddingBottom: "8px" }}
          >
            Publishing syllabus...
          </DialogTitle>
          <DialogContentText
            id="alert-dialog-description"
            component="div"
            paddingBottom="24px"
          >
            This syllabus is being published.
          </DialogContentText>
        </>
      )
  }
}

export default ActivateSyllabusPopup

function programDisplayName(program: ProgramDto | undefined) {
  if (!program) {
    return ""
  }
  const programType = (program.type ?? "part_time").replace("_", "-")
  return (
    program.domain +
    " - " +
    new Date(program.startDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }) +
    " - " +
    programType
  )
}
