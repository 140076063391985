import "./App.css"
import { Routes, Route, Navigate } from "react-router-dom"
import { CoursesMainPage } from "./main/coursesMainPage"
import { CourseEditorContainer } from "./editor/courseEditor"
import { PageContainer } from "@cmp/pageContainer"
import { CourseViewContainer } from "./editor/courseViewContainer"
import ProtectedRoute from "@cmp/protectedRoute"
import { SyllabusMainPage } from "./main/syllabusesMainPage"
import { SyllabusEditorContainer } from "./editor/syllabus/syllabusEditor"
import { SyllabusViewContainer } from "./view/syllabusViewContainer"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { useEffect } from "react"
import { loadAccessTokenFromLocalStorage } from "./features/user/userSlice"
import { Login } from "./login/login"
import { LoginProxyToken } from "./login/loginProxyToken"

function App() {
  const userState = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (userState === null) {
      dispatch(loadAccessTokenFromLocalStorage())
    }
  }, [userState, dispatch])

  const routes = [
    { path: "/", element: <Navigate to="/syllabuses" /> },
    { path: "login", element: <Login /> },
    { path: "login-proxy/:token/*", element: <LoginProxyToken /> },
  ]

  const protectedRoutes = [
    { path: "courses", element: <CoursesMainPage /> },
    { path: "syllabuses", element: <SyllabusMainPage /> },
    { path: "course/:courseId", element: <CourseViewContainer /> },
    { path: "syllabus/:syllabusId", element: <SyllabusViewContainer /> },
    { path: "syllabus-edit/:syllabusId", element: <SyllabusEditorContainer /> },
    {
      path: "syllabus-edit/:syllabusId/:unitId/:courseId",
      element: <SyllabusEditorContainer />,
    },
    { path: "course-edit/:courseId", element: <CourseEditorContainer /> },
  ]

  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
      <Route path="/" element={<ProtectedRoute />}>
        <Route element={<PageContainer />}>
          {protectedRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
      </Route>
    </Routes>
  )
}

export default App
