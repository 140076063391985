import { useAppDispatch, useAppSelector } from "@app/hooks"
import {
  CourseDto,
  CourseDtoStatusEnum,
} from "@masterschool/course-builder-api"
import { useNavigate } from "react-router-dom"
import { useEditCourseClicked } from "../../editor/custom-hooks/useEditCourseClicked"
import {
  duplicateCourse,
  deleteCourse,
} from "../../features/coursesMenu/coursesMenuSlice"
import {
  popupItemClicked,
  PopupState,
  PopupType,
} from "../../features/ui/uiSlice"
import { selectDependentSyllabuses } from "../../features/ui/uiSelector"
import {
  discardChangesRequested,
  discardDraftRequested,
  validateCourseForPreview,
} from "../../features/courseEditor/courseEditorSlice"

function useCourseMenuActions(course: CourseDto) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const syllabusDependencies = useAppSelector(
    selectDependentSyllabuses(course.id),
  )

  const onOpenClicked = () => {
    if (course.status === CourseDtoStatusEnum.Draft) {
      navigate(`/course-edit/${course.id}`)
    } else {
      navigate(`/course/${course.id}?version=${course.version}`)
    }
  }

  const onPreviewInCampusClicked = () => {
    const popupModel: PopupState = {
      course,
      type: PopupType.CampusCoursePreview,
    }
    switch (course.status) {
      case CourseDtoStatusEnum.Published:
        dispatch(popupItemClicked(popupModel))
        break
      case CourseDtoStatusEnum.Draft:
        dispatch(validateCourseForPreview()).then((flattenedValidations) => {
          const isCourseValid =
            Object.keys(flattenedValidations.payload ?? {}).length === 0
          if (isCourseValid) {
            dispatch(popupItemClicked(popupModel))
          }
        })
        break
      case CourseDtoStatusEnum.Archived:
        throw new Error("Course is archived, cannot preview")
    }
  }

  const onEditClicked = useEditCourseClicked()

  const onDuplicateClicked = () => {
    dispatch(duplicateCourse(course))
  }

  const onArchiveClicked = () => {
    dispatch(
      popupItemClicked({
        course: course,
        type: PopupType.ArchiveConfirmation,
      }),
    )
  }

  const onVersionHistoryClicked = () => {
    dispatch(
      popupItemClicked({
        course: course,
        type: PopupType.VersionsHistory,
      }),
    )
  }

  const onDependentSyllabusesClicked = () => {
    dispatch(
      popupItemClicked({
        course: course,
        syllabusDependencies,
        type: PopupType.DependentSyllabuses,
      }),
    )
  }

  const onDeleteClicked = () => {
    dispatch(deleteCourse(course.id))
  }

  const onDiscardChangesClicked = () => {
    dispatch(discardChangesRequested())
  }

  const onDiscardDraftClicked = () => {
    dispatch(discardDraftRequested())
  }

  return {
    onOpenClicked,
    onPreviewInCampusClicked,
    onEditClicked,
    onDuplicateClicked,
    onArchiveClicked,
    onVersionHistoryClicked,
    onDependentSyllabusesClicked,
    onDeleteClicked,
    onDiscardChangesClicked,
    onDiscardDraftClicked,
  }
}

export default useCourseMenuActions
