import { useAppSelector } from "@app/hooks"
import { CourseClient } from "@clients/courseClient"
import PendingResourceComponent from "@cmp/pendingResourceComponent"
import { CourseDto, CourseMetadataDto } from "@masterschool/course-builder-api"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useEffect, useState } from "react"
import { selectLatestPublishedCourse } from "../../../../features/coursesMenu/coursesSelectors"
import appTheme from "../../../../theme/appTheme"
import CoursePreview from "../../../coursePreview"
import StaleCourseModel from "../staleCoursesPopup/staleCourseModel"
import useComputeStaleCourseContainers from "../staleCoursesPopup/useComputeStaleCourseContainers"
import useCourseVersionHistory from "./useCourseVersionHistory"
import VersionButton from "./versionButton"

function SyllabusValidationVersionHistory(props: {
  staleCourse: StaleCourseModel
  onClose: () => void
}) {
  const { staleCourse, onClose } = props
  const model = useVersionHistoryViewModel(staleCourse)
  const courses = useCourseVersionHistory(staleCourse.courseId)
  const [selectedVersion, setSelectedVersion] = useState<
    CourseMetadataDto | undefined
  >(undefined)
  const [displayedCourse, setDisplayedCourse] = useState<
    CourseDto | "pending" | "rejected"
  >("pending")

  useEffect(() => {
    switch (courses) {
      case "pending":
      case "rejected":
        return
    }

    setSelectedVersion(courses[0])
  }, [courses])

  useEffect(() => {
    if (selectedVersion === undefined) return
    setDisplayedCourse("pending")
    CourseClient.getCourseByVersion(
      staleCourse.courseId,
      selectedVersion.version,
    )
      .then((course) => {
        setDisplayedCourse(course)
      })
      .catch(() => {
        setDisplayedCourse("rejected")
      })
  }, [staleCourse.courseId, selectedVersion])

  return (
    <Dialog open={true} maxWidth="xl" fullWidth onClose={onClose}>
      <DialogTitle
        sx={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          borderBottom: `1px solid ${appTheme.palette.other.outlineBorder}`,
        }}
      >
        Version History
        <IconButton
          onClick={(e) => {
            onClose()
            e.stopPropagation()
          }}
        >
          <SvgIcon
            component={appIcons.xClose}
            inheritViewBox
            sx={{
              stroke: appTheme.palette.icon.black,
              width: "20px",
              height: "20px",
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          height: "800px",
          padding: "0px",
        }}
      >
        <PendingResourceComponent
          resource={courses}
          render={(courses) => (
            <>
              <DialogContent
                sx={{
                  padding: "0px",
                  width: "320px",
                  borderRight: `1px solid ${appTheme.palette.other.outlineBorder}`,
                }}
              >
                {courses.map((course) => (
                  <VersionButton
                    key={course.version}
                    course={course}
                    isSelected={course.version === selectedVersion?.version}
                    onClick={() => setSelectedVersion(course)}
                    tag={
                      course.version === model?.latestVersion
                        ? "published"
                        : course.version === model?.currentVersion
                        ? "inUse"
                        : undefined
                    }
                  />
                ))}
              </DialogContent>
              <DialogContent
                sx={{
                  width: "100%",
                  padding: "0px 41px",
                }}
              >
                <PendingResourceComponent
                  resource={displayedCourse}
                  render={(course) => <CoursePreview course={course} />}
                />
              </DialogContent>
            </>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}

function useVersionHistoryViewModel(staleCourse: StaleCourseModel):
  | {
      currentVersion: number
      latestVersion: number
    }
  | undefined {
  const containers = useComputeStaleCourseContainers(staleCourse)
  const latestPublishedVersion = useAppSelector(
    selectLatestPublishedCourse(staleCourse.courseId),
  )

  if (!containers || !latestPublishedVersion) {
    return undefined
  }

  return {
    currentVersion: containers.course.version,
    latestVersion: latestPublishedVersion?.version,
  }
}

export default SyllabusValidationVersionHistory
