import { TabPanel } from "@mui/lab"

export function CBTabPanel(props: {
  children: React.ReactNode
  value: string
}) {
  const { children, value } = props
  return (
    <TabPanel
      value={value}
      sx={{
        padding: "16px 0px",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {children}
    </TabPanel>
  )
}
