import { createTheme, Shadows } from "@mui/material/styles"
import {
  MuiAccordionDetailsOverrides,
  MuiAccordionOverrides,
  MuiAccordionSummaryOverrides,
} from "./accordion"
import { MuiButtonOverrides } from "./button"
import { MuiChipOverrides } from "./chip"
import { MuiDividerOverrides } from "./divider"
import { paletteOptions as palette } from "./palette"
import { customShadows } from "./shadows"
import { MuiTabOverrides } from "./tabs"
import { MuiRadioOverrides } from "./radio"
import { MuiTypographyComponentOverride, typography } from "./typography"
import { MuiSnackbarContentOverrides } from "./snackbar"
import { MuiIconButtonOverrides } from "./iconButton"
import { MuiListItemOverrides } from "./listItem"
import { MuiTooltipOverrides } from "./tooltip"
import { MuiTextFieldOverrides } from "./texfield"
import { MuiInputLabelOverrides } from "./inputLabel"
import { MuiSelectOverrides } from "./select"
import { MuiFormHelperTextOverride } from "./FormHelperText"

const appTheme = createTheme({
  shadows: customShadows as Shadows,
  components: {
    MuiButton: MuiButtonOverrides,
    MuiTypography: MuiTypographyComponentOverride,
    MuiTab: MuiTabOverrides,
    MuiAccordion: MuiAccordionOverrides,
    MuiAccordionDetails: MuiAccordionDetailsOverrides,
    MuiAccordionSummary: MuiAccordionSummaryOverrides,
    MuiDivider: MuiDividerOverrides,
    MuiChip: MuiChipOverrides,
    MuiRadio: MuiRadioOverrides,
    MuiSnackbarContent: MuiSnackbarContentOverrides,
    MuiIconButton: MuiIconButtonOverrides,
    MuiListItem: MuiListItemOverrides,
    MuiTooltip: MuiTooltipOverrides,
    MuiTextField: MuiTextFieldOverrides,
    MuiInputLabel: MuiInputLabelOverrides,
    MuiSelect: MuiSelectOverrides,
    MuiFormHelperText: MuiFormHelperTextOverride,
  },
  typography,
  palette,
})

export default appTheme
