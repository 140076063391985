import { useAppDispatch, useAppSelector } from "@app/hooks"
import SimpleDialog from "@cmp/simpleDialog"
import {
  CourseDto,
  CourseMetadataDto,
  CourseMetadataDtoStatusEnum,
} from "@masterschool/course-builder-api"
import {
  Box,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useEffect, useState } from "react"
import { CourseClient } from "@clients/courseClient"
import CoursePreview from "../../editor/coursePreview"
import { popupClosed } from "../../features/ui/uiSlice"
import appTheme from "../../theme/appTheme"
import { LoadingButton } from "@mui/lab"
import { fetchCourses } from "../../features/coursesMenu/coursesMenuSlice"
import formatTimestamp from "@utils/formatTimestamp"
import { selectLatestPublishedCourse } from "../../features/coursesMenu/coursesSelectors"

const VersionHistoryPopup = (props: {
  courseId: string
  onClose: () => void
}) => {
  const { courseId, onClose } = props
  const [versionsHistory, setVersionsHistory] = useState<
    CourseMetadataDto[] | undefined
  >(undefined)
  const [selectedVersion, setSelectedVersion] = useState<number | undefined>(
    undefined,
  )
  const [displayedCourse, setDisplayedCourse] = useState<CourseDto | undefined>(
    undefined,
  )
  const isLoading =
    versionsHistory === undefined || displayedCourse === undefined
  const dispatch = useAppDispatch()

  const [refetchVersionHistoryCount, setRefetchVersionHistoryCount] =
    useState(1)

  useEffect(() => {
    if (selectedVersion === undefined) return
    CourseClient.getCourseByVersion(courseId, selectedVersion).then(
      (course) => {
        setDisplayedCourse(course)
      },
    )
  }, [courseId, selectedVersion])

  const [isReverting, setIsReverting] = useState(false)
  useEffect(() => {
    if (isReverting) return
    CourseClient.listCourseVersions(courseId).then((versions) => {
      const sorted = versions.sort((a, b) => b.version - a.version)
      setVersionsHistory(sorted)
      setSelectedVersion(sorted[0].version)
    })
  }, [courseId, isReverting])

  const lastVersionStatus = statusOfLastVersion(versionsHistory)
  const isLastVersionPublished =
    lastVersionStatus === CourseMetadataDtoStatusEnum.Published
  const isLastVersion = (() => {
    if (!versionsHistory || versionsHistory.length === 0) return false
    return versionsHistory[0].version === displayedCourse?.version
  })()
  const [showRevertVerification, setShowRevertVerification] = useState(false)
  const lastPublishedVersion = useAppSelector(
    selectLatestPublishedCourse(courseId),
  )
  const showRevertButton = !isLastVersion

  const Header = () => {
    return (
      <Box display={"flex"} justifyContent="space-between" padding="24px">
        <Typography variant="h6">Version history</Typography>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
          sx={{ padding: "2px", borderRadius: "50%" }}
        >
          <SvgIcon
            component={appIcons.xClose}
            inheritViewBox
            sx={{
              stroke: appTheme.palette.icon.black,
              width: "20px",
              height: "20px",
            }}
          />
        </IconButton>
      </Box>
    )
  }

  const onRevertClicked = () => {
    if (isLastVersionPublished) {
      revert()
    } else {
      setShowRevertVerification(true)
    }
  }

  const revertButton = (
    <LoadingButton
      loading={isReverting}
      variant="outlined"
      size="small"
      sx={{
        width: "fit-content",
        alignSelf: "flex-end",
        mt: "45px",
      }}
      onClick={onRevertClicked}
    >
      Revert to this version
    </LoadingButton>
  )

  const revert = () => {
    if (displayedCourse?.version === undefined) {
      return
    }
    setIsReverting(true)
    CourseClient.revertToVersion(courseId, displayedCourse.version)
      .then(() => {
        setRefetchVersionHistoryCount(refetchVersionHistoryCount + 1)
        setShowRevertVerification(false)
        setIsReverting(false)
      })
      .then(() => {
        dispatch(fetchCourses())
      })
  }

  const revertVerificationDialog = (
    <SimpleDialog
      title="Revert to this version?"
      content="Any changes you’ve made to the course will be deleted."
      onClose={() => setShowRevertVerification(false)}
      buttons={[
        {
          children: "Cancel",
          variant: "outlined",
          size: "small",
          onClick: () => setShowRevertVerification(false),
        },
        {
          children: "Revert",
          variant: "contained",
          loading: isLoading,
          size: "small",
          onClick: revert,
        },
      ]}
    />
  )

  return (
    <>
      {showRevertVerification && revertVerificationDialog}
      <Dialog
        open={true}
        onClose={() => {
          dispatch(popupClosed())
        }}
        fullWidth
        maxWidth={"lg"}
        sx={{
          ".MuiDialog-paper": {
            width: "100%",
            height: "100%",
            margin: "0px",
            padding: "0px",
          },
          boxShadow: "1px 1px 1px 1px rgba(0,0,0,1)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header />
          <Divider />
          <Box
            display="flex"
            width="100%"
            flexGrow={1}
            height="100%"
            overflow={"hidden"}
          >
            <VersionsMenu
              versionsHistory={versionsHistory ?? []}
              onVersionSelected={(versionNumber) =>
                setSelectedVersion(versionNumber)
              }
              selectedVersion={displayedCourse?.version}
              lastPublishedVersion={lastPublishedVersion?.version}
            />
            <Box
              display="flex"
              width="100%"
              height="80vh"
              sx={{ minHeight: "100%", maxHeight: "100%" }}
              alignItems={isLoading ? "center" : "start"}
            >
              {isLoading && <CircularProgress />}
              {!isLoading && displayedCourse && (
                <Box
                  sx={{
                    display: "flex",
                    width: "80%",
                    height: "100%",
                    margin: "auto",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  {showRevertButton && revertButton}
                  <CoursePreview course={displayedCourse} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

const VersionsMenu = (props: {
  versionsHistory: CourseMetadataDto[]
  onVersionSelected: (versionNumber: number) => void
  selectedVersion: number | undefined
  lastPublishedVersion?: number
}) => {
  const {
    versionsHistory,
    onVersionSelected,
    selectedVersion,
    lastPublishedVersion,
  } = props

  const border = `1px solid ${appTheme.palette.other.outlineBorder}`

  return (
    <MenuList
      style={{
        minWidth: "320px",
        padding: 0,
        borderRight: border,
        height: "100%",
        overflow: "scroll",
      }}
    >
      {versionsHistory.map((version, index) => {
        return (
          <MenuItem
            key={version.id + version.version}
            onClick={(e) => {
              onVersionSelected(version.version)
            }}
            style={{
              borderBottom: border,
              padding: 0,
              backgroundColor:
                selectedVersion === version.version
                  ? appTheme.palette.action.selected
                  : "inherit",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              padding="24px"
            >
              <Box display="flex" flexDirection="column" textAlign="start">
                {version.version === lastPublishedVersion && (
                  <Box
                    padding="4px"
                    sx={{
                      backgroundColor: appTheme.palette.grey[300],
                      borderRadius: "16px",
                      width: "fit-content",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography variant="body3" padding="0 6px">
                      Published version
                    </Typography>
                  </Box>
                )}
                <Typography variant="body2">
                  {formatTimestamp(version.createdAtTimestamp)}
                </Typography>
                <Typography
                  variant="body3"
                  color="text.secondary"
                  maxWidth={"60px"}
                  noWrap
                  textOverflow={"ellipsis"}
                >
                  By {version.createdBy}
                </Typography>
              </Box>
              <SvgIcon
                component={appIcons.chevronDown}
                transform="rotate(270)"
                inheritViewBox
                sx={{
                  fill: "black",
                  width: "20px",
                  height: "20px",
                }}
              />
            </Box>
          </MenuItem>
        )
      })}
    </MenuList>
  )
}

function statusOfLastVersion(versions: CourseMetadataDto[] | undefined) {
  if (!versions || versions.length === 0) return undefined
  return versions[0].status
}

export default VersionHistoryPopup
