import OptionsButton from "@cmp/buttons/optionsButton"
import appIcons from "@utils/appIcons"

function SyllabusDescriptorMenu(props: {
  onOpenClicked?: () => void
  onEditClicked?: () => void
  onRenameClicked?: () => void
  onPreviewInCampusClicked?: () => void
  onDuplicateClicked?: () => void
  onArchiveClicked?: () => void
  onDeleteClicked?: () => void
}) {
  const {
    onOpenClicked,
    onEditClicked,
    onRenameClicked,
    onPreviewInCampusClicked,
    onDuplicateClicked,
    onArchiveClicked,
    onDeleteClicked,
  } = props

  const items = [
    onOpenClicked
      ? {
          text: "Open",
          onSelect: onOpenClicked,
        }
      : undefined,
    onEditClicked
      ? {
          text: "Edit",
          onSelect: onEditClicked,
        }
      : undefined,
    onRenameClicked
      ? {
          text: "Rename",
          onSelect: onRenameClicked,
        }
      : undefined,
    onPreviewInCampusClicked
      ? {
          text: "Preview in campus",
          onSelect: onPreviewInCampusClicked,
        }
      : undefined,
    onDuplicateClicked
      ? {
          text: "Duplicate",
          onSelect: onDuplicateClicked,
        }
      : undefined,
    onArchiveClicked
      ? {
          text: "Archive",
          onSelect: onArchiveClicked,
        }
      : undefined,
    onDeleteClicked
      ? {
          text: "Delete",
          onSelect: onDeleteClicked,
        }
      : undefined,
  ].filter(
    (item): item is { text: string; onSelect: () => void } =>
      item !== undefined,
  )

  return (
    <OptionsButton
      button={{
        sx: { padding: "2px" },
        leftIcon: appIcons.dots,
      }}
      items={items}
    />
  )
}

export default SyllabusDescriptorMenu
