import {
  Configuration,
  LoginApi,
  LoginRequest,
} from "@masterschool/course-builder-api"
import { User } from "../features/user/userSlice"

export class LoginClient {
  static client = new LoginApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  static login(loginRequest: LoginRequest) {
    return LoginClient.client
      .loginControllerLogin(loginRequest)
      .then((response) => {
        return response.data
      })
  }

  static getPermanentToken(
    temporaryToken: string,
  ): Promise<{ accessToken: string }> {
    const BASE_URL = import.meta.env.VITE_ADMISSIONS_BE_URL
    const requestInit = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${temporaryToken}`,
      },
    }
    return fetch(`${BASE_URL}/api/user/token`, requestInit).then((res) =>
      res.json(),
    )
  }

  static getUser(accessToken: string): Promise<User> {
    const BASE_URL = import.meta.env.VITE_ADMISSIONS_BE_URL
    const requestInit = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
    return fetch(
      `${BASE_URL}/api/user?withWorkspaceEmail=true`,
      requestInit,
    ).then((res) => res.json())
  }
}
