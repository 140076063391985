import { RootState } from "@app/store"
import { Middleware } from "redux"
import localStorageObserver from "./localStorageObserver"
import snackbarObserver from "../../main/snackbars/snackbarObserver"

const observers = [localStorageObserver, snackbarObserver]

const observersMiddleware: Middleware = (store) => (next) => (action) => {
  const prevState = store.getState() as RootState
  next(action)
  const nextState = store.getState() as RootState

  observers.forEach((observer) => {
    observer.didUpdate(prevState, nextState, action)
  })
}

export default observersMiddleware
