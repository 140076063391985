import { CircularProgress, Typography } from "@mui/material"

export const LoadingComponent = (props: { loadedComponents: string }) => {
  return (
    <>
      <CircularProgress color={"secondary"} />
      <Typography variant="body1_sb">
        Loading {props.loadedComponents}...
      </Typography>
    </>
  )
}
