import { Badge, Chip } from "@mui/material"

function ChipStatus({
  text,
  color,
  showIndicator,
}: {
  text: string
  color: "success" | "error" | undefined
  showIndicator: boolean
}) {
  const chip = <Chip label={text} variant="outlined" color={color} />

  return showIndicator ? (
    <Badge
      variant="dot"
      color="info"
      sx={{
        "& .MuiBadge-badge": {
          right: 12,
          top: 2,
        },
      }}
    >
      {chip}
    </Badge>
  ) : (
    chip
  )
}

export default ChipStatus
