import { Dialog } from "@mui/material"
import CoursePreview from "../../editor/coursePreview"
import { CourseDto } from "@masterschool/course-builder-api"
import { useAppDispatch } from "@app/hooks"
import { popupClosed } from "../../features/ui/uiSlice"

function CoursePreviewPopup(props: { course: CourseDto }) {
  const dispatch = useAppDispatch()

  return (
    <Dialog
      open={true}
      onClose={() => {
        dispatch(popupClosed())
      }}
      fullWidth
      maxWidth={"lg"}
      sx={{
        ".MuiDialog-paper": {
          width: "100%",
          height: "100%",
          margin: "0px",
          padding: "24px 48px",
        },
        boxShadow: "1px 1px 1px 1px rgba(0,0,0,1)",
      }}
    >
      <CoursePreview course={props.course} />
    </Dialog>
  )
}

export default CoursePreviewPopup
