import { useAppDispatch, useAppSelector } from "@app/hooks"
import FullScreenLoadingComponent from "@cmp/loaders/fullScreenLoadingComponent"
import { Box } from "@mui/material"
import { useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { fetchPrograms } from "../features/program/programSlice"
import {
  selectLastPublishedSyllabus,
  selectSyllabusWithVersion,
} from "../features/syllabus/syllabusSelectors"
import ActivateSyllabusPopup from "../main/popups/activateSyllabusPopup"
import { SyllabusView } from "./syllabusView"
import { SyllabusViewHeader } from "./syllabusViewHeader"

export function SyllabusViewContainer() {
  const { syllabusId } = useParams()
  const [searchParams] = useSearchParams()
  const version = searchParams.get("version")
  const navigate = useNavigate()
  const [showActivatePopup, setShowActivatePopup] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const publishedSyllabus = useAppSelector(
    selectLastPublishedSyllabus(syllabusId ?? ""),
  )
  const byVersion = useAppSelector(
    selectSyllabusWithVersion(
      syllabusId,
      version ? parseInt(version) : undefined,
    ),
  )

  const syllabus = version
    ? byVersion ?? publishedSyllabus
    : publishedSyllabus ?? byVersion

  if (!syllabus) {
    return <FullScreenLoadingComponent loadedComponents="" />
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <SyllabusViewHeader
        syllabus={syllabus}
        onEditClicked={() => navigate(`/syllabus-edit/${syllabusId}`)}
        onActivateClicked={() => setShowActivatePopup(true)}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          overflow: "hidden",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <SyllabusView syllabus={syllabus} />
      </Box>
      {showActivatePopup && (
        <ActivateSyllabusPopup
          syllabusId={syllabus.id}
          domain={syllabus.domain}
          onClose={() => {
            setShowActivatePopup(false)
            dispatch(fetchPrograms())
          }}
        />
      )}
    </Box>
  )
}
