import { useAppSelector } from "@app/hooks"
import { CourseSyllabusElement, Topic } from "@masterschool/course-builder-api"
import { SvgIconProps, SxProps } from "@mui/material"
import { Theme } from "@emotion/react"
import appIcons from "@utils/appIcons"
import { useSearchParams } from "react-router-dom"
import appTheme from "../../theme/appTheme"
import CourseElementDescriptor from "../courseElementDescriptor"
import { selectIsElementInvalid } from "../../features/courseEditor/courseValidationsSelectors"

function CourseSyllabusElementView(props: {
  element: CourseSyllabusElement
  topic: Topic
  index: number
  readonly?: boolean
  sx?: SxProps<Theme>
}) {
  const { element, topic, index, readonly, sx } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const isSelected = searchParams.get("elementId") === element.item.id
  const isInvalid = useAppSelector(selectIsElementInvalid(element))

  const borderColor = isSelected
    ? appTheme.palette.info.main
    : isInvalid
    ? appTheme.palette.error.main
    : appTheme.palette.other.outlineBorder

  const extraIcons: (SvgIconProps & { id: string })[] = []
  if (element.item.isHidden) {
    extraIcons.push({
      id: "hidden",
      component: appIcons.eyeOff,
    })
  }
  if (isInvalid) {
    extraIcons.push({
      id: "invalid",
      component: appIcons.alertCircle,
      sx: {
        stroke: appTheme.palette.error.main,
      },
    })
  }
  const isEditMode = readonly !== true

  const onClick = isEditMode
    ? () => {
        searchParams.set("elementId", element.item.id)
        setSearchParams(searchParams, {
          replace: true,
        })
      }
    : () => {
        return
      }

  return (
    <CourseElementDescriptor
      key={element.item.id}
      index={index}
      topic={topic}
      element={element}
      editMode={isEditMode}
      onClick={onClick}
      sx={{
        backgroundColor: isSelected ? "#2196F314" : appTheme.palette.grey["50"],
        borderColor,
        ...sx,
      }}
      leftIcon={isEditMode ? appIcons.dotsGrid : undefined}
      leftIconSx={{
        cursor: "grab",
      }}
      extraIcons={extraIcons}
      extraText={element.item.isExtraCurricular ? "Advanced" : undefined}
    />
  )
}

export default CourseSyllabusElementView
