import {
  Box,
  Button,
  Card,
  Stack,
  SvgIcon,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../theme/appTheme"
import { useAppSelector } from "@app/hooks"
import { selectIsLoggedIn } from "../features/user/userSlice"

export function Login(props: { redirectUrl?: string | undefined }) {
  const redirectUrl = props.redirectUrl
  const theme = useTheme()
  const masterschoolCampusUrl = import.meta.env.VITE_ADMISSIONS_BE_URL
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  console.log("Login/isLoggedIn", isLoggedIn)
  console.log("masterschoolCampusUrl", masterschoolCampusUrl)

  const redirectUrlParam = redirectUrl ? `&redirectUrl=${redirectUrl}` : ""
  const googleLoginUrl = `${masterschoolCampusUrl}/api/google/login?appName=lms${redirectUrlParam}`
  console.log("googleLoginUrl", googleLoginUrl)

  return (
    <ThemeProvider theme={appTheme}>
      <Box
        display="flex"
        alignItems="center"
        gap="32px"
        flexDirection="column"
        sx={{
          paddingTop: "calc(100vh / 6)",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          backgroundColor: appTheme.palette.background.default,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="8px"
        >
          <SvgIcon
            component={appIcons.masterschoolLms}
            inheritViewBox
            sx={{
              width: "340px",
              height: "90px",
            }}
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          gap="40px"
          alignItems="center"
        >
          <Card
            component={Stack}
            spacing={3}
            p={6}
            width="400px"
            sx={{
              backgroundColor: "primary.contrast",
              border: "1px solid",
              borderColor: "other.outlineBorder",
              boxShadow: "none",
              borderRadius: "8px",
              [theme.breakpoints.down("sm")]: {
                px: 4,
              },
            }}
          >
            <Typography variant="h3" textAlign="center">
              Welcome Back!
            </Typography>
            <Typography variant="body2" textAlign="center">
              Please login with your Masterschool employee account to access the
              LMS.
            </Typography>
            <Button
              onClick={() => (window.location.href = googleLoginUrl)}
              variant="outlined"
              startIcon={
                <SvgIcon
                  component={appIcons.googleIcon}
                  sx={{ width: 20, height: 20 }}
                />
              }
              sx={{
                borderColor: "primary.main",
              }}
            >
              <Typography variant="body1">Login with Google</Typography>
            </Button>
          </Card>
        </Box>
        <Typography variant="body3">
          <i>"The best LMS Masterschool has ever built."</i>
          <br /> - Shirin
        </Typography>
      </Box>
    </ThemeProvider>
  )
}
