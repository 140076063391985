import { LoadingButton, LoadingButtonProps } from "@mui/lab"
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SvgIcon,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../theme/appTheme"

type SimpleDialogProps = {
  title: string
  content: string
  onClose: () => void
  buttons: (ButtonProps | LoadingButtonProps)[]
}

function SimpleDialog(props: SimpleDialogProps) {
  return (
    <Dialog
      open
      onClose={props.onClose}
      maxWidth="xs"
      sx={{
        ".MuiDialog-paper": {
          borderRadius: "8px",
          width: "388px",
          padding: "24px",
        },
        boxShadow: "1px 1px 1px 1px rgba(0,0,0,1)",
      }}
    >
      <DialogActions
        disableSpacing
        sx={{
          padding: "0px",
          width: "100%",
          gap: "16px",
        }}
      >
        <IconButton
          onClick={props.onClose}
          sx={{
            padding: "0px",
          }}
        >
          <SvgIcon
            inheritViewBox
            component={appIcons.xClose}
            sx={{
              color: appTheme.palette.text.secondary,
            }}
          />
        </IconButton>
      </DialogActions>
      <DialogTitle
        sx={{
          padding: "0px",
        }}
      >
        {props.title}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0px",
          mt: "8px",
        }}
      >
        <DialogContentText
          variant="body1"
          sx={{
            color: appTheme.palette.text.primary,
          }}
        >
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        disableSpacing
        sx={{
          padding: "0px",
          width: "100%",
          gap: "16px",
          mt: "48px",
        }}
      >
        {props.buttons.map((buttonProps, index) => {
          if (isLoadingButtonProps(buttonProps)) {
            return (
              <LoadingButton
                key={index}
                {...buttonProps}
                loading={buttonProps.loading}
              >
                {buttonProps.children}
              </LoadingButton>
            )
          } else {
            return (
              <Button key={index} {...buttonProps}>
                {buttonProps.children}
              </Button>
            )
          }
        })}
      </DialogActions>
    </Dialog>
  )
}

function isLoadingButtonProps(
  props: ButtonProps | LoadingButtonProps,
): props is LoadingButtonProps {
  return "loading" in props
}

export default SimpleDialog
