import { useAppDispatch, useAppSelector } from "@app/hooks"
import CloseIcon from "@mui/icons-material/Close"
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { selectActiveCourse } from "../../features/courseEditor/courseEditorSelectors"
import {
  unpublishedChangesPopupClosed,
  validateCourse,
} from "../../features/courseEditor/courseEditorSlice"

function UnpublishedChangesPopup() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { syllabusId } = useParams()
  const course = useAppSelector(selectActiveCourse)

  if (!course) {
    return null
  }

  return (
    <Dialog
      open={true}
      onClose={() => dispatch(unpublishedChangesPopupClosed())}
    >
      <DialogTitle sx={{ padding: "48px 0px 0px" }}>
        <IconButton
          aria-label="close"
          onClick={() => dispatch(unpublishedChangesPopupClosed())}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
            width: "24px",
            height: "24px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",

          gap: "8px",
          width: "388px",
        }}
      >
        <Typography variant="body1_sb">Publish changes?</Typography>
        <Typography variant="body1">
          Your changes were saved but have not been published yet.
        </Typography>

        <Box
          display="flex"
          width="100%"
          justifyContent="end"
          gap="16px"
          paddingTop="40px"
        >
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              if (syllabusId) {
                navigate(`/syllabus-edit/${syllabusId}`)
              } else {
                navigate("/courses")
              }
            }}
          >
            Publish later
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              dispatch(validateCourse())
            }}
          >
            Publish now
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UnpublishedChangesPopup
