import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../../../theme/appTheme"
import StaleCourseCard from "./staleCourseCard"
import StaleCourseModel from "./staleCourseModel"

function MultiStaleCoursesPopup(props: {
  staleCourses: StaleCourseModel[]
  onClose: () => void
  onView: (staleCourse: StaleCourseModel) => void
  onUpdate: (staleCourse: StaleCourseModel) => void
  onUpdateAll: () => void
}) {
  const { staleCourses, onClose, onView, onUpdate, onUpdateAll } = props

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogActions
        disableSpacing
        sx={{
          padding: "16px 16px 0px 16px",
          width: "100%",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            padding: "0px",
          }}
        >
          <SvgIcon
            inheritViewBox
            component={appIcons.xClose}
            sx={{
              color: appTheme.palette.text.secondary,
              stroke: appTheme.palette.icon.black,
            }}
          />
        </IconButton>
      </DialogActions>
      <DialogTitle variant="body1_sb">
        Update to latest course versions?
        <Typography variant="body1" mt="8px">
          Course changes may impact the unit structure.
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0px 0px 0px 0px",
        }}
      >
        {staleCourses.flatMap((staleCourse, index) => (
          <StaleCourseCard
            key={staleCourse.courseId}
            staleCourse={staleCourse}
            onViewClicked={() => onView(staleCourse)}
            onUpdateClicked={() => onUpdate(staleCourse)}
            sx={{
              borderBottom:
                index === staleCourses.length - 1
                  ? `1px solid ${appTheme.palette.other.divider}`
                  : undefined,
            }}
          />
        ))}
      </DialogContent>
      <DialogActions
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{
            width: "50%",
          }}
          onClick={onClose}
        >
          Keep current version
        </Button>
        <LoadingButton
          variant="contained"
          size="small"
          sx={{
            width: "50%",
          }}
          onClick={onUpdateAll}
        >
          Update all
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default MultiStaleCoursesPopup
