import { CourseSyllabusElementTypeEnum } from "@masterschool/course-builder-api"
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"

function ElementTypeSelection(props: {
  type: CourseSyllabusElementTypeEnum
  isInvalid: boolean
  allowedTypes: CourseSyllabusElementTypeEnum[]
  onChange: (type: CourseSyllabusElementTypeEnum) => void
}) {
  const { type, isInvalid, allowedTypes, onChange } = props

  return (
    <FormControl
      sx={{
        minWidth: "180px",
      }}
      size="small"
    >
      <InputLabel
        sx={{
          fontSize: "14px",
        }}
      >
        Provider
      </InputLabel>
      <Select
        label="Provider"
        value={elementTypePresentationName(type)}
        onChange={(e) => {
          const newType = providerTypeToElementType(e.target.value)
          if (newType) {
            onChange(newType)
          }
        }}
      >
        {allowedTypes.map((providerType) => (
          <MenuItem
            value={elementTypePresentationName(providerType)}
            key={providerType}
          >
            {elementTypePresentationName(providerType)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText
        error={isInvalid}
        variant="standard"
        sx={{
          paddingLeft: "14px",
          textTransform: "none",
          letterSpacing: "0px",
        }}
      >
        {isInvalid ? "Choose a provider" : ""}
      </FormHelperText>
    </FormControl>
  )
}

const elementTypePresentationName = (
  type: CourseSyllabusElementTypeEnum,
): string | undefined => {
  switch (type) {
    case CourseSyllabusElementTypeEnum.DatacampLesson:
      return "Datacamp"
    case CourseSyllabusElementTypeEnum.EmptyLesson:
    case CourseSyllabusElementTypeEnum.EmptyProject:
      return ""
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
      return "Masterschool"
    case CourseSyllabusElementTypeEnum.NotionLesson:
      return "Notion"
    case CourseSyllabusElementTypeEnum.OtherLesson:
      return "Other"
    case CourseSyllabusElementTypeEnum.CourseraLesson:
      return "Coursera"
    case CourseSyllabusElementTypeEnum.TryHackmeLesson:
      return "TryHackme"
    case CourseSyllabusElementTypeEnum.TypeformLesson:
      return "Typeform"
    case CourseSyllabusElementTypeEnum.UdacityLesson:
      return "Udacity"
    case CourseSyllabusElementTypeEnum.RunestoneLesson:
      return "Runestone"
    case CourseSyllabusElementTypeEnum.CodioProject:
      return "Codio"
  }
}

const providerTypeToElementType = (
  providerType: string,
): CourseSyllabusElementTypeEnum | undefined => {
  switch (providerType) {
    case "Datacamp":
      return CourseSyllabusElementTypeEnum.DatacampLesson
    case "Masterschool":
      return CourseSyllabusElementTypeEnum.MasterschoolLesson
    case "Coursera":
      return CourseSyllabusElementTypeEnum.CourseraLesson
    case "Notion":
      return CourseSyllabusElementTypeEnum.NotionLesson
    case "Other":
      return CourseSyllabusElementTypeEnum.OtherLesson
    case "TryHackme":
      return CourseSyllabusElementTypeEnum.TryHackmeLesson
    case "Typeform":
      return CourseSyllabusElementTypeEnum.TypeformLesson
    case "Udacity":
      return CourseSyllabusElementTypeEnum.UdacityLesson
    case "Runestone":
      return CourseSyllabusElementTypeEnum.RunestoneLesson
    case "Codio":
      return CourseSyllabusElementTypeEnum.CodioProject
  }
}

export default ElementTypeSelection
