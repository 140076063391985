import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles"

const MuiChipOverrides: {
  defaultProps?: ComponentsProps["MuiChip"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiChip"]
  variants?: ComponentsVariants["MuiChip"]
} = {
  styleOverrides: {
    label: ({ theme }) => ({
      ...theme.typography.body3,
    }),
  },
  variants: [
    {
      props: { size: "small" },
      style: {
        padding: "3px 4px",
        "& .MuiChip-label": {
          padding: "0 6px",
        },
      },
    },
    {
      props: { size: "medium" },
      style: {
        padding: "4px",
        "& .MuiChip-label": {
          padding: "3px 6px",
        },
      },
    },
    {
      props: { variant: "outlined", color: "success" },
      style: ({ theme }) => ({
        borderColor: theme.palette.success.outlinedRestingBorder,
      }),
    },
    {
      props: { variant: "outlined", color: "error" },
      style: ({ theme }) => ({
        borderColor: theme.palette.error.outlinedRestingBorder,
      }),
    },
    {
      props: { variant: "outlined", color: "warning" },
      style: ({ theme }) => ({
        borderColor: theme.palette.warning.outlinedRestingBorder,
      }),
    },
    {
      props: { variant: "outlined", color: "default" },
      style: ({ theme }) => ({
        borderColor: theme.palette.other.outlineBorder,
      }),
    },
  ],
}

export { MuiChipOverrides }
