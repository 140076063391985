import {
  CourseMetadataDto,
  CourseMetadataDtoStatusEnum,
} from "@masterschool/course-builder-api"
import { useEffect, useState } from "react"
import { CourseClient } from "../../../../clients/courseClient"

function useCourseVersionHistory(courseId: string) {
  const [versionsHistory, setVersionsHistory] = useState<
    CourseMetadataDto[] | "pending" | "rejected"
  >("pending")

  useEffect(() => {
    CourseClient.listCourseVersions(courseId)
      .then((versions) => {
        const sorted = versions
          .sort((a, b) => b.version - a.version)
          .filter((c) => c.status === CourseMetadataDtoStatusEnum.Published)
        setVersionsHistory(sorted)
      })
      .catch(() => {
        setVersionsHistory("rejected")
      })
  }, [courseId])

  return versionsHistory
}

export default useCourseVersionHistory
