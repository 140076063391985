import { useAppDispatch, useAppSelector } from "@app/hooks"
import BackButton from "@cmp/buttons/backButton"
import OptionsButton from "@cmp/buttons/optionsButton"
import { SelectedStatusChip } from "@cmp/syllabusStatusChip"
import { LoadingButton } from "@mui/lab"
import { Box, Button, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useNavigate } from "react-router-dom"
import { selectIsSyllabusPublishable } from "../../features/syllabus/syllabusSelectors"
import {
  archiveSyllabus,
  saveSyllabus,
} from "../../features/syllabusEditor/syllabusEditorSlice"
import { PopupType, popupItemClicked } from "../../features/ui/uiSlice"
import appTheme from "../../theme/appTheme"
import useSelectedSyllabus from "@cmp/hooks/useSelectedSyllabus"

export function SyllabusEditorHeader(props: {
  onPublishChangesClicked: () => void
}) {
  const { onPublishChangesClicked } = props
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const draftAndLastPublishedVersions = useSelectedSyllabus()

  const selectedSyllabus =
    draftAndLastPublishedVersions.lastPublishedVersion ??
    draftAndLastPublishedVersions.draftVersion

  const canPublish = useAppSelector(
    selectIsSyllabusPublishable(selectedSyllabus?.id ?? ""),
  )

  if (!selectedSyllabus) return null

  const OptionsMenu = () => {
    const items = [
      {
        text: "Archive",
        onSelect: () => {
          dispatch(archiveSyllabus({ syllabusId: selectedSyllabus.id }))
        },
      },
      {
        text: "Show Campus JSON",
        onSelect: () => {
          dispatch(saveSyllabus()).then(() => {
            dispatch(
              popupItemClicked({
                type: PopupType.CampusSyllabusJSONPreview,
                syllabusId: selectedSyllabus.id,
              }),
            )
          })
        },
      },
    ]

    return (
      <OptionsButton
        button={{
          sx: {
            padding: "3px",
            border: `1px solid ${appTheme.palette.other.outlineBorder}`,
          },
          leftIcon: appIcons.dots,
        }}
        items={items}
      />
    )
  }

  const onPreviewInCampusClicked = () => {
    if (!selectedSyllabus) return
    dispatch(saveSyllabus()).then(() => {
      dispatch(
        popupItemClicked({
          type: PopupType.CampusSyllabusPreview,
          syllabus: selectedSyllabus,
        }),
      )
    })
  }

  const { name, status, isActive } = selectedSyllabus

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "20px 16px",
        width: "100%",
        borderBottom: `1px solid ${appTheme.palette.divider}`,
      }}
    >
      <Box width="33%">
        <BackButton
          onClick={() => {
            navigate("/syllabuses")
          }}
        />
      </Box>

      <Box display="flex" gap="8px" justifyContent="center" width="34%">
        <Typography
          variant="body1"
          color={name.length > 0 ? "text.primary" : "text.disabled"}
        >
          {name.length > 0 ? name : "Unnamed syllabus"}
        </Typography>

        {status && (
          <SelectedStatusChip
            syllabusId={selectedSyllabus.id}
            status={selectedSyllabus.status}
            isActive={isActive}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
        width="33%"
      >
        <OptionsMenu />
        <Button
          onClick={onPreviewInCampusClicked}
          variant="outlined"
          size="small"
        >
          Preview
        </Button>

        <LoadingButton
          loading={false}
          onClick={onPublishChangesClicked}
          variant="contained"
          disabled={!canPublish}
          size="small"
        >
          {selectedSyllabus.version > 1 ? "Publish changes" : "Publish"}
        </LoadingButton>
      </Box>
    </Box>
  )
}
