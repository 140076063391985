import { useAppDispatch } from "@app/hooks"
import SimpleDialog from "@cmp/simpleDialog"
import { useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  discardDraftConfirmationClosed,
  discardDraftConfirmed,
} from "../../features/courseEditor/courseEditorSlice"

function DiscardDraftConfirmationPopup() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { courseId } = useParams()
  const [searchParams] = useSearchParams()
  const version = parseInt(searchParams.get("version") ?? "2")
  const previousVersion = version - 1
  const [isLoading, setIsLoading] = useState(false)

  const title = "Revert to published version?"
  const content = "This will delete any changes you’ve made to this course."

  return (
    <SimpleDialog
      title={title}
      content={content}
      onClose={() => dispatch(discardDraftConfirmationClosed())}
      buttons={[
        {
          children: "Cancel",
          variant: "outlined",
          size: "small",
          onClick: () => dispatch(discardDraftConfirmationClosed()),
        },
        {
          children: "Revert",
          variant: "contained",
          loading: isLoading,
          size: "small",
          onClick: () => {
            setIsLoading(true)
            dispatch(discardDraftConfirmed()).then(() =>
              navigate(`/course/${courseId}?version=${previousVersion}`),
            )
          },
        },
      ]}
    />
  )
}

export default DiscardDraftConfirmationPopup
