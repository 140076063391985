import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SvgIcon,
  TextField,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import appTheme from "../../theme/appTheme"

export const RenameSyllabusPopup = (props: {
  name: string
  onSave: (syllabusName: string) => void
  onClose: () => void
}) => {
  const { name, onSave, onClose } = props

  const [syllabusName, setSyllabusName] = useState<string>(name)

  const onSaveClicked = () => {
    onSave(syllabusName)
    onClose()
  }

  return (
    <Dialog
      onClick={(e) => {
        e.stopPropagation()
      }}
      onClose={onClose}
      open={true}
      PaperProps={{
        sx: {
          width: "388px",
          borderRadius: "8px",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          textAlign: "center",
          maxHeight: "100%",
          padding: "24px",
        }}
      >
        <Box display="flex" justifyContent="end">
          <IconButton
            sx={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
          >
            <SvgIcon
              component={appIcons.xClose}
              inheritViewBox
              sx={{
                stroke: appTheme.palette.icon.black,
              }}
            />
          </IconButton>
        </Box>
        <DialogTitle
          variant="body1_sb"
          sx={{
            textAlign: "left",
            fontSize: "16px",
            lineHeight: "24px",
            padding: 0,
          }}
        >
          Rename syllabus
        </DialogTitle>
        <DialogContentText
          id="alert-dialog-description"
          component="div"
          marginTop="24px"
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <Box>
              <TextField
                label="Syllabus name"
                defaultValue={syllabusName}
                onChange={(event) => {
                  setSyllabusName(event.target.value)
                }}
                placeholder="Syllabus name"
                multiline
                fullWidth
              />
            </Box>
            <Box display="flex" gap="8px" marginTop="8px" justifyContent="end">
              <Button
                onClick={onClose}
                variant="outlined"
                size="small"
                style={{
                  width: "fit-content",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={onSaveClicked}
                variant="outlined"
                size="small"
                disabled={false}
                style={{
                  width: "fit-content",
                  fontSize: "14px",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
