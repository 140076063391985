import { Box, Button, Typography } from "@mui/material"
import { useAppSelector } from "../app/hooks"
import CourseViewStatus from "@cmp/courseViewStatus"
import BackButton from "@cmp/buttons/backButton"
import { useNavigate } from "react-router-dom"
import {
  CourseDto,
  CourseDtoStatusEnum,
} from "@masterschool/course-builder-api"
import appTheme from "../theme/appTheme"
import CourseDescriptorMenu from "../main/descriptor/courseDescriptorMenu"
import { useEditCourseClicked } from "./custom-hooks/useEditCourseClicked"
import {
  courseSyllabusDuration,
  durationFormatting,
} from "@utils/syllabus+duration"

export function CourseHeader() {
  const course = useAppSelector((state) => state.courseView.course)

  if (!course) return <div>Loading...</div>

  return <CourseHeaderView course={course} />
}

function CourseHeaderView(props: { course: CourseDto }) {
  const { course } = props
  const navigate = useNavigate()
  const onEditClicked = useEditCourseClicked()

  const hasTitle = course.title && course.title?.length > 0

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px 16px",
        borderBottom: `2px solid ${appTheme.palette.other.divider}`,
      }}
    >
      <Box width="33%">
        <BackButton
          onClick={() => {
            navigate("/courses")
          }}
        />
      </Box>
      <Box
        width="34%"
        justifyContent="center"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Typography
          variant="body1"
          color={hasTitle ? "text.primary" : "text.disabled"}
        >
          {hasTitle ? course.title : "Unnamed course"}
          <span
            style={{
              color: appTheme.palette.text.disabled,
            }}
          >
            {` (${durationFormatting(
              courseSyllabusDuration(course.syllabus),
            )})`}
          </span>
        </Typography>
        <CourseViewStatus course={course} />
      </Box>
      <Box
        width="33%"
        justifyContent="end"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <CourseDescriptorMenu
          course={course}
          sx={{
            border: `1px solid ${appTheme.palette.other.outlineBorder}`,
            borderRadius: "2px",
            padding: "3px",
          }}
          iconSx={{
            width: "20px",
            height: "20px",
          }}
        />
        {course.status !== CourseDtoStatusEnum.Archived && (
          <Button
            onClick={() => {
              onEditClicked(course)
            }}
            variant="outlined"
            size="small"
          >
            Edit
          </Button>
        )}
      </Box>
    </Box>
  )
}
