import { useAppSelector } from "@app/hooks"
import { CourseDto } from "@masterschool/course-builder-api"
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material"
import { useState } from "react"
import { selectShowCourseInvalidErrorForField } from "../features/courseEditor/courseEditorSelectors"
import { DomainsEnumeration } from "../features/search/searchSlice"
import EditorTextField from "./components/editorTextField"
import { MultipleTagsSelectionInput } from "./components/multipleTagsSelectionInput"
import useUpdateCourse from "./custom-hooks/useUpdateCourse"

function CourseEditorGeneralInfo(props: { course: CourseDto }) {
  const updateCourse = useUpdateCourse()
  const showInvalidNameError = useAppSelector(
    selectShowCourseInvalidErrorForField("name"),
  )
  const showInvalidTitleError = useAppSelector(
    selectShowCourseInvalidErrorForField("title"),
  )
  const { course } = props

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
      }}
    >
      <Typography variant="body2" color="text.secondary" sx={{ mb: "8px" }}>
        Fill in all required fields to publish your course.
      </Typography>
      <EditorTextField
        label="Internal name"
        value={course.name}
        debounce
        onChange={(e) => {
          updateCourse("name", e.target.value)
        }}
        error={showInvalidNameError}
        helperText={showInvalidNameError ? "This field is required" : ""}
      />
      <EditorTextField
        label="Display name"
        value={course.title ?? ""}
        debounce
        onChange={(e) => {
          updateCourse("title", e.target.value)
        }}
        error={showInvalidTitleError}
        helperText={showInvalidTitleError ? "This field is required" : ""}
      />
      <MultipleTagsSelectionForm
        fieldName="Programs"
        selectedTags={course.domains}
        onEdit={(tags: string[]) => updateCourse("domains", tags)}
      />
      <TextField
        defaultValue={course.description}
        onChange={(event) => {
          updateCourse("description", event.target.value)
        }}
        rows={4}
        multiline
        fullWidth
        label="Course description (optional)"
        size="small"
        InputProps={{ sx: { fontSize: "14px", padding: "16px" } }}
        InputLabelProps={{ sx: { fontSize: "14px" } }}
      />
    </Box>
  )
}

export default CourseEditorGeneralInfo

function MultipleTagsSelectionForm(props: {
  fieldName: string
  selectedTags: string[]
  onEdit: (tags: string[]) => void
}) {
  const { fieldName, selectedTags, onEdit } = props

  const [isFirstEdit, setIsFirstEdit] = useState(true)
  const isValid = selectedTags.length > 0 || isFirstEdit

  return (
    <FormControl>
      <MultipleTagsSelectionInput
        options={DomainsEnumeration}
        selectedTags={selectedTags}
        onChange={(domains) => {
          setIsFirstEdit(false)
          onEdit(domains)
        }}
        title={fieldName}
        placeholder="Select domains"
        error={!isValid}
      />
      <FormHelperText
        error={!isValid}
        variant="standard"
        sx={{
          paddingLeft: "14px",
          textTransform: "none",
          letterSpacing: "0px",
        }}
      >
        {isValid ? "" : `${fieldName} are mandatory`}
      </FormHelperText>
    </FormControl>
  )
}
