import { CourseState } from "../../features/coursesMenu/coursesMenuSlice"

export enum SortOrder {
  Ascending = "ascending",
  Descending = "descending",
}

export enum SortType {
  Chronological = "chronological",
  Alphabetic = "alphabetic",
}

export type SortOption = {
  type: SortType
  order: SortOrder
}

export type CourseSortFunction = (
  courses: CourseState[],
  sortOrder: SortOrder,
) => CourseState[]

export type CourseComparator = (a: CourseState, b: CourseState) => -1 | 0 | 1

export const alphabeticCoursesSort: CourseSortFunction = (
  courses,
  sortOrder,
) => {
  const sortOrderSign = sortOrder === SortOrder.Ascending ? 1 : -1
  return courses.sort((a, b) => sortOrderSign * alphabeticComparator(a, b))
}

export const chronologicalCoursesSort: CourseSortFunction = (
  courses,
  sortOrder,
) => {
  const sortOrderSign = sortOrder === SortOrder.Ascending ? 1 : -1
  return courses.sort((a, b) => sortOrderSign * chronologicalComparator(a, b))
}

const alphabeticComparator: CourseComparator = (a, b) => {
  const nameA = a.name.toLowerCase()
  const nameB = b.name.toLowerCase()

  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

const chronologicalComparator: CourseComparator = (a, b) => {
  if (a.createdAtTimestamp < b.createdAtTimestamp) {
    return -1
  }
  if (a.createdAtTimestamp > b.createdAtTimestamp) {
    return 1
  }
  return 0
}
