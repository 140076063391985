import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { useAppSelector } from "@app/hooks"
import { selectActiveCourse } from "../features/courseEditor/courseEditorSelectors"
import {
  CourseSyllabusElement,
  CourseSyllabusElementTypeEnum,
  Topic,
} from "@masterschool/course-builder-api"
import MasterschoolLessonEditor from "./elements/lesson/masterschoolLessonEditor"
import SurveyEditor from "./elements/survey/surveyEditor"
import TestEditor from "./elements/test/testEditor"
import NotionLessonEditor from "./elements/lesson/notionLessonEditor"
import ElementEditorHeader from "./elements/elementEditorHeader"
import EditLessonType from "./elements/lesson/EditLessonType"
import DatacampLessonEditor from "./elements/lesson/datacampLessonEditor"
import TryHackmeLessonEditor from "./elements/lesson/tryHackmeLessonEditor"
import OtherLessonEditor from "./elements/lesson/otherLessonEditor"
import UdacityLessonEditor from "./elements/lesson/udacityLessonEditor"
import useEditElement from "./elements/custom-hooks/useEditElement"
import EmptyLessonEditor from "./elements/lesson/emptyLessonEditor"
import RunestoneLessonEditor from "./elements/lesson/runestoneLessonEditor"
import EmptyProjectEditor from "./elements/project/emptyProjectEditor"
import CodioProjectEditor from "./elements/project/codioProjectEditor"

function CourseItemEditor() {
  const [searchParams] = useSearchParams()
  const course = useAppSelector(selectActiveCourse)
  const elementId = searchParams.get("elementId")

  if (!elementId || !course) {
    return null
  }

  const topic = course.syllabus.topics.find((t) =>
    t.elements.some((e) => e.item.id === elementId),
  )
  const element = topic?.elements.find((e) => e.item.id === elementId)

  if (!topic || !element) {
    return <Typography>Item not found</Typography>
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ElementEditorHeader topic={topic} />
          <EditorBody element={element} topic={topic} />
        </Box>
        <Box
          sx={{
            height: "5%",
            width: "100%",
            marginLeft: "24px",
            marginBottom: "12px",
          }}
        >
          <ElementCheckboxes element={element} topic={topic} />
        </Box>
      </Box>
    </Box>
  )
}

function EditorBody({
  element,
  topic,
}: {
  element: CourseSyllabusElement
  topic: Topic
}) {
  switch (element.type) {
    case CourseSyllabusElementTypeEnum.DatacampLesson:
      return <DatacampLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.EmptyLesson:
      return <EmptyLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
      return <MasterschoolLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.NotionLesson:
      return <NotionLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.OtherLesson:
      return <OtherLessonEditor lessonType="other" topic={topic} />
    case CourseSyllabusElementTypeEnum.CourseraLesson:
      return <OtherLessonEditor lessonType="coursera" topic={topic} />
    case CourseSyllabusElementTypeEnum.TryHackmeLesson:
      return <TryHackmeLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.UdacityLesson:
      return <UdacityLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.RunestoneLesson:
      return <RunestoneLessonEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.EmptyProject:
      return <EmptyProjectEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.CodioProject:
      return <CodioProjectEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.Survey:
      return <SurveyEditor topic={topic} />
    case CourseSyllabusElementTypeEnum.Test:
      return <TestEditor topic={topic} />
    default:
      return (
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <EditLessonType topic={topic} />
          <Typography>Unsupported item type {element.type}</Typography>
        </Box>
      )
  }
}

function ElementCheckboxes(props: {
  element: CourseSyllabusElement
  topic: Topic
}) {
  const { element, topic } = props
  const editElement = useEditElement()
  const canMarkIsMandatory = canBeMarkedAsMandatory(props.element)

  if (!canMarkIsMandatory) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={element.item.isExtraCurricular}
            onChange={() => {
              editElement(
                {
                  ...element,
                  item: {
                    ...element.item,
                    isExtraCurricular: !element.item.isExtraCurricular,
                  },
                },
                topic,
              )
            }}
          />
        }
        label="Advanced learning"
      />
    )
  }

  const item = element.item as { isMandatory: boolean }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={element.item.isExtraCurricular}
            onChange={() => {
              const newIsExtraCurricularValue = !element.item.isExtraCurricular
              editElement(
                {
                  ...element,
                  item: {
                    ...element.item,
                    isExtraCurricular: newIsExtraCurricularValue,
                    isMandatory: newIsExtraCurricularValue
                      ? false
                      : item.isMandatory,
                  },
                },
                topic,
              )
            }}
          />
        }
        label="Advanced learning"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={item.isMandatory}
            onChange={() => {
              const newIsMandatoryValue = !item.isMandatory
              editElement(
                {
                  ...element,
                  item: {
                    ...element.item,
                    isExtraCurricular: newIsMandatoryValue
                      ? false
                      : element.item.isExtraCurricular,
                    isMandatory: newIsMandatoryValue,
                  },
                },
                topic,
              )
            }}
          />
        }
        label="Mandatory"
      />
    </Box>
  )
}

function canBeMarkedAsMandatory(
  element: CourseSyllabusElement,
): element is CourseSyllabusElement & { item: { isMandatory: boolean } } {
  return element.item.hasOwnProperty("isMandatory")
}

export default CourseItemEditor
