import AlertCircleIcon from "@icons/alert-circle.svg?react"
import ArrowLeftIcon from "@icons/arrow-left.svg?react"
import ArrowRightIcon from "@icons/arrow-right.svg?react"
import Atom02Icon from "@icons/atom-02.svg?react"
import BookOpen01Icon from "@icons/book-open-01.svg?react"
import CheckIcon from "@icons/check.svg?react"
import ChevronDownIcon from "@icons/chevron-down.svg?react"
import ClockIcon from "@icons/clock.svg?react"
import CodeSquare01Icon from "@icons/code-square-01.svg?react"
import CourseFilledIcon from "@icons/course-filled.svg?react"
import CourseIcon from "@icons/course.svg?react"
import DotsGridIcon from "@icons/dots-grid.svg?react"
import DotsIcon from "@icons/dots.svg?react"
import Edit02Icon from "@icons/edit-02.svg?react"
import Edit05Icon from "@icons/edit-05.svg?react"
import EyeOffIcon from "@icons/eye-off.svg?react"
import EyeIcon from "@icons/eye.svg?react"
import File02Icon from "@icons/file-02.svg?react"
import FunnelIcon from "@icons/funnel.svg?react"
import GoogleIconIcon from "@icons/google-icon.svg?react"
import InfoCircleIcon from "@icons/info-circle.svg?react"
import MasterschoolLmsIcon from "@icons/masterschool-lms.svg?react"
import MinusIcon from "@icons/minus.svg?react"
import PencilLineIcon from "@icons/pencil-line.svg?react"
import PlaceholderIcon from "@icons/placeholder.svg?react"
import PlusCircleIcon from "@icons/plus-circle.svg?react"
import PlusIcon from "@icons/plus.svg?react"
import RefreshCcwIcon from "@icons/refresh-ccw.svg?react"
import RemoveIconIcon from "@icons/remove-icon.svg?react"
import ReverseLeftIcon from "@icons/reverse-left.svg?react"
import SearchSmIcon from "@icons/search-sm.svg?react"
import SplitSprintIcon from "@icons/split-sprint.svg?react"
import Star01Icon from "@icons/star-01.svg?react"
import SyllabusesFilledIcon from "@icons/syllabuses-filled.svg?react"
import SyllabusesIcon from "@icons/syllabuses.svg?react"
import Trash03Icon from "@icons/trash-03.svg?react"
import XCloseIcon from "@icons/x-close.svg?react"

const appIcons = {
  alertCircle: AlertCircleIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  atom02: Atom02Icon,
  bookOpen01: BookOpen01Icon,
  check: CheckIcon,
  chevronDown: ChevronDownIcon,
  clock: ClockIcon,
  codeSquare01: CodeSquare01Icon,
  courseFilled: CourseFilledIcon,
  course: CourseIcon,
  dotsGrid: DotsGridIcon,
  dots: DotsIcon,
  edit02: Edit02Icon,
  edit05: Edit05Icon,
  eyeOff: EyeOffIcon,
  eye: EyeIcon,
  file02: File02Icon,
  funnel: FunnelIcon,
  googleIcon: GoogleIconIcon,
  infoCircle: InfoCircleIcon,
  masterschoolLms: MasterschoolLmsIcon,
  minus: MinusIcon,
  pencilLine: PencilLineIcon,
  placeholder: PlaceholderIcon,
  plusCircle: PlusCircleIcon,
  plus: PlusIcon,
  refreshCcw: RefreshCcwIcon,
  removeIcon: RemoveIconIcon,
  reverseLeft: ReverseLeftIcon,
  searchSm: SearchSmIcon,
  splitSprint: SplitSprintIcon,
  star01: Star01Icon,
  syllabusesFilled: SyllabusesFilledIcon,
  syllabuses: SyllabusesIcon,
  trash03: Trash03Icon,
  xClose: XCloseIcon,
}

export default appIcons
