import { useAppDispatch, useAppSelector } from "@app/hooks"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Link,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { selectActiveCourse } from "../../features/courseEditor/courseEditorSelectors"
import {
  publishConfirmationDialogClosed,
  publishCourse,
} from "../../features/courseEditor/courseEditorSlice"
import { selectLatestPublishedCourse } from "../../features/coursesMenu/coursesSelectors"
import {
  Syllabus,
  selectSyllabuses,
} from "../../features/syllabus/syllabusSelectors"
import { selectDependentSyllabuses } from "../../features/ui/uiSelector"
import appTheme from "../../theme/appTheme"

function CoursePublishConfirmationPopup() {
  const viewProps = useViewProps()

  if (!viewProps) {
    return null
  }

  return <View {...viewProps} />
}

type ViewModel = {
  title: string
  subtitle: string
  warningText?: string
  syllabuses: Syllabus[]
  syllabusesTextColor: string
  isPublishing: boolean
}

type ViewProps = {
  model: ViewModel
  onClose: () => void
  onPublish: () => void
  onSyllabusClicked: (syllabus: Syllabus) => void
}

function useViewProps(): ViewProps | undefined {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { syllabusId, unitId } = useParams()
  const [searchParams] = useSearchParams()
  const duplicateOriginalCourseId = searchParams.get(
    "duplicateOriginalCourseId",
  )
  const course = useAppSelector(selectActiveCourse)
  const dependentSyllabusesIDs = useAppSelector(
    selectDependentSyllabuses(course?.id),
  )
  const syllabusesMetadata = useAppSelector(selectSyllabuses)
  const syllabuses = syllabusesMetadata.filter((syllabus) =>
    dependentSyllabusesIDs.includes(syllabus.id),
  )
  const editableCourse = useAppSelector(selectActiveCourse)
  const publishedCourse = useAppSelector(
    selectLatestPublishedCourse(editableCourse?.id),
  )
  const didChangeStructure =
    JSON.stringify(editableCourse?.syllabus.topics.map((t) => t.id)) !==
    JSON.stringify(publishedCourse?.syllabus.topics.map((t) => t.id))

  const onSyllabusClicked = (syllabus: Syllabus) => {
    navigate(`/syllabus/${syllabus.id}`)
  }
  const [isPublishing, setIsPublishing] = useState(false)

  const subtitle = (() => {
    if (dependentSyllabusesIDs.length === 0) {
      return "The course will be visible to all users in the main repository. It does not appear in any syllabuses."
    }
    if (!didChangeStructure) {
      return "The course will be visible to all users in the main repository and in the following syllabuses:"
    }
    return "The course will be visible to all users in the main repository."
  })()

  const warningText = (() => {
    if (dependentSyllabusesIDs.length === 0) {
      return undefined
    }
    if (!didChangeStructure) {
      return undefined
    }
    return "The following syllabuses will need to be updated in order to see these changes:"
  })()

  const syllabusesTextColor = didChangeStructure
    ? appTheme.palette.error.alert?.content ?? "text.primary"
    : "text.primary"

  const model: ViewModel = {
    title: "Publish changes?",
    subtitle: subtitle,
    syllabuses: syllabuses,
    syllabusesTextColor: syllabusesTextColor,
    warningText: warningText,
    isPublishing: isPublishing,
  }

  if (!course) {
    return undefined
  }

  const onPublish = async () => {
    setIsPublishing(true)

    const context =
      syllabusId && unitId
        ? {
            syllabusId,
            unitId,
            duplicateOriginalCourseId: duplicateOriginalCourseId ?? undefined,
          }
        : undefined

    dispatch(publishCourse(context)).then(() => {
      setIsPublishing(false)
      if (context) {
        navigate({
          pathname: `/syllabus-edit/${context.syllabusId}`,
          search: createSearchParams({
            unitId: context.unitId,
            unitTab: "content",
          }).toString(),
        })
      } else {
        navigate(`/course/${course.id}?version=${course.version}`)
      }
    })
  }

  return {
    model: model,
    onClose: () => dispatch(publishConfirmationDialogClosed()),
    onPublish: onPublish,
    onSyllabusClicked: onSyllabusClicked,
  }
}

function View(props: ViewProps) {
  return (
    <Dialog
      open={true}
      onClose={() => props.onClose()}
      sx={{
        ".MuiDialog-paper": {
          padding: "24px",
          gap: "40px",
          width: "388px",
          borderRadius: "8px",
        },
      }}
    >
      <Box
        sx={{
          gap: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <IconButton onClick={props.onClose}>
            <SvgIcon
              component={appIcons.xClose}
              inheritViewBox
              sx={{
                stroke: appTheme.palette.icon.black,
              }}
            />
          </IconButton>
        </Box>
        <Typography variant="body1_sb">{props.model.title}</Typography>
        <Box
          sx={{
            gap: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography>{props.model.subtitle}</Typography>
          {props.model.warningText && (
            <ManualSyllabusUpdateWarning
              text={props.model.warningText}
              color={appTheme.palette.error.alert?.content ?? "text.primary"}
            />
          )}
          {props.model.syllabuses.length > 0 &&
            props.model.syllabuses.map((syllabus) => (
              <SyllabusButton
                key={syllabus.id}
                syllabus={syllabus}
                color={props.model.syllabusesTextColor}
                onSyllabusClicked={() => props.onSyllabusClicked(syllabus)}
              />
            ))}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          gap: "16px",
        }}
      >
        <LoadingButton
          loading={props.model.isPublishing}
          variant="contained"
          size="small"
          onClick={props.onPublish}
        >
          Publish
        </LoadingButton>
        <Button variant="outlined" size="small" onClick={props.onClose}>
          Cancel
        </Button>
      </Box>
    </Dialog>
  )
}

function SyllabusButton(props: {
  syllabus: Syllabus
  color?: string
  onSyllabusClicked: (syllabus: Syllabus) => void
}) {
  const { syllabus, color, onSyllabusClicked } = props

  return (
    <Box>
      <span
        style={{
          textDecoration: "none",
        }}
      >
        &#x2022;&nbsp;
      </span>
      <Link
        href={`/syllabus/${syllabus.id}`}
        variant="body2"
        onClick={() => onSyllabusClicked(syllabus)}
        sx={{
          color: color,
        }}
      >
        {syllabus.name || "Unnamed syllabus"}
      </Link>
    </Box>
  )
}

function ManualSyllabusUpdateWarning(props: { text: string; color: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <SvgIcon
        component={appIcons.infoCircle}
        sx={{
          color: props.color,
          marginRight: "8px",
        }}
      />
      <Typography
        sx={{
          color: props.color,
        }}
      >
        {props.text}
      </Typography>
    </Box>
  )
}

export default CoursePublishConfirmationPopup
