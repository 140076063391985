import { useAppDispatch } from "@app/hooks"
import { Box, Dialog, IconButton, SvgIcon } from "@mui/material"
import { popupClosed } from "../../features/ui/uiSlice"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"

function CampusPreviewPopup(props: {
  id: string
  name: string
  idType: "course" | "syllabus"
  version?: number
}) {
  const { id, name, idType, version } = props
  const dispatch = useAppDispatch()
  const masterschoolCampusUrl = import.meta.env.VITE_CAMPUS_URL
  const iframeURL = new URL(`${masterschoolCampusUrl}/learn/${idType}/${id}`)
  if (version) {
    iframeURL.searchParams.append("version", version.toString())
  }

  return (
    <Dialog
      open={true}
      onClose={() => {
        dispatch(popupClosed())
      }}
      fullWidth
      maxWidth={"lg"}
      sx={{
        ".MuiDialog-paper": {
          width: "100%",
          height: "100%",
          margin: "0px",
          padding: "0px",
        },
        boxShadow: "1px 1px 1px 1px rgba(0,0,0,1)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          cursor: "pointer",
          zIndex: 10,
        }}
      >
        <IconButton
          sx={{ padding: "2px", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation()
            dispatch(popupClosed())
          }}
        >
          <SvgIcon
            component={appIcons.xClose}
            inheritViewBox
            sx={{
              stroke: appTheme.palette.icon.black,
            }}
          />
        </IconButton>
      </Box>
      <iframe
        title={name}
        width="100%"
        height="100%"
        src={iframeURL.toString()}
        style={{ border: "none" }}
      />
    </Dialog>
  )
}

export default CampusPreviewPopup
