import { CourseSyllabusElementTypeEnum } from "@masterschool/course-builder-api"

function elementTypeToTitle(type: CourseSyllabusElementTypeEnum) {
  switch (type) {
    case CourseSyllabusElementTypeEnum.DatacampLesson:
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
    case CourseSyllabusElementTypeEnum.NotionLesson:
    case CourseSyllabusElementTypeEnum.TryHackmeLesson:
    case CourseSyllabusElementTypeEnum.TypeformLesson:
    case CourseSyllabusElementTypeEnum.UdacityLesson:
    case CourseSyllabusElementTypeEnum.CourseraLesson:
    case CourseSyllabusElementTypeEnum.OtherLesson:
    case CourseSyllabusElementTypeEnum.EmptyLesson:
    case CourseSyllabusElementTypeEnum.RunestoneLesson:
      return "Lesson"
    case CourseSyllabusElementTypeEnum.EmptyProject:
    case CourseSyllabusElementTypeEnum.CodioProject:
      return "Project"
    case CourseSyllabusElementTypeEnum.Survey:
      return "Survey"
    case CourseSyllabusElementTypeEnum.Test:
      return "Quiz"
    default:
      throw new Error(`Invalid element of type ${type}`)
  }
}

export function elementTypeDisplayName(type: CourseSyllabusElementTypeEnum) {
  switch (type) {
    case CourseSyllabusElementTypeEnum.DatacampLesson:
      return "Datacamp"
    case CourseSyllabusElementTypeEnum.MasterschoolLesson:
      return "Masterschool"
    case CourseSyllabusElementTypeEnum.NotionLesson:
      return "Notion"
    case CourseSyllabusElementTypeEnum.TryHackmeLesson:
      return "TryHackMe"
    case CourseSyllabusElementTypeEnum.TypeformLesson:
      return "Typeform"
    case CourseSyllabusElementTypeEnum.UdacityLesson:
      return "Udacity"
    case CourseSyllabusElementTypeEnum.RunestoneLesson:
      return "Runestone"
    case CourseSyllabusElementTypeEnum.CourseraLesson:
      return "Coursera"
    case CourseSyllabusElementTypeEnum.OtherLesson:
      return "Other"
    case CourseSyllabusElementTypeEnum.CodioProject:
      return "codio project"
    case CourseSyllabusElementTypeEnum.Survey:
      return undefined
    case CourseSyllabusElementTypeEnum.Test:
      return undefined
  }
}

export default elementTypeToTitle
