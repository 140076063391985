import { CourseDtoStatusEnum } from "@masterschool/course-builder-api"

const courseStatusTextAndColor = (
  courseStatus: CourseDtoStatusEnum,
): {
  text: string
  color: "success" | "error" | undefined
} => {
  switch (courseStatus) {
    case CourseDtoStatusEnum.Draft:
      return {
        text: "Drafts",
        color: undefined,
      }
    case CourseDtoStatusEnum.Published:
      return {
        text: "Published",
        color: "success",
      }
    case CourseDtoStatusEnum.Archived:
      return {
        text: "Archived",
        color: "error",
      }
  }
}

export { courseStatusTextAndColor }
