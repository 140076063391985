import { useAppDispatch, useAppSelector } from "@app/hooks"
import { AddItemButton } from "@cmp/buttons/addItemButton"
import { SortItemsButton } from "@cmp/buttons/sortItemsButton"
import { TabContext, TabList } from "@mui/lab"
import { Box, Tab, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { selectMainPageSyllabuses } from "../features/search/searchSlice"
import {
  SyllabusIdentifierEnumeration,
  create,
  sortAlphabeticallyClicked,
  sortChronologicallyClicked,
  tabSelected,
} from "../features/syllabus/syllabusesMenuSlice"
import SyllabusDescriptorView from "./descriptor/syllabus/syllabusDescriptorView"
import { SyllabusSearchComponent } from "./descriptor/syllabus/syllabusSearchComponent"
import {
  selectSyllabusesMainPageTab,
  selectSyllabusesSortOption,
} from "../features/syllabus/syllabusSelectors"
import FullScreenLoadingComponent from "@cmp/loaders/fullScreenLoadingComponent"
import FullScreenErrorComponent from "@cmp/loaders/fullScreenErrorComponent"

export function SyllabusMainPage() {
  const dispatch = useAppDispatch()
  const syllabusesToDisplay = useAppSelector(selectMainPageSyllabuses)
  const tab = useAppSelector(selectSyllabusesMainPageTab)
  const sortOption = useAppSelector(selectSyllabusesSortOption)

  const syllabusLoadingState = useAppSelector(
    (state) => state.syllabusesMenu.loadingState,
  )
  const programLoadingState = useAppSelector(
    (state) => state.program.loadingState,
  )
  const isLoading =
    syllabusLoadingState === "loading" || programLoadingState === "loading"
  const isError =
    syllabusLoadingState === "failed" || programLoadingState === "failed"

  const navigate = useNavigate()

  const createSyllabus = () => {
    dispatch(create())
      .then((createdSyllabus) => createdSyllabus.payload)
      .then((payload) => {
        if (payload) {
          navigate(`/syllabus-edit/${payload.id}`)
        }
      })
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        paddingX: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "1240px",
          height: "100%",
        }}
      >
        {isLoading && (
          <FullScreenLoadingComponent loadedComponents="syllabuses and programs" />
        )}
        {isError && (
          <FullScreenErrorComponent loadedComponents="syllabuses and programs" />
        )}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            alignSelf="start"
            sx={{ paddingTop: "80px", paddingBottom: "16px" }}
          >
            Syllabuses
          </Typography>
          <SyllabusSearchComponent />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              overflow: "hidden",
              marginTop: "32px",
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <TabContext value={tab}>
                <TabList
                  onChange={(event, value) => {
                    dispatch(tabSelected(value))
                  }}
                >
                  <Tab label={SyllabusIdentifierEnumeration.All} value="All" />
                  <Tab
                    label={SyllabusIdentifierEnumeration.Active}
                    value={SyllabusIdentifierEnumeration.Active}
                  />
                  <Tab
                    label={SyllabusIdentifierEnumeration.Published}
                    value={SyllabusIdentifierEnumeration.Published}
                  />
                  <Tab
                    label="Drafts"
                    value={SyllabusIdentifierEnumeration.Draft}
                  />
                  <Tab
                    label={SyllabusIdentifierEnumeration.Archived}
                    value={SyllabusIdentifierEnumeration.Archived}
                  />
                </TabList>
              </TabContext>
              <Box display="flex" gap="8px" alignItems="center">
                <SortItemsButton
                  sortOption={sortOption}
                  chronologicalClicked={(sortOrder) =>
                    dispatch(sortChronologicallyClicked(sortOrder))
                  }
                  alphabeticClicked={(sortOrder) =>
                    dispatch(sortAlphabeticallyClicked(sortOrder))
                  }
                />
                <AddItemButton text="Add syllabus" onClick={createSyllabus} />
              </Box>
            </Box>
            <Box
              sx={{
                overflowY: "auto",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                marginTop: "16px",
                paddingBottom: "16px",
              }}
            >
              {syllabusesToDisplay.length > 0 ? (
                syllabusesToDisplay.map((syllabus) => (
                  <SyllabusDescriptorView
                    syllabus={syllabus}
                    key={syllabus.id}
                  />
                ))
              ) : (
                <EmptySyllabusesDescriptorsContainer />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function EmptySyllabusesDescriptorsContainer() {
  const isFiltersApplied = useAppSelector(
    (state) =>
      state.search.syllabusFilters.text !== "" ||
      state.search.syllabusFilters.advanceFilters.length > 0,
  )
  const tab = useAppSelector(selectSyllabusesMainPageTab)
  return (
    <Typography
      variant="body1"
      sx={{
        width: "100%",
        textAlign: "center",
        padding: "24px",
        color: "text.disabled",
      }}
    >
      {isFiltersApplied
        ? "We couldn't find any syllabuses that match your search"
        : placeholderText(tab)}
    </Typography>
  )
}

function placeholderText(tabIdentifier: SyllabusIdentifierEnumeration) {
  switch (tabIdentifier) {
    case SyllabusIdentifierEnumeration.All:
      return "There are no syllabuses yet"
    case SyllabusIdentifierEnumeration.Active:
      return "No syllabuses have been activated yet"
    case SyllabusIdentifierEnumeration.Draft:
      return "You don't have any drafts"
    case SyllabusIdentifierEnumeration.Archived:
      return "No syllabuses have been archived yet"
  }
}
