import { DialogProps } from "@mui/material"
import React from "react"
import { PopupState, PopupType } from "../../features/ui/uiSlice"
import ArchiveConfirmationPopup from "../popups/archiveConfirmationPopup"
import CampusPreviewPopup from "../popups/campusCoursePreviewPopup"
import CoursePreviewPopup from "../popups/coursePreviewPopup"
import SyllabusDependenciesPopup from "../popups/syllabusDependenciesPopup"
import VersionHistoryPopup from "../popups/versionHistoryPopup"
import SyllabusDeleteConfirmationPopup from "../popups/syllabusDeleteConfirmationPopup"
import { CampusSyllabusJSONPreview } from "../popups/campusSyllabusJSONPreview"

function popupFactory(
  popup: PopupState,
  onClose: () => void,
): React.ReactElement<DialogProps> | null {
  switch (popup.type) {
    case PopupType.Preview:
      return <CoursePreviewPopup course={popup.course} />
    case PopupType.CampusCoursePreview:
      return (
        <CampusPreviewPopup
          name={popup.course.name}
          id={popup.course.id}
          idType="course"
          version={popup.course.version}
        />
      )
    case PopupType.VersionsHistory:
      return (
        <VersionHistoryPopup courseId={popup.course.id} onClose={onClose} />
      )
    case PopupType.DependentSyllabuses:
      return (
        <SyllabusDependenciesPopup
          syllabusIDs={popup.syllabusDependencies}
          onClose={onClose}
        />
      )
    case PopupType.ArchiveConfirmation:
      return (
        <ArchiveConfirmationPopup
          courseId={popup.course.id}
          onClose={onClose}
        />
      )
    case PopupType.CampusSyllabusPreview:
      return (
        <CampusPreviewPopup
          name={popup.syllabus.name}
          id={popup.syllabus.id ?? ""}
          idType="syllabus"
        />
      )
    case PopupType.SyllabusDeleteConfirmation:
      return <SyllabusDeleteConfirmationPopup syllabusId={popup.syllabusId} />
    case PopupType.CampusSyllabusJSONPreview:
      return <CampusSyllabusJSONPreview syllabusId={popup.syllabusId} />
  }
}

export default popupFactory
