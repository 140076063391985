import { Box, ThemeProvider } from "@mui/material"
import { Outlet } from "react-router-dom"
import appTheme from "../theme/appTheme"
import Sidebar from "./sidebar"
import PopupPresenter from "../popups/infra/popupPresenter"
import SnackbarPresenter from "../main/snackbars/snackbarPresenter"
import useFetchAppData from "@utils/hooks/fetchAppData"

export function PageContainer() {
  useFetchAppData()

  return (
    <ThemeProvider theme={appTheme}>
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Sidebar />
        <Box
          sx={{
            maxHeight: "100%",
            width: "100%",
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <SnackbarPresenter />
      <PopupPresenter />
    </ThemeProvider>
  )
}
