import { RootState } from "@app/store"
import { createSelector } from "@reduxjs/toolkit"
import {
  selectActiveCourse,
  selectOriginalCourse,
} from "./courseEditorSelectors"
import { isCourse } from "./courseEditorUtils"
import {
  CourseSyllabusElement,
  Topic,
  TopicItemValidationErrorsEnum,
} from "@masterschool/course-builder-api"
import {
  PublishValidationStatus,
  courseValidationForPublish,
  isElementInvalid,
  isTopicInValid,
} from "./courseValidations"

export const selectPublishValidations = (state: RootState) => {
  const publishValidations = state.courseEditor.publishValidations
  if (publishValidations === "idle" || publishValidations === "pending") {
    return undefined
  }
  return publishValidations
}

export const selectPublishValidationsForItem = (itemId?: string) =>
  createSelector([selectPublishValidations], (publishValidations) => {
    if (!itemId || !publishValidations) {
      return undefined
    }
    return publishValidations[itemId]
  })

export const selectIsCourseGeneralInfoInvalid = createSelector(
  [selectActiveCourse, selectPublishValidations],
  (course, publishValidations) => {
    if (!isCourse(course)) {
      return false
    }
    if (!publishValidations) {
      return false
    }
    return (
      publishValidations[course.id]?.includes("MISSING_NAME") ||
      publishValidations[course.id]?.includes("MISSING_TITLE") ||
      publishValidations[course.id]?.includes("MISSING_DOMAIN")
    )
  },
)

export const selectIsCourseContentInvalid = createSelector(
  [selectActiveCourse, selectPublishValidations],
  (course, publishValidations) => {
    if (!isCourse(course)) {
      return false
    }
    if (!publishValidations) {
      return false
    }

    if (publishValidations[course.id]?.includes("MISSING_TOPICS")) {
      return true
    }

    return course.syllabus?.topics.some((topic) => {
      return isTopicInValid(topic, publishValidations)
    })
  },
)

export const selectIsCourseInvalid = createSelector(
  [selectIsCourseContentInvalid, selectIsCourseGeneralInfoInvalid],
  (isCourseContentInvalid, showErrorBadgeOnGeneralTab) => {
    return isCourseContentInvalid || showErrorBadgeOnGeneralTab
  },
)

export const selectIsElementInvalid = (element: CourseSyllabusElement) =>
  createSelector([selectPublishValidations], (publishValidations) => {
    if (!publishValidations) {
      return false
    }
    return isElementInvalid(element, publishValidations)
  })

export const selectPublishValidationStatus = createSelector(
  [selectActiveCourse, selectOriginalCourse],
  (course, originalCourse) => {
    if (!isCourse(course)) {
      return PublishValidationStatus.NOT_A_COURSE
    }
    return courseValidationForPublish(course, originalCourse)
  },
)

export const selectIsTopicInvalid = (
  topic: Topic,
  error?: TopicItemValidationErrorsEnum,
) =>
  createSelector([selectPublishValidations], (publishValidations) => {
    if (error && publishValidations) {
      return publishValidations[topic.id]?.includes(error)
    }
    return isTopicInValid(topic, publishValidations)
  })
