import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { CBTabPanel } from "@cmp/tabPanel"
import { CourseDto } from "@masterschool/course-builder-api"
import { TabContext, TabList } from "@mui/lab"
import { Badge, Box, Tab } from "@mui/material"
import useKeyDown from "@utils/hooks/useKeyDown"
import useWindowEvent from "@utils/hooks/useRefreshObserver"
import { useEffect } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { selectActiveCourse } from "../features/courseEditor/courseEditorSelectors"
import {
  fetchCourse,
  silentSaveCourse,
  unmounted,
} from "../features/courseEditor/courseEditorSlice"
import {
  selectIsCourseContentInvalid,
  selectIsCourseGeneralInfoInvalid,
} from "../features/courseEditor/courseValidationsSelectors"
import { selectLatestDraftCourse } from "../features/coursesMenu/coursesSelectors"
import appTheme from "../theme/appTheme"
import CourseEditorErrorBanner from "./courseEditorErrorBanner"
import CourseEditorGeneralInfo from "./courseEditorGeneralInfo"
import { CourseEditorHeaderContainer } from "./courseEditorHeader"
import CourseItemEditor from "./courseItemEditor"
import CoursePublishConfirmationPopup from "./popups/coursePublishConfirmationPopup"
import DiscardChangesConfirmationPopup from "./popups/discardChangesConfirmationPopup"
import DiscardDraftConfirmationPopup from "./popups/discardDraftConfirmationPopup"
import UnpublishedChangesPopup from "./popups/unpublishedChangesPopup"
import TopicsContainer from "./topic/topicsContainer"

const tabIdentifierEnumeration = ["general", "content"] as const

export function CourseEditorContainer() {
  const course = useAppSelector(selectActiveCourse)
  const dispatch = useAppDispatch()
  const { courseId } = useParams()
  const lastVersion = useAppSelector(selectLatestDraftCourse(courseId))
  const shouldShowUnpublishedChangesWarning = useAppSelector(
    (state) => state.courseEditor.showUnpublishedChangesWarning,
  )
  const showPublishDialog = useAppSelector(
    (state) => state.courseEditor.showPublishDialogConfirmation,
  )
  const showDiscardChangesDialog = useAppSelector(
    (state) => state.courseEditor.showDiscardChangesConfirmation,
  )
  const showDiscardDraftDialog = useAppSelector(
    (state) => state.courseEditor.showDiscardDraftConfirmation,
  )

  useKeyDown(
    "q",
    () => {
      dispatch(silentSaveCourse({ userRequested: true }))
    },
    true,
  )

  useWindowEvent("beforeunload", (event) => {
    dispatch(silentSaveCourse({ userRequested: false }))
  })

  useEffect(() => {
    if (courseId === undefined || lastVersion === undefined) return
    dispatch(fetchCourse({ courseId: courseId, version: lastVersion.version }))
    return () => {
      dispatch(silentSaveCourse({ userRequested: false })).then(() =>
        dispatch(unmounted()),
      )
    }
  }, [dispatch, courseId, lastVersion])

  if (!course)
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <LoadingComponent loadedComponents="" />
      </Box>
    )

  return (
    <>
      <CourseEditor course={course} />
      {shouldShowUnpublishedChangesWarning && <UnpublishedChangesPopup />}
      {showPublishDialog && <CoursePublishConfirmationPopup />}
      {showDiscardChangesDialog && <DiscardChangesConfirmationPopup />}
      {showDiscardDraftDialog && <DiscardDraftConfirmationPopup />}
    </>
  )
}

function CourseEditor(props: { course: CourseDto }) {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("editorTab") || "general"
  const elementId = searchParams.get("elementId")
  const element =
    elementId !== undefined
      ? props.course.syllabus.topics.find((t) =>
          t.elements.find((e) => e.item.id === elementId),
        )
      : undefined
  const translate = element !== undefined
  const showErrorBadgeOnGeneralTab = useAppSelector(
    selectIsCourseGeneralInfoInvalid,
  )
  const showErrorBadgeOnContentTab = useAppSelector(
    selectIsCourseContentInvalid,
  )

  useEffect(() => {
    const tab = searchParams.get("editorTab")
    if (!tabIdentifierEnumeration.includes(tab as any)) {
      const tab =
        props.course.syllabus.topics.length > 0 ? "content" : "general"
      searchParams.set("editorTab", tab)
      setSearchParams(searchParams, {
        replace: true,
      })
    }
  }, [props.course.syllabus.topics.length, searchParams, setSearchParams])

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(silentSaveCourse({ userRequested: false }))
    }, 10000)
    return () => {
      dispatch(silentSaveCourse({ userRequested: false }))
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const elementsNumber = props.course.syllabus.topics.flatMap(
    (t) => t.elements,
  ).length
  const contentTabLabel = `Content${
    elementsNumber > 0 ? ` (${elementsNumber})` : ""
  }`

  const onBackgroundClicked = () => {
    if (searchParams.get("elementId")) {
      searchParams.delete("elementId")
      setSearchParams(searchParams, {
        replace: true,
      })
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <CourseEditorHeaderContainer />
      <CourseEditorErrorBanner />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "60%",
            height: "100%",
            padding: "0px 120px",
            transform: translate ? "translateX(0%)" : "translateX(33%)",
            transition: "all .35s ease-in-out",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={onBackgroundClicked}
        >
          <Box
            sx={{
              maxWidth: "600px",
              width: "100%",
              height: "100%",
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              marginTop: "48px",
            }}
          >
            <TabContext value={tab}>
              <TabList
                onChange={(event, value) => {
                  searchParams.set("editorTab", value)
                  if (value === "general") {
                    searchParams.delete("elementId")
                  }
                  setSearchParams(searchParams, {
                    replace: true,
                  })
                }}
              >
                <Tab
                  label={
                    <Badge
                      variant="dot"
                      color={showErrorBadgeOnGeneralTab ? "error" : "default"}
                    >
                      General
                    </Badge>
                  }
                  value="general"
                />
                <Tab
                  label={
                    <Badge
                      variant="dot"
                      color={showErrorBadgeOnContentTab ? "error" : "default"}
                    >
                      {contentTabLabel}
                    </Badge>
                  }
                  value="content"
                />
              </TabList>
              <CBTabPanel value="general">
                <CourseEditorGeneralInfo course={props.course} />
              </CBTabPanel>
              <CBTabPanel value="content">
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <TopicsContainer />
                </Box>
              </CBTabPanel>
            </TabContext>
          </Box>
        </Box>
        <Box
          sx={{
            width: "40%",
            height: "100%",
            transform: translate ? "translateX(0%)" : "translateX(100%)",
            transition: "all .35s ease-in-out",
            position: "relative",
            ":before": {
              content: '""',
              borderLeft: `1px solid ${appTheme.palette.divider}`,
              width: "1px",
              height: "100%",
              position: "absolute",
              top: "0",
            },
          }}
        >
          <CourseItemEditor />
        </Box>
      </Box>
    </Box>
  )
}
