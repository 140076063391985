import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"

export const PublishSyllabusPopup = (props: {
  onClose: () => void
  onPublishClicked: () => void
}) => {
  const { onClose, onPublishClicked } = props

  return (
    <Dialog
      onClick={(e) => {
        e.stopPropagation()
      }}
      onClose={onClose}
      open={true}
      PaperProps={{
        sx: {
          width: "388px",
          borderRadius: "8px",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          maxHeight: "100%",
          padding: "24px",
        }}
      >
        <Box display="flex" justifyContent="end">
          <IconButton
            sx={{ padding: 0 }}
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
          >
            <SvgIcon
              component={appIcons.xClose}
              inheritViewBox
              sx={{
                stroke: appTheme.palette.icon.black,
              }}
            />
          </IconButton>
        </Box>
        <DialogContentText id="alert-dialog-description" component="div">
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "48px",
            }}
          >
            <Box>
              <Typography variant="body1" fontWeight="600">
                Publish changes?
              </Typography>
              <Typography variant="body1" marginTop="8px">
                Your changes will be published to an active syllabus and will be
                available for all the students in the cohort.
              </Typography>
            </Box>
            <Box display="flex" gap="8px" marginTop="8px" justifyContent="end">
              <Button
                onClick={onClose}
                variant="outlined"
                size="small"
                style={{
                  width: "fit-content",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={onPublishClicked}
                variant="contained"
                size="small"
                disabled={false}
                style={{
                  width: "fit-content",
                  fontSize: "14px",
                }}
              >
                Publish
              </Button>
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
