import { useAppDispatch, useAppSelector } from "@app/hooks"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import appIcons from "@utils/appIcons"
import { useNavigate, useParams } from "react-router-dom"
import {
  selectActiveCourse,
  useCanPublish,
} from "../../../../features/courseEditor/courseEditorSelectors"
import { closedRequestedWithUnpublishedChanges } from "../../../../features/courseEditor/courseEditorSlice"
import appTheme from "../../../../theme/appTheme"
import { CourseEditorContainer } from "../../../courseEditor"

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "100%",
    maxWidth: "1600px",
    overflowY: "hidden",
    height: "100%",
    borderRadius: "8px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
    width: "100%",
    height: "100%",
  },
}))

function CourseEditorPopup() {
  const { syllabusId, unitId, courseId } = useParams()
  const canPublish = useCanPublish()
  const course = useAppSelector(selectActiveCourse)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  async function onClose() {
    if (canPublish && (course?.version ?? 1) > 1) {
      dispatch(closedRequestedWithUnpublishedChanges())
      return
    }
    navigate({
      pathname: `/syllabus-edit/${syllabusId}`,
      search: `unitId=${unitId}`,
    })
  }

  return (
    <CustomDialog
      open={courseId !== undefined}
      maxWidth={false}
      sx={{
        overflowY: "hidden",
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <DialogTitle
        sx={{
          bgcolor: appTheme.palette.background.default,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Course Editor
        <IconButton onClick={onClose} size="small">
          <SvgIcon
            inheritViewBox
            component={appIcons.xClose}
            sx={{
              stroke: appTheme.palette.icon.black,
            }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <CourseEditorContainer />
      </DialogContent>
    </CustomDialog>
  )
}

export default CourseEditorPopup
