import { ComponentsOverrides, ComponentsProps, Theme } from "@mui/material"

export const MuiTextFieldOverrides: {
  defaultProps?: ComponentsProps["MuiTextField"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiTextField"]
} = {
  defaultProps: {
    InputLabelProps: {
      sx: {
        fontSize: "14px",
      },
    },
    InputProps: {
      sx: {
        fontSize: "14px",
        backgroundColor: "#fff",
        ":before": {
          content: '""',
          display: "inline-block",
        },
      },
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: theme.palette.other.outlineBorder,
        },
        "&:hover fieldset": {
          borderColor: theme.palette.action.active,
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.action.active,
        },
      },
    }),
  },
}
