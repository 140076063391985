import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles"

const MuiRadioOverrides: {
  defaultProps?: ComponentsProps["MuiRadio"]
  styleOverrides?: ComponentsOverrides<Theme>["MuiRadio"]
  variants?: ComponentsVariants["MuiRadio"]
} = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1),
    }),
  },
  variants: [
    {
      props: { checked: false },
      style: ({ theme }) => ({
        "& .MuiSvgIcon-root": {
          color: theme.palette.action.active,
        },
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      }),
    },
    {
      props: { checked: true, color: "primary" },
      style: ({ theme }) => ({
        "&:hover": {
          backgroundColor: theme.palette.primary.outlinedHoverBackground,
        },
      }),
    },
  ],
}

export { MuiRadioOverrides }
